export default [
  {
    value: 110000,
    pid: 0,
    label: '北京',
    children: [
      {
        value: 110100,
        pid: 110000,
        label: '北京市',
        children: [
          { value: 110101, pid: 110100, label: '东城区' },
          { value: 110102, pid: 110100, label: '西城区' },
          { value: 110105, pid: 110100, label: '朝阳区' },
          { value: 110106, pid: 110100, label: '丰台区' },
          { value: 110107, pid: 110100, label: '石景山区' },
          { value: 110108, pid: 110100, label: '海淀区' },
          { value: 110109, pid: 110100, label: '门头沟区' },
          { value: 110111, pid: 110100, label: '房山区' },
          { value: 110112, pid: 110100, label: '通州区' },
          { value: 110113, pid: 110100, label: '顺义区' },
          { value: 110114, pid: 110100, label: '昌平区' },
          { value: 110115, pid: 110100, label: '大兴区' },
          { value: 110116, pid: 110100, label: '怀柔区' },
          { value: 110117, pid: 110100, label: '平谷区' },
          { value: 110118, pid: 110100, label: '密云区' },
          { value: 110119, pid: 110100, label: '延庆区' },
          { value: 110120, pid: 110100, label: '中关村科技园区' },
        ],
      },
    ],
  },
  {
    value: 120000,
    pid: 0,
    label: '天津',
    children: [
      {
        value: 120100,
        pid: 120000,
        label: '天津市',
        children: [
          { value: 120101, pid: 120100, label: '和平区' },
          { value: 120102, pid: 120100, label: '河东区' },
          { value: 120103, pid: 120100, label: '河西区' },
          { value: 120104, pid: 120100, label: '南开区' },
          { value: 120105, pid: 120100, label: '河北区' },
          { value: 120106, pid: 120100, label: '红桥区' },
          { value: 120110, pid: 120100, label: '东丽区' },
          { value: 120111, pid: 120100, label: '西青区' },
          { value: 120112, pid: 120100, label: '津南区' },
          { value: 120113, pid: 120100, label: '北辰区' },
          { value: 120114, pid: 120100, label: '武清区' },
          { value: 120115, pid: 120100, label: '宝坻区' },
          { value: 120116, pid: 120100, label: '滨海新区' },
          { value: 120117, pid: 120100, label: '宁河区' },
          { value: 120118, pid: 120100, label: '静海区' },
          { value: 120119, pid: 120100, label: '蓟州区' },
          { value: 120120, pid: 120100, label: '滨海高新区' },
        ],
      },
    ],
  },
  {
    value: 130000,
    pid: 0,
    label: '河北省',
    children: [
      {
        value: 130100,
        pid: 130000,
        label: '石家庄市',
        children: [
          { value: 130102, pid: 130100, label: '长安区' },
          { value: 130104, pid: 130100, label: '桥西区' },
          { value: 130105, pid: 130100, label: '新华区' },
          { value: 130107, pid: 130100, label: '井陉矿区' },
          { value: 130108, pid: 130100, label: '裕华区' },
          { value: 130109, pid: 130100, label: '藁城区' },
          { value: 130110, pid: 130100, label: '鹿泉区' },
          { value: 130111, pid: 130100, label: '栾城区' },
          { value: 130121, pid: 130100, label: '井陉县' },
          { value: 130123, pid: 130100, label: '正定县' },
          { value: 130125, pid: 130100, label: '行唐县' },
          { value: 130126, pid: 130100, label: '灵寿县' },
          { value: 130127, pid: 130100, label: '高邑县' },
          { value: 130128, pid: 130100, label: '深泽县' },
          { value: 130129, pid: 130100, label: '赞皇县' },
          { value: 130130, pid: 130100, label: '无极县' },
          { value: 130131, pid: 130100, label: '平山县' },
          { value: 130132, pid: 130100, label: '元氏县' },
          { value: 130133, pid: 130100, label: '赵县' },
          { value: 130181, pid: 130100, label: '辛集市' },
          { value: 130183, pid: 130100, label: '晋州市' },
          { value: 130184, pid: 130100, label: '新乐市' },
          { value: 130185, pid: 130100, label: '高新区' },
          { value: 130186, pid: 130100, label: '经济开发区' },
          { value: 130187, pid: 130100, label: '循环化工园区' },
        ],
      },
      {
        value: 130200,
        pid: 130000,
        label: '唐山市',
        children: [
          { value: 130202, pid: 130200, label: '路南区' },
          { value: 130203, pid: 130200, label: '路北区' },
          { value: 130204, pid: 130200, label: '古冶区' },
          { value: 130205, pid: 130200, label: '开平区' },
          { value: 130207, pid: 130200, label: '丰南区' },
          { value: 130208, pid: 130200, label: '丰润区' },
          { value: 130209, pid: 130200, label: '曹妃甸区' },
          { value: 130224, pid: 130200, label: '滦南县' },
          { value: 130225, pid: 130200, label: '乐亭县' },
          { value: 130227, pid: 130200, label: '迁西县' },
          { value: 130229, pid: 130200, label: '玉田县' },
          { value: 130281, pid: 130200, label: '遵化市' },
          { value: 130283, pid: 130200, label: '迁安市' },
          { value: 130284, pid: 130200, label: '滦州市' },
          { value: 130291, pid: 130200, label: '高新区' },
          { value: 130292, pid: 130200, label: '芦台开发区' },
          { value: 130293, pid: 130200, label: '海港经济开发区' },
          { value: 130294, pid: 130200, label: '汉沽管理区' },
        ],
      },
      {
        value: 130300,
        pid: 130000,
        label: '秦皇岛市',
        children: [
          { value: 130302, pid: 130300, label: '海港区' },
          { value: 130303, pid: 130300, label: '山海关区' },
          { value: 130304, pid: 130300, label: '北戴河区' },
          { value: 130306, pid: 130300, label: '抚宁区' },
          { value: 130321, pid: 130300, label: '青龙满族自治县' },
          { value: 130322, pid: 130300, label: '昌黎县' },
          { value: 130324, pid: 130300, label: '卢龙县' },
          { value: 130371, pid: 130300, label: '经济技术开发区' },
          { value: 130372, pid: 130300, label: '北戴河新区' },
        ],
      },
      {
        value: 130400,
        pid: 130000,
        label: '邯郸市',
        children: [
          { value: 130402, pid: 130400, label: '邯山区' },
          { value: 130403, pid: 130400, label: '丛台区' },
          { value: 130404, pid: 130400, label: '复兴区' },
          { value: 130406, pid: 130400, label: '峰峰矿区' },
          { value: 130407, pid: 130400, label: '肥乡区' },
          { value: 130408, pid: 130400, label: '永年区' },
          { value: 130423, pid: 130400, label: '临漳县' },
          { value: 130424, pid: 130400, label: '成安县' },
          { value: 130425, pid: 130400, label: '大名县' },
          { value: 130426, pid: 130400, label: '涉县' },
          { value: 130427, pid: 130400, label: '磁县' },
          { value: 130430, pid: 130400, label: '邱县' },
          { value: 130431, pid: 130400, label: '鸡泽县' },
          { value: 130432, pid: 130400, label: '广平县' },
          { value: 130433, pid: 130400, label: '馆陶县' },
          { value: 130434, pid: 130400, label: '魏县' },
          { value: 130435, pid: 130400, label: '曲周县' },
          { value: 130481, pid: 130400, label: '武安市' },
          { value: 130482, pid: 130400, label: '冀南新区' },
          { value: 130483, pid: 130400, label: '高新技术产业开发区' },
        ],
      },
      {
        value: 130500,
        pid: 130000,
        label: '邢台市',
        children: [
          { value: 130502, pid: 130500, label: '桥东区' },
          { value: 130503, pid: 130500, label: '桥西区' },
          { value: 130521, pid: 130500, label: '邢台县' },
          { value: 130522, pid: 130500, label: '临城县' },
          { value: 130523, pid: 130500, label: '内丘县' },
          { value: 130524, pid: 130500, label: '柏乡县' },
          { value: 130525, pid: 130500, label: '隆尧县' },
          { value: 130526, pid: 130500, label: '任县' },
          { value: 130527, pid: 130500, label: '南和县' },
          { value: 130528, pid: 130500, label: '宁晋县' },
          { value: 130529, pid: 130500, label: '巨鹿县' },
          { value: 130530, pid: 130500, label: '新河县' },
          { value: 130531, pid: 130500, label: '广宗县' },
          { value: 130532, pid: 130500, label: '平乡县' },
          { value: 130533, pid: 130500, label: '威县' },
          { value: 130534, pid: 130500, label: '清河县' },
          { value: 130535, pid: 130500, label: '临西县' },
          { value: 130581, pid: 130500, label: '南宫市' },
          { value: 130582, pid: 130500, label: '沙河市' },
          { value: 130583, pid: 130500, label: '经济开发区' },
        ],
      },
      {
        value: 130600,
        pid: 130000,
        label: '保定市',
        children: [
          { value: 130602, pid: 130600, label: '竞秀区' },
          { value: 130606, pid: 130600, label: '莲池区' },
          { value: 130607, pid: 130600, label: '满城区' },
          { value: 130608, pid: 130600, label: '清苑区' },
          { value: 130609, pid: 130600, label: '徐水区' },
          { value: 130623, pid: 130600, label: '涞水县' },
          { value: 130624, pid: 130600, label: '阜平县' },
          { value: 130626, pid: 130600, label: '定兴县' },
          { value: 130627, pid: 130600, label: '唐县' },
          { value: 130628, pid: 130600, label: '高阳县' },
          { value: 130629, pid: 130600, label: '容城县' },
          { value: 130630, pid: 130600, label: '涞源县' },
          { value: 130631, pid: 130600, label: '望都县' },
          { value: 130632, pid: 130600, label: '安新县' },
          { value: 130633, pid: 130600, label: '易县' },
          { value: 130634, pid: 130600, label: '曲阳县' },
          { value: 130635, pid: 130600, label: '蠡县' },
          { value: 130636, pid: 130600, label: '顺平县' },
          { value: 130637, pid: 130600, label: '博野县' },
          { value: 130638, pid: 130600, label: '雄县' },
          { value: 130681, pid: 130600, label: '涿州市' },
          { value: 130682, pid: 130600, label: '定州市' },
          { value: 130683, pid: 130600, label: '安国市' },
          { value: 130684, pid: 130600, label: '高碑店市' },
          { value: 130685, pid: 130600, label: '雄安新区' },
          { value: 130686, pid: 130600, label: '高新区' },
          { value: 130687, pid: 130600, label: '白沟新城' },
        ],
      },
      {
        value: 130700,
        pid: 130000,
        label: '张家口市',
        children: [
          { value: 130702, pid: 130700, label: '桥东区' },
          { value: 130703, pid: 130700, label: '桥西区' },
          { value: 130705, pid: 130700, label: '宣化区' },
          { value: 130706, pid: 130700, label: '下花园区' },
          { value: 130708, pid: 130700, label: '万全区' },
          { value: 130709, pid: 130700, label: '崇礼区' },
          { value: 130722, pid: 130700, label: '张北县' },
          { value: 130723, pid: 130700, label: '康保县' },
          { value: 130724, pid: 130700, label: '沽源县' },
          { value: 130725, pid: 130700, label: '尚义县' },
          { value: 130726, pid: 130700, label: '蔚县' },
          { value: 130727, pid: 130700, label: '阳原县' },
          { value: 130728, pid: 130700, label: '怀安县' },
          { value: 130730, pid: 130700, label: '怀来县' },
          { value: 130731, pid: 130700, label: '涿鹿县' },
          { value: 130732, pid: 130700, label: '赤城县' },
          { value: 130771, pid: 130700, label: '高新区' },
          { value: 130772, pid: 130700, label: '察北管理区' },
          { value: 130773, pid: 130700, label: '塞北管理区' },
        ],
      },
      {
        value: 130800,
        pid: 130000,
        label: '承德市',
        children: [
          { value: 130802, pid: 130800, label: '双桥区' },
          { value: 130803, pid: 130800, label: '双滦区' },
          { value: 130804, pid: 130800, label: '鹰手营子矿区' },
          { value: 130821, pid: 130800, label: '承德县' },
          { value: 130822, pid: 130800, label: '兴隆县' },
          { value: 130824, pid: 130800, label: '滦平县' },
          { value: 130825, pid: 130800, label: '隆化县' },
          { value: 130826, pid: 130800, label: '丰宁满族自治县' },
          { value: 130827, pid: 130800, label: '宽城满族自治县' },
          { value: 130828, pid: 130800, label: '围场满族蒙古族自治县' },
          { value: 130881, pid: 130800, label: '平泉市' },
          { value: 130882, pid: 130800, label: '高新区' },
        ],
      },
      {
        value: 130900,
        pid: 130000,
        label: '沧州市',
        children: [
          { value: 130902, pid: 130900, label: '新华区' },
          { value: 130903, pid: 130900, label: '运河区' },
          { value: 130921, pid: 130900, label: '沧县' },
          { value: 130922, pid: 130900, label: '青县' },
          { value: 130923, pid: 130900, label: '东光县' },
          { value: 130924, pid: 130900, label: '海兴县' },
          { value: 130925, pid: 130900, label: '盐山县' },
          { value: 130926, pid: 130900, label: '肃宁县' },
          { value: 130927, pid: 130900, label: '南皮县' },
          { value: 130928, pid: 130900, label: '吴桥县' },
          { value: 130929, pid: 130900, label: '献县' },
          { value: 130930, pid: 130900, label: '孟村回族自治县' },
          { value: 130981, pid: 130900, label: '泊头市' },
          { value: 130982, pid: 130900, label: '任丘市' },
          { value: 130983, pid: 130900, label: '黄骅市' },
          { value: 130984, pid: 130900, label: '河间市' },
          { value: 130985, pid: 130900, label: '渤海新区' },
          { value: 130986, pid: 130900, label: '高新区' },
          { value: 130987, pid: 130900, label: '临港开发区' },
        ],
      },
      {
        value: 131000,
        pid: 130000,
        label: '廊坊市',
        children: [
          { value: 131002, pid: 131000, label: '安次区' },
          { value: 131003, pid: 131000, label: '广阳区' },
          { value: 131022, pid: 131000, label: '固安县' },
          { value: 131023, pid: 131000, label: '永清县' },
          { value: 131024, pid: 131000, label: '香河县' },
          { value: 131025, pid: 131000, label: '大城县' },
          { value: 131026, pid: 131000, label: '文安县' },
          { value: 131028, pid: 131000, label: '大厂回族自治县' },
          { value: 131081, pid: 131000, label: '霸州市' },
          { value: 131082, pid: 131000, label: '三河市' },
          { value: 131083, pid: 131000, label: '经济技术开发区' },
        ],
      },
      {
        value: 131100,
        pid: 130000,
        label: '衡水市',
        children: [
          { value: 131102, pid: 131100, label: '桃城区' },
          { value: 131103, pid: 131100, label: '冀州区' },
          { value: 131121, pid: 131100, label: '枣强县' },
          { value: 131122, pid: 131100, label: '武邑县' },
          { value: 131123, pid: 131100, label: '武强县' },
          { value: 131124, pid: 131100, label: '饶阳县' },
          { value: 131125, pid: 131100, label: '安平县' },
          { value: 131126, pid: 131100, label: '故城县' },
          { value: 131127, pid: 131100, label: '景县' },
          { value: 131128, pid: 131100, label: '阜城县' },
          { value: 131182, pid: 131100, label: '深州市' },
          { value: 131183, pid: 131100, label: '经济开发区' },
          { value: 131184, pid: 131100, label: '滨湖新区' },
        ],
      },
    ],
  },
  {
    value: 140000,
    pid: 0,
    label: '山西省',
    children: [
      {
        value: 140100,
        pid: 140000,
        label: '太原市',
        children: [
          { value: 140105, pid: 140100, label: '小店区' },
          { value: 140106, pid: 140100, label: '迎泽区' },
          { value: 140107, pid: 140100, label: '杏花岭区' },
          { value: 140108, pid: 140100, label: '尖草坪区' },
          { value: 140109, pid: 140100, label: '万柏林区' },
          { value: 140110, pid: 140100, label: '晋源区' },
          { value: 140121, pid: 140100, label: '清徐县' },
          { value: 140122, pid: 140100, label: '阳曲县' },
          { value: 140123, pid: 140100, label: '娄烦县' },
          { value: 140181, pid: 140100, label: '古交市' },
          { value: 140182, pid: 140100, label: '高新阳曲园区' },
          { value: 140183, pid: 140100, label: '高新汾东园区' },
          { value: 140184, pid: 140100, label: '高新姚村园区' },
        ],
      },
      {
        value: 140200,
        pid: 140000,
        label: '大同市',
        children: [
          { value: 140212, pid: 140200, label: '新荣区' },
          { value: 140213, pid: 140200, label: '平城区' },
          { value: 140214, pid: 140200, label: '云冈区' },
          { value: 140215, pid: 140200, label: '云州区' },
          { value: 140221, pid: 140200, label: '阳高县' },
          { value: 140222, pid: 140200, label: '天镇县' },
          { value: 140223, pid: 140200, label: '广灵县' },
          { value: 140224, pid: 140200, label: '灵丘县' },
          { value: 140225, pid: 140200, label: '浑源县' },
          { value: 140226, pid: 140200, label: '左云县' },
          { value: 140228, pid: 140200, label: '经济开发区' },
        ],
      },
      {
        value: 140300,
        pid: 140000,
        label: '阳泉市',
        children: [
          { value: 140302, pid: 140300, label: '城区' },
          { value: 140303, pid: 140300, label: '矿区' },
          { value: 140311, pid: 140300, label: '郊区' },
          { value: 140321, pid: 140300, label: '平定县' },
          { value: 140322, pid: 140300, label: '盂县' },
        ],
      },
      {
        value: 140400,
        pid: 140000,
        label: '长治市',
        children: [
          { value: 140403, pid: 140400, label: '潞州区' },
          { value: 140404, pid: 140400, label: '上党区' },
          { value: 140405, pid: 140400, label: '屯留区' },
          { value: 140406, pid: 140400, label: '潞城区' },
          { value: 140423, pid: 140400, label: '襄垣县' },
          { value: 140425, pid: 140400, label: '平顺县' },
          { value: 140426, pid: 140400, label: '黎城县' },
          { value: 140427, pid: 140400, label: '壶关县' },
          { value: 140428, pid: 140400, label: '长子县' },
          { value: 140429, pid: 140400, label: '武乡县' },
          { value: 140430, pid: 140400, label: '沁县' },
          { value: 140431, pid: 140400, label: '沁源县' },
        ],
      },
      {
        value: 140500,
        pid: 140000,
        label: '晋城市',
        children: [
          { value: 140502, pid: 140500, label: '城区' },
          { value: 140521, pid: 140500, label: '沁水县' },
          { value: 140522, pid: 140500, label: '阳城县' },
          { value: 140524, pid: 140500, label: '陵川县' },
          { value: 140525, pid: 140500, label: '泽州县' },
          { value: 140581, pid: 140500, label: '高平市' },
          { value: 140582, pid: 140500, label: '经济开发区' },
        ],
      },
      {
        value: 140600,
        pid: 140000,
        label: '朔州市',
        children: [
          { value: 140602, pid: 140600, label: '朔城区' },
          { value: 140603, pid: 140600, label: '平鲁区' },
          { value: 140621, pid: 140600, label: '山阴县' },
          { value: 140622, pid: 140600, label: '应县' },
          { value: 140623, pid: 140600, label: '右玉县' },
          { value: 140681, pid: 140600, label: '怀仁市' },
        ],
      },
      {
        value: 140700,
        pid: 140000,
        label: '晋中市',
        children: [
          { value: 140702, pid: 140700, label: '榆次区' },
          { value: 140721, pid: 140700, label: '榆社县' },
          { value: 140722, pid: 140700, label: '左权县' },
          { value: 140723, pid: 140700, label: '和顺县' },
          { value: 140724, pid: 140700, label: '昔阳县' },
          { value: 140725, pid: 140700, label: '寿阳县' },
          { value: 140726, pid: 140700, label: '太谷县' },
          { value: 140727, pid: 140700, label: '祁县' },
          { value: 140728, pid: 140700, label: '平遥县' },
          { value: 140729, pid: 140700, label: '灵石县' },
          { value: 140781, pid: 140700, label: '介休市' },
        ],
      },
      {
        value: 140800,
        pid: 140000,
        label: '运城市',
        children: [
          { value: 140802, pid: 140800, label: '盐湖区' },
          { value: 140821, pid: 140800, label: '临猗县' },
          { value: 140822, pid: 140800, label: '万荣县' },
          { value: 140823, pid: 140800, label: '闻喜县' },
          { value: 140824, pid: 140800, label: '稷山县' },
          { value: 140825, pid: 140800, label: '新绛县' },
          { value: 140826, pid: 140800, label: '绛县' },
          { value: 140827, pid: 140800, label: '垣曲县' },
          { value: 140828, pid: 140800, label: '夏县' },
          { value: 140829, pid: 140800, label: '平陆县' },
          { value: 140830, pid: 140800, label: '芮城县' },
          { value: 140881, pid: 140800, label: '永济市' },
          { value: 140882, pid: 140800, label: '河津市' },
        ],
      },
      {
        value: 140900,
        pid: 140000,
        label: '忻州市',
        children: [
          { value: 140902, pid: 140900, label: '忻府区' },
          { value: 140921, pid: 140900, label: '定襄县' },
          { value: 140922, pid: 140900, label: '五台县' },
          { value: 140923, pid: 140900, label: '代县' },
          { value: 140924, pid: 140900, label: '繁峙县' },
          { value: 140925, pid: 140900, label: '宁武县' },
          { value: 140926, pid: 140900, label: '静乐县' },
          { value: 140927, pid: 140900, label: '神池县' },
          { value: 140928, pid: 140900, label: '五寨县' },
          { value: 140929, pid: 140900, label: '岢岚县' },
          { value: 140930, pid: 140900, label: '河曲县' },
          { value: 140931, pid: 140900, label: '保德县' },
          { value: 140932, pid: 140900, label: '偏关县' },
          { value: 140981, pid: 140900, label: '原平市' },
        ],
      },
      {
        value: 141000,
        pid: 140000,
        label: '临汾市',
        children: [
          { value: 141002, pid: 141000, label: '尧都区' },
          { value: 141021, pid: 141000, label: '曲沃县' },
          { value: 141022, pid: 141000, label: '翼城县' },
          { value: 141023, pid: 141000, label: '襄汾县' },
          { value: 141024, pid: 141000, label: '洪洞县' },
          { value: 141025, pid: 141000, label: '古县' },
          { value: 141026, pid: 141000, label: '安泽县' },
          { value: 141027, pid: 141000, label: '浮山县' },
          { value: 141028, pid: 141000, label: '吉县' },
          { value: 141029, pid: 141000, label: '乡宁县' },
          { value: 141030, pid: 141000, label: '大宁县' },
          { value: 141031, pid: 141000, label: '隰县' },
          { value: 141032, pid: 141000, label: '永和县' },
          { value: 141033, pid: 141000, label: '蒲县' },
          { value: 141034, pid: 141000, label: '汾西县' },
          { value: 141081, pid: 141000, label: '侯马市' },
          { value: 141082, pid: 141000, label: '霍州市' },
        ],
      },
      {
        value: 141100,
        pid: 140000,
        label: '吕梁市',
        children: [
          { value: 141102, pid: 141100, label: '离石区' },
          { value: 141121, pid: 141100, label: '文水县' },
          { value: 141122, pid: 141100, label: '交城县' },
          { value: 141123, pid: 141100, label: '兴县' },
          { value: 141124, pid: 141100, label: '临县' },
          { value: 141125, pid: 141100, label: '柳林县' },
          { value: 141126, pid: 141100, label: '石楼县' },
          { value: 141127, pid: 141100, label: '岚县' },
          { value: 141128, pid: 141100, label: '方山县' },
          { value: 141129, pid: 141100, label: '中阳县' },
          { value: 141130, pid: 141100, label: '交口县' },
          { value: 141181, pid: 141100, label: '孝义市' },
          { value: 141182, pid: 141100, label: '汾阳市' },
        ],
      },
    ],
  },
  {
    value: 150000,
    pid: 0,
    label: '内蒙古自治区',
    children: [
      {
        value: 150100,
        pid: 150000,
        label: '呼和浩特市',
        children: [
          { value: 150102, pid: 150100, label: '新城区' },
          { value: 150103, pid: 150100, label: '回民区' },
          { value: 150104, pid: 150100, label: '玉泉区' },
          { value: 150105, pid: 150100, label: '赛罕区' },
          { value: 150121, pid: 150100, label: '土默特左旗' },
          { value: 150122, pid: 150100, label: '托克托县' },
          { value: 150123, pid: 150100, label: '和林格尔县' },
          { value: 150124, pid: 150100, label: '清水河县' },
          { value: 150125, pid: 150100, label: '武川县' },
          { value: 150171, pid: 150100, label: '金海工业园区' },
          { value: 150172, pid: 150100, label: '经济技术开发区' },
        ],
      },
      {
        value: 150200,
        pid: 150000,
        label: '包头市',
        children: [
          { value: 150202, pid: 150200, label: '东河区' },
          { value: 150203, pid: 150200, label: '昆都仑区' },
          { value: 150204, pid: 150200, label: '青山区' },
          { value: 150205, pid: 150200, label: '石拐区' },
          { value: 150206, pid: 150200, label: '白云鄂博矿区' },
          { value: 150207, pid: 150200, label: '九原区' },
          { value: 150221, pid: 150200, label: '土默特右旗' },
          { value: 150222, pid: 150200, label: '固阳县' },
          { value: 150223, pid: 150200, label: '达尔罕茂明安联合旗' },
        ],
      },
      {
        value: 150300,
        pid: 150000,
        label: '乌海市',
        children: [
          { value: 150302, pid: 150300, label: '海勃湾区' },
          { value: 150303, pid: 150300, label: '海南区' },
          { value: 150304, pid: 150300, label: '乌达区' },
        ],
      },
      {
        value: 150400,
        pid: 150000,
        label: '赤峰市',
        children: [
          { value: 150402, pid: 150400, label: '红山区' },
          { value: 150403, pid: 150400, label: '元宝山区' },
          { value: 150404, pid: 150400, label: '松山区' },
          { value: 150421, pid: 150400, label: '阿鲁科尔沁旗' },
          { value: 150422, pid: 150400, label: '巴林左旗' },
          { value: 150423, pid: 150400, label: '巴林右旗' },
          { value: 150424, pid: 150400, label: '林西县' },
          { value: 150425, pid: 150400, label: '克什克腾旗' },
          { value: 150426, pid: 150400, label: '翁牛特旗' },
          { value: 150428, pid: 150400, label: '喀喇沁旗' },
          { value: 150429, pid: 150400, label: '宁城县' },
          { value: 150430, pid: 150400, label: '敖汉旗' },
        ],
      },
      {
        value: 150500,
        pid: 150000,
        label: '通辽市',
        children: [
          { value: 150502, pid: 150500, label: '科尔沁区' },
          { value: 150521, pid: 150500, label: '科尔沁左翼中旗' },
          { value: 150522, pid: 150500, label: '科尔沁左翼后旗' },
          { value: 150523, pid: 150500, label: '开鲁县' },
          { value: 150524, pid: 150500, label: '库伦旗' },
          { value: 150525, pid: 150500, label: '奈曼旗' },
          { value: 150526, pid: 150500, label: '扎鲁特旗' },
          { value: 150581, pid: 150500, label: '霍林郭勒市' },
        ],
      },
      {
        value: 150600,
        pid: 150000,
        label: '鄂尔多斯市',
        children: [
          { value: 150602, pid: 150600, label: '东胜区' },
          { value: 150603, pid: 150600, label: '康巴什区' },
          { value: 150621, pid: 150600, label: '达拉特旗' },
          { value: 150622, pid: 150600, label: '准格尔旗' },
          { value: 150623, pid: 150600, label: '鄂托克前旗' },
          { value: 150624, pid: 150600, label: '鄂托克旗' },
          { value: 150625, pid: 150600, label: '杭锦旗' },
          { value: 150626, pid: 150600, label: '乌审旗' },
          { value: 150627, pid: 150600, label: '伊金霍洛旗' },
        ],
      },
      {
        value: 150700,
        pid: 150000,
        label: '呼伦贝尔市',
        children: [
          { value: 150702, pid: 150700, label: '海拉尔区' },
          { value: 150703, pid: 150700, label: '扎赉诺尔区' },
          { value: 150721, pid: 150700, label: '阿荣旗' },
          { value: 150722, pid: 150700, label: '莫力达瓦达斡尔族自治旗' },
          { value: 150723, pid: 150700, label: '鄂伦春自治旗' },
          { value: 150724, pid: 150700, label: '鄂温克族自治旗' },
          { value: 150725, pid: 150700, label: '陈巴尔虎旗' },
          { value: 150726, pid: 150700, label: '新巴尔虎左旗' },
          { value: 150727, pid: 150700, label: '新巴尔虎右旗' },
          { value: 150781, pid: 150700, label: '满洲里市' },
          { value: 150782, pid: 150700, label: '牙克石市' },
          { value: 150783, pid: 150700, label: '扎兰屯市' },
          { value: 150784, pid: 150700, label: '额尔古纳市' },
          { value: 150785, pid: 150700, label: '根河市' },
        ],
      },
      {
        value: 150800,
        pid: 150000,
        label: '巴彦淖尔市',
        children: [
          { value: 150802, pid: 150800, label: '临河区' },
          { value: 150821, pid: 150800, label: '五原县' },
          { value: 150822, pid: 150800, label: '磴口县' },
          { value: 150823, pid: 150800, label: '乌拉特前旗' },
          { value: 150824, pid: 150800, label: '乌拉特中旗' },
          { value: 150825, pid: 150800, label: '乌拉特后旗' },
          { value: 150826, pid: 150800, label: '杭锦后旗' },
        ],
      },
      {
        value: 150900,
        pid: 150000,
        label: '乌兰察布市',
        children: [
          { value: 150902, pid: 150900, label: '集宁区' },
          { value: 150921, pid: 150900, label: '卓资县' },
          { value: 150922, pid: 150900, label: '化德县' },
          { value: 150923, pid: 150900, label: '商都县' },
          { value: 150924, pid: 150900, label: '兴和县' },
          { value: 150925, pid: 150900, label: '凉城县' },
          { value: 150926, pid: 150900, label: '察哈尔右翼前旗' },
          { value: 150927, pid: 150900, label: '察哈尔右翼中旗' },
          { value: 150928, pid: 150900, label: '察哈尔右翼后旗' },
          { value: 150929, pid: 150900, label: '四子王旗' },
          { value: 150981, pid: 150900, label: '丰镇市' },
        ],
      },
      {
        value: 152200,
        pid: 150000,
        label: '兴安盟',
        children: [
          { value: 152201, pid: 152200, label: '乌兰浩特市' },
          { value: 152202, pid: 152200, label: '阿尔山市' },
          { value: 152221, pid: 152200, label: '科尔沁右翼前旗' },
          { value: 152222, pid: 152200, label: '科尔沁右翼中旗' },
          { value: 152223, pid: 152200, label: '扎赉特旗' },
          { value: 152224, pid: 152200, label: '突泉县' },
        ],
      },
      {
        value: 152500,
        pid: 150000,
        label: '锡林郭勒盟',
        children: [
          { value: 152501, pid: 152500, label: '二连浩特市' },
          { value: 152502, pid: 152500, label: '锡林浩特市' },
          { value: 152522, pid: 152500, label: '阿巴嘎旗' },
          { value: 152523, pid: 152500, label: '苏尼特左旗' },
          { value: 152524, pid: 152500, label: '苏尼特右旗' },
          { value: 152525, pid: 152500, label: '东乌珠穆沁旗' },
          { value: 152526, pid: 152500, label: '西乌珠穆沁旗' },
          { value: 152527, pid: 152500, label: '太仆寺旗' },
          { value: 152528, pid: 152500, label: '镶黄旗' },
          { value: 152529, pid: 152500, label: '正镶白旗' },
          { value: 152530, pid: 152500, label: '正蓝旗' },
          { value: 152531, pid: 152500, label: '多伦县' },
        ],
      },
      {
        value: 152900,
        pid: 150000,
        label: '阿拉善盟',
        children: [
          { value: 152921, pid: 152900, label: '阿拉善左旗' },
          { value: 152922, pid: 152900, label: '阿拉善右旗' },
          { value: 152923, pid: 152900, label: '额济纳旗' },
        ],
      },
    ],
  },
  {
    value: 210000,
    pid: 0,
    label: '辽宁省',
    children: [
      {
        value: 210100,
        pid: 210000,
        label: '沈阳市',
        children: [
          { value: 210102, pid: 210100, label: '和平区' },
          { value: 210103, pid: 210100, label: '沈河区' },
          { value: 210104, pid: 210100, label: '大东区' },
          { value: 210105, pid: 210100, label: '皇姑区' },
          { value: 210106, pid: 210100, label: '铁西区' },
          { value: 210111, pid: 210100, label: '苏家屯区' },
          { value: 210112, pid: 210100, label: '浑南区' },
          { value: 210113, pid: 210100, label: '沈北新区' },
          { value: 210114, pid: 210100, label: '于洪区' },
          { value: 210115, pid: 210100, label: '辽中区' },
          { value: 210123, pid: 210100, label: '康平县' },
          { value: 210124, pid: 210100, label: '法库县' },
          { value: 210181, pid: 210100, label: '新民市' },
          { value: 210182, pid: 210100, label: '高新区' },
        ],
      },
      {
        value: 210200,
        pid: 210000,
        label: '大连市',
        children: [
          { value: 210202, pid: 210200, label: '中山区' },
          { value: 210203, pid: 210200, label: '西岗区' },
          { value: 210204, pid: 210200, label: '沙河口区' },
          { value: 210211, pid: 210200, label: '甘井子区' },
          { value: 210212, pid: 210200, label: '旅顺口区' },
          { value: 210213, pid: 210200, label: '金州区' },
          { value: 210214, pid: 210200, label: '普兰店区' },
          { value: 210224, pid: 210200, label: '长海县' },
          { value: 210281, pid: 210200, label: '瓦房店市' },
          { value: 210283, pid: 210200, label: '庄河市' },
          { value: 210284, pid: 210200, label: '高新区' },
          { value: 210285, pid: 210200, label: '经济开发区' },
          { value: 210286, pid: 210200, label: '金普新区' },
        ],
      },
      {
        value: 210300,
        pid: 210000,
        label: '鞍山市',
        children: [
          { value: 210302, pid: 210300, label: '铁东区' },
          { value: 210303, pid: 210300, label: '铁西区' },
          { value: 210304, pid: 210300, label: '立山区' },
          { value: 210311, pid: 210300, label: '千山区' },
          { value: 210321, pid: 210300, label: '台安县' },
          { value: 210323, pid: 210300, label: '岫岩满族自治县' },
          { value: 210381, pid: 210300, label: '海城市' },
          { value: 210382, pid: 210300, label: '高新区' },
        ],
      },
      {
        value: 210400,
        pid: 210000,
        label: '抚顺市',
        children: [
          { value: 210402, pid: 210400, label: '新抚区' },
          { value: 210403, pid: 210400, label: '东洲区' },
          { value: 210404, pid: 210400, label: '望花区' },
          { value: 210411, pid: 210400, label: '顺城区' },
          { value: 210421, pid: 210400, label: '抚顺县' },
          { value: 210422, pid: 210400, label: '新宾满族自治县' },
          { value: 210423, pid: 210400, label: '清原满族自治县' },
        ],
      },
      {
        value: 210500,
        pid: 210000,
        label: '本溪市',
        children: [
          { value: 210502, pid: 210500, label: '平山区' },
          { value: 210503, pid: 210500, label: '溪湖区' },
          { value: 210504, pid: 210500, label: '明山区' },
          { value: 210505, pid: 210500, label: '南芬区' },
          { value: 210521, pid: 210500, label: '本溪满族自治县' },
          { value: 210522, pid: 210500, label: '桓仁满族自治县' },
        ],
      },
      {
        value: 210600,
        pid: 210000,
        label: '丹东市',
        children: [
          { value: 210602, pid: 210600, label: '元宝区' },
          { value: 210603, pid: 210600, label: '振兴区' },
          { value: 210604, pid: 210600, label: '振安区' },
          { value: 210624, pid: 210600, label: '宽甸满族自治县' },
          { value: 210681, pid: 210600, label: '东港市' },
          { value: 210682, pid: 210600, label: '凤城市' },
        ],
      },
      {
        value: 210700,
        pid: 210000,
        label: '锦州市',
        children: [
          { value: 210702, pid: 210700, label: '古塔区' },
          { value: 210703, pid: 210700, label: '凌河区' },
          { value: 210711, pid: 210700, label: '太和区' },
          { value: 210726, pid: 210700, label: '黑山县' },
          { value: 210727, pid: 210700, label: '义县' },
          { value: 210781, pid: 210700, label: '凌海市' },
          { value: 210782, pid: 210700, label: '北镇市' },
          { value: 210783, pid: 210700, label: '松山新区' },
          { value: 210784, pid: 210700, label: '龙栖湾新区' },
          { value: 210785, pid: 210700, label: '经济技术开发区' },
        ],
      },
      {
        value: 210800,
        pid: 210000,
        label: '营口市',
        children: [
          { value: 210802, pid: 210800, label: '站前区' },
          { value: 210803, pid: 210800, label: '西市区' },
          { value: 210804, pid: 210800, label: '鲅鱼圈区' },
          { value: 210811, pid: 210800, label: '老边区' },
          { value: 210881, pid: 210800, label: '盖州市' },
          { value: 210882, pid: 210800, label: '大石桥市' },
        ],
      },
      {
        value: 210900,
        pid: 210000,
        label: '阜新市',
        children: [
          { value: 210902, pid: 210900, label: '海州区' },
          { value: 210903, pid: 210900, label: '新邱区' },
          { value: 210904, pid: 210900, label: '太平区' },
          { value: 210905, pid: 210900, label: '清河门区' },
          { value: 210911, pid: 210900, label: '细河区' },
          { value: 210921, pid: 210900, label: '阜新蒙古族自治县' },
          { value: 210922, pid: 210900, label: '彰武县' },
        ],
      },
      {
        value: 211000,
        pid: 210000,
        label: '辽阳市',
        children: [
          { value: 211002, pid: 211000, label: '白塔区' },
          { value: 211003, pid: 211000, label: '文圣区' },
          { value: 211004, pid: 211000, label: '宏伟区' },
          { value: 211005, pid: 211000, label: '弓长岭区' },
          { value: 211011, pid: 211000, label: '太子河区' },
          { value: 211021, pid: 211000, label: '辽阳县' },
          { value: 211081, pid: 211000, label: '灯塔市' },
        ],
      },
      {
        value: 211100,
        pid: 210000,
        label: '盘锦市',
        children: [
          { value: 211102, pid: 211100, label: '双台子区' },
          { value: 211103, pid: 211100, label: '兴隆台区' },
          { value: 211104, pid: 211100, label: '大洼区' },
          { value: 211122, pid: 211100, label: '盘山县' },
        ],
      },
      {
        value: 211200,
        pid: 210000,
        label: '铁岭市',
        children: [
          { value: 211202, pid: 211200, label: '银州区' },
          { value: 211204, pid: 211200, label: '清河区' },
          { value: 211221, pid: 211200, label: '铁岭县' },
          { value: 211223, pid: 211200, label: '西丰县' },
          { value: 211224, pid: 211200, label: '昌图县' },
          { value: 211281, pid: 211200, label: '调兵山市' },
          { value: 211282, pid: 211200, label: '开原市' },
        ],
      },
      {
        value: 211300,
        pid: 210000,
        label: '朝阳市',
        children: [
          { value: 211302, pid: 211300, label: '双塔区' },
          { value: 211303, pid: 211300, label: '龙城区' },
          { value: 211321, pid: 211300, label: '朝阳县' },
          { value: 211322, pid: 211300, label: '建平县' },
          { value: 211324, pid: 211300, label: '喀喇沁左翼蒙古族自治县' },
          { value: 211381, pid: 211300, label: '北票市' },
          { value: 211382, pid: 211300, label: '凌源市' },
        ],
      },
      {
        value: 211400,
        pid: 210000,
        label: '葫芦岛市',
        children: [
          { value: 211402, pid: 211400, label: '连山区' },
          { value: 211403, pid: 211400, label: '龙港区' },
          { value: 211404, pid: 211400, label: '南票区' },
          { value: 211421, pid: 211400, label: '绥中县' },
          { value: 211422, pid: 211400, label: '建昌县' },
          { value: 211481, pid: 211400, label: '兴城市' },
        ],
      },
    ],
  },
  {
    value: 220000,
    pid: 0,
    label: '吉林省',
    children: [
      {
        value: 220100,
        pid: 220000,
        label: '长春市',
        children: [
          { value: 220102, pid: 220100, label: '南关区' },
          { value: 220103, pid: 220100, label: '宽城区' },
          { value: 220104, pid: 220100, label: '朝阳区' },
          { value: 220105, pid: 220100, label: '二道区' },
          { value: 220106, pid: 220100, label: '绿园区' },
          { value: 220112, pid: 220100, label: '双阳区' },
          { value: 220113, pid: 220100, label: '九台区' },
          { value: 220122, pid: 220100, label: '农安县' },
          { value: 220182, pid: 220100, label: '榆树市' },
          { value: 220183, pid: 220100, label: '德惠市' },
          { value: 220184, pid: 220100, label: '长春新区' },
          { value: 220185, pid: 220100, label: '高新区' },
          { value: 220186, pid: 220100, label: '经济技术开发区' },
          { value: 220187, pid: 220100, label: '汽车产业开发区' },
          { value: 220188, pid: 220100, label: '兴隆综合保税区' },
        ],
      },
      {
        value: 220200,
        pid: 220000,
        label: '吉林市',
        children: [
          { value: 220202, pid: 220200, label: '昌邑区' },
          { value: 220203, pid: 220200, label: '龙潭区' },
          { value: 220204, pid: 220200, label: '船营区' },
          { value: 220211, pid: 220200, label: '丰满区' },
          { value: 220221, pid: 220200, label: '永吉县' },
          { value: 220281, pid: 220200, label: '蛟河市' },
          { value: 220282, pid: 220200, label: '桦甸市' },
          { value: 220283, pid: 220200, label: '舒兰市' },
          { value: 220284, pid: 220200, label: '磐石市' },
          { value: 220285, pid: 220200, label: '高新区' },
        ],
      },
      {
        value: 220300,
        pid: 220000,
        label: '四平市',
        children: [
          { value: 220302, pid: 220300, label: '铁西区' },
          { value: 220303, pid: 220300, label: '铁东区' },
          { value: 220322, pid: 220300, label: '梨树县' },
          { value: 220323, pid: 220300, label: '伊通满族自治县' },
          { value: 220381, pid: 220300, label: '公主岭市' },
          { value: 220382, pid: 220300, label: '双辽市' },
        ],
      },
      {
        value: 220400,
        pid: 220000,
        label: '辽源市',
        children: [
          { value: 220402, pid: 220400, label: '龙山区' },
          { value: 220403, pid: 220400, label: '西安区' },
          { value: 220421, pid: 220400, label: '东丰县' },
          { value: 220422, pid: 220400, label: '东辽县' },
        ],
      },
      {
        value: 220500,
        pid: 220000,
        label: '通化市',
        children: [
          { value: 220502, pid: 220500, label: '东昌区' },
          { value: 220503, pid: 220500, label: '二道江区' },
          { value: 220521, pid: 220500, label: '通化县' },
          { value: 220523, pid: 220500, label: '辉南县' },
          { value: 220524, pid: 220500, label: '柳河县' },
          { value: 220581, pid: 220500, label: '梅河口市' },
          { value: 220582, pid: 220500, label: '集安市' },
        ],
      },
      {
        value: 220600,
        pid: 220000,
        label: '白山市',
        children: [
          { value: 220602, pid: 220600, label: '浑江区' },
          { value: 220605, pid: 220600, label: '江源区' },
          { value: 220621, pid: 220600, label: '抚松县' },
          { value: 220622, pid: 220600, label: '靖宇县' },
          { value: 220623, pid: 220600, label: '长白朝鲜族自治县' },
          { value: 220681, pid: 220600, label: '临江市' },
        ],
      },
      {
        value: 220700,
        pid: 220000,
        label: '松原市',
        children: [
          { value: 220702, pid: 220700, label: '宁江区' },
          { value: 220721, pid: 220700, label: '前郭尔罗斯蒙古族自治县' },
          { value: 220722, pid: 220700, label: '长岭县' },
          { value: 220723, pid: 220700, label: '乾安县' },
          { value: 220781, pid: 220700, label: '扶余市' },
        ],
      },
      {
        value: 220800,
        pid: 220000,
        label: '白城市',
        children: [
          { value: 220802, pid: 220800, label: '洮北区' },
          { value: 220821, pid: 220800, label: '镇赉县' },
          { value: 220822, pid: 220800, label: '通榆县' },
          { value: 220881, pid: 220800, label: '洮南市' },
          { value: 220882, pid: 220800, label: '大安市' },
        ],
      },
      {
        value: 222400,
        pid: 220000,
        label: '延边朝鲜族自治州',
        children: [
          { value: 222401, pid: 222400, label: '延吉市' },
          { value: 222402, pid: 222400, label: '图们市' },
          { value: 222403, pid: 222400, label: '敦化市' },
          { value: 222404, pid: 222400, label: '珲春市' },
          { value: 222405, pid: 222400, label: '龙井市' },
          { value: 222406, pid: 222400, label: '和龙市' },
          { value: 222424, pid: 222400, label: '汪清县' },
          { value: 222426, pid: 222400, label: '安图县' },
        ],
      },
    ],
  },
  {
    value: 230000,
    pid: 0,
    label: '黑龙江省',
    children: [
      {
        value: 230100,
        pid: 230000,
        label: '哈尔滨市',
        children: [
          { value: 230102, pid: 230100, label: '道里区' },
          { value: 230103, pid: 230100, label: '南岗区' },
          { value: 230104, pid: 230100, label: '道外区' },
          { value: 230108, pid: 230100, label: '平房区' },
          { value: 230109, pid: 230100, label: '松北区' },
          { value: 230110, pid: 230100, label: '香坊区' },
          { value: 230111, pid: 230100, label: '呼兰区' },
          { value: 230112, pid: 230100, label: '阿城区' },
          { value: 230113, pid: 230100, label: '双城区' },
          { value: 230123, pid: 230100, label: '依兰县' },
          { value: 230124, pid: 230100, label: '方正县' },
          { value: 230125, pid: 230100, label: '宾县' },
          { value: 230126, pid: 230100, label: '巴彦县' },
          { value: 230127, pid: 230100, label: '木兰县' },
          { value: 230128, pid: 230100, label: '通河县' },
          { value: 230129, pid: 230100, label: '延寿县' },
          { value: 230183, pid: 230100, label: '尚志市' },
          { value: 230184, pid: 230100, label: '五常市' },
          { value: 230185, pid: 230100, label: '哈尔滨新区' },
          { value: 230186, pid: 230100, label: '高新区' },
        ],
      },
      {
        value: 230200,
        pid: 230000,
        label: '齐齐哈尔市',
        children: [
          { value: 230202, pid: 230200, label: '龙沙区' },
          { value: 230203, pid: 230200, label: '建华区' },
          { value: 230204, pid: 230200, label: '铁锋区' },
          { value: 230205, pid: 230200, label: '昂昂溪区' },
          { value: 230206, pid: 230200, label: '富拉尔基区' },
          { value: 230207, pid: 230200, label: '碾子山区' },
          { value: 230208, pid: 230200, label: '梅里斯达斡尔族区' },
          { value: 230221, pid: 230200, label: '龙江县' },
          { value: 230223, pid: 230200, label: '依安县' },
          { value: 230224, pid: 230200, label: '泰来县' },
          { value: 230225, pid: 230200, label: '甘南县' },
          { value: 230227, pid: 230200, label: '富裕县' },
          { value: 230229, pid: 230200, label: '克山县' },
          { value: 230230, pid: 230200, label: '克东县' },
          { value: 230231, pid: 230200, label: '拜泉县' },
          { value: 230281, pid: 230200, label: '讷河市' },
          { value: 230282, pid: 230200, label: '高新区' },
        ],
      },
      {
        value: 230300,
        pid: 230000,
        label: '鸡西市',
        children: [
          { value: 230302, pid: 230300, label: '鸡冠区' },
          { value: 230303, pid: 230300, label: '恒山区' },
          { value: 230304, pid: 230300, label: '滴道区' },
          { value: 230305, pid: 230300, label: '梨树区' },
          { value: 230306, pid: 230300, label: '城子河区' },
          { value: 230307, pid: 230300, label: '麻山区' },
          { value: 230321, pid: 230300, label: '鸡东县' },
          { value: 230381, pid: 230300, label: '虎林市' },
          { value: 230382, pid: 230300, label: '密山市' },
        ],
      },
      {
        value: 230400,
        pid: 230000,
        label: '鹤岗市',
        children: [
          { value: 230402, pid: 230400, label: '向阳区' },
          { value: 230403, pid: 230400, label: '工农区' },
          { value: 230404, pid: 230400, label: '南山区' },
          { value: 230405, pid: 230400, label: '兴安区' },
          { value: 230406, pid: 230400, label: '东山区' },
          { value: 230407, pid: 230400, label: '兴山区' },
          { value: 230421, pid: 230400, label: '萝北县' },
          { value: 230422, pid: 230400, label: '绥滨县' },
        ],
      },
      {
        value: 230500,
        pid: 230000,
        label: '双鸭山市',
        children: [
          { value: 230502, pid: 230500, label: '尖山区' },
          { value: 230503, pid: 230500, label: '岭东区' },
          { value: 230505, pid: 230500, label: '四方台区' },
          { value: 230506, pid: 230500, label: '宝山区' },
          { value: 230521, pid: 230500, label: '集贤县' },
          { value: 230522, pid: 230500, label: '友谊县' },
          { value: 230523, pid: 230500, label: '宝清县' },
          { value: 230524, pid: 230500, label: '饶河县' },
        ],
      },
      {
        value: 230600,
        pid: 230000,
        label: '大庆市',
        children: [
          { value: 230602, pid: 230600, label: '萨尔图区' },
          { value: 230603, pid: 230600, label: '龙凤区' },
          { value: 230604, pid: 230600, label: '让胡路区' },
          { value: 230605, pid: 230600, label: '红岗区' },
          { value: 230606, pid: 230600, label: '大同区' },
          { value: 230621, pid: 230600, label: '肇州县' },
          { value: 230622, pid: 230600, label: '肇源县' },
          { value: 230623, pid: 230600, label: '林甸县' },
          { value: 230624, pid: 230600, label: '杜尔伯特蒙古族自治县' },
          { value: 230625, pid: 230600, label: '高新区' },
        ],
      },
      {
        value: 230700,
        pid: 230000,
        label: '伊春市',
        children: [
          { value: 230702, pid: 230700, label: '伊春区' },
          { value: 230703, pid: 230700, label: '南岔区' },
          { value: 230704, pid: 230700, label: '友好区' },
          { value: 230705, pid: 230700, label: '西林区' },
          { value: 230706, pid: 230700, label: '翠峦区' },
          { value: 230707, pid: 230700, label: '新青区' },
          { value: 230708, pid: 230700, label: '美溪区' },
          { value: 230709, pid: 230700, label: '金山屯区' },
          { value: 230710, pid: 230700, label: '五营区' },
          { value: 230711, pid: 230700, label: '乌马河区' },
          { value: 230712, pid: 230700, label: '汤旺河区' },
          { value: 230713, pid: 230700, label: '带岭区' },
          { value: 230714, pid: 230700, label: '乌伊岭区' },
          { value: 230715, pid: 230700, label: '红星区' },
          { value: 230716, pid: 230700, label: '上甘岭区' },
          { value: 230722, pid: 230700, label: '嘉荫县' },
          { value: 230781, pid: 230700, label: '铁力市' },
        ],
      },
      {
        value: 230800,
        pid: 230000,
        label: '佳木斯市',
        children: [
          { value: 230803, pid: 230800, label: '向阳区' },
          { value: 230804, pid: 230800, label: '前进区' },
          { value: 230805, pid: 230800, label: '东风区' },
          { value: 230811, pid: 230800, label: '郊区' },
          { value: 230822, pid: 230800, label: '桦南县' },
          { value: 230826, pid: 230800, label: '桦川县' },
          { value: 230828, pid: 230800, label: '汤原县' },
          { value: 230881, pid: 230800, label: '同江市' },
          { value: 230882, pid: 230800, label: '富锦市' },
          { value: 230883, pid: 230800, label: '抚远市' },
        ],
      },
      {
        value: 230900,
        pid: 230000,
        label: '七台河市',
        children: [
          { value: 230902, pid: 230900, label: '新兴区' },
          { value: 230903, pid: 230900, label: '桃山区' },
          { value: 230904, pid: 230900, label: '茄子河区' },
          { value: 230921, pid: 230900, label: '勃利县' },
        ],
      },
      {
        value: 231000,
        pid: 230000,
        label: '牡丹江市',
        children: [
          { value: 231002, pid: 231000, label: '东安区' },
          { value: 231003, pid: 231000, label: '阳明区' },
          { value: 231004, pid: 231000, label: '爱民区' },
          { value: 231005, pid: 231000, label: '西安区' },
          { value: 231025, pid: 231000, label: '林口县' },
          { value: 231081, pid: 231000, label: '绥芬河市' },
          { value: 231083, pid: 231000, label: '海林市' },
          { value: 231084, pid: 231000, label: '宁安市' },
          { value: 231085, pid: 231000, label: '穆棱市' },
          { value: 231086, pid: 231000, label: '东宁市' },
        ],
      },
      {
        value: 231100,
        pid: 230000,
        label: '黑河市',
        children: [
          { value: 231102, pid: 231100, label: '爱辉区' },
          { value: 231121, pid: 231100, label: '嫩江县' },
          { value: 231123, pid: 231100, label: '逊克县' },
          { value: 231124, pid: 231100, label: '孙吴县' },
          { value: 231181, pid: 231100, label: '北安市' },
          { value: 231182, pid: 231100, label: '五大连池市' },
        ],
      },
      {
        value: 231200,
        pid: 230000,
        label: '绥化市',
        children: [
          { value: 231202, pid: 231200, label: '北林区' },
          { value: 231221, pid: 231200, label: '望奎县' },
          { value: 231222, pid: 231200, label: '兰西县' },
          { value: 231223, pid: 231200, label: '青冈县' },
          { value: 231224, pid: 231200, label: '庆安县' },
          { value: 231225, pid: 231200, label: '明水县' },
          { value: 231226, pid: 231200, label: '绥棱县' },
          { value: 231281, pid: 231200, label: '安达市' },
          { value: 231282, pid: 231200, label: '肇东市' },
          { value: 231283, pid: 231200, label: '海伦市' },
        ],
      },
      {
        value: 232700,
        pid: 230000,
        label: '大兴安岭地区',
        children: [
          { value: 232701, pid: 232700, label: '漠河市' },
          { value: 232721, pid: 232700, label: '呼玛县' },
          { value: 232722, pid: 232700, label: '塔河县' },
          { value: 232761, pid: 232700, label: '加格达奇区' },
          { value: 232762, pid: 232700, label: '松岭区' },
          { value: 232763, pid: 232700, label: '新林区' },
          { value: 232764, pid: 232700, label: '呼中区' },
        ],
      },
    ],
  },
  {
    value: 310000,
    pid: 0,
    label: '上海',
    children: [
      {
        value: 310100,
        pid: 310000,
        label: '上海市',
        children: [
          { value: 310101, pid: 310100, label: '黄浦区' },
          { value: 310104, pid: 310100, label: '徐汇区' },
          { value: 310105, pid: 310100, label: '长宁区' },
          { value: 310106, pid: 310100, label: '静安区' },
          { value: 310107, pid: 310100, label: '普陀区' },
          { value: 310109, pid: 310100, label: '虹口区' },
          { value: 310110, pid: 310100, label: '杨浦区' },
          { value: 310112, pid: 310100, label: '闵行区' },
          { value: 310113, pid: 310100, label: '宝山区' },
          { value: 310114, pid: 310100, label: '嘉定区' },
          { value: 310115, pid: 310100, label: '浦东新区' },
          { value: 310116, pid: 310100, label: '金山区' },
          { value: 310117, pid: 310100, label: '松江区' },
          { value: 310118, pid: 310100, label: '青浦区' },
          { value: 310120, pid: 310100, label: '奉贤区' },
          { value: 310151, pid: 310100, label: '崇明区' },
          { value: 310231, pid: 310100, label: '张江高新区' },
          { value: 310232, pid: 310100, label: '紫竹高新区' },
          { value: 310233, pid: 310100, label: '漕河泾开发区' },
        ],
      },
    ],
  },
  {
    value: 320000,
    pid: 0,
    label: '江苏省',
    children: [
      {
        value: 320100,
        pid: 320000,
        label: '南京市',
        children: [
          { value: 320102, pid: 320100, label: '玄武区' },
          { value: 320104, pid: 320100, label: '秦淮区' },
          { value: 320105, pid: 320100, label: '建邺区' },
          { value: 320106, pid: 320100, label: '鼓楼区' },
          { value: 320111, pid: 320100, label: '浦口区' },
          { value: 320113, pid: 320100, label: '栖霞区' },
          { value: 320114, pid: 320100, label: '雨花台区' },
          { value: 320115, pid: 320100, label: '江宁区' },
          { value: 320116, pid: 320100, label: '六合区' },
          { value: 320117, pid: 320100, label: '溧水区' },
          { value: 320118, pid: 320100, label: '高淳区' },
          { value: 320119, pid: 320100, label: '江北新区' },
          { value: 320120, pid: 320100, label: '高新区' },
        ],
      },
      {
        value: 320200,
        pid: 320000,
        label: '无锡市',
        children: [
          { value: 320205, pid: 320200, label: '锡山区' },
          { value: 320206, pid: 320200, label: '惠山区' },
          { value: 320211, pid: 320200, label: '滨湖区' },
          { value: 320213, pid: 320200, label: '梁溪区' },
          { value: 320214, pid: 320200, label: '新吴区' },
          { value: 320281, pid: 320200, label: '江阴市' },
          { value: 320282, pid: 320200, label: '宜兴市' },
        ],
      },
      {
        value: 320300,
        pid: 320000,
        label: '徐州市',
        children: [
          { value: 320302, pid: 320300, label: '鼓楼区' },
          { value: 320303, pid: 320300, label: '云龙区' },
          { value: 320305, pid: 320300, label: '贾汪区' },
          { value: 320311, pid: 320300, label: '泉山区' },
          { value: 320312, pid: 320300, label: '铜山区' },
          { value: 320321, pid: 320300, label: '丰县' },
          { value: 320322, pid: 320300, label: '沛县' },
          { value: 320324, pid: 320300, label: '睢宁县' },
          { value: 320381, pid: 320300, label: '新沂市' },
          { value: 320382, pid: 320300, label: '邳州市' },
          { value: 320383, pid: 320300, label: '经济技术开发区' },
          { value: 320384, pid: 320300, label: '高新区' },
          { value: 320385, pid: 320300, label: '软件园' },
        ],
      },
      {
        value: 320400,
        pid: 320000,
        label: '常州市',
        children: [
          { value: 320402, pid: 320400, label: '天宁区' },
          { value: 320404, pid: 320400, label: '钟楼区' },
          { value: 320411, pid: 320400, label: '新北区' },
          { value: 320412, pid: 320400, label: '武进区' },
          { value: 320413, pid: 320400, label: '金坛区' },
          { value: 320481, pid: 320400, label: '溧阳市' },
          { value: 320482, pid: 320400, label: '高新区' },
        ],
      },
      {
        value: 320500,
        pid: 320000,
        label: '苏州市',
        children: [
          { value: 320505, pid: 320500, label: '虎丘区' },
          { value: 320506, pid: 320500, label: '吴中区' },
          { value: 320507, pid: 320500, label: '相城区' },
          { value: 320508, pid: 320500, label: '姑苏区' },
          { value: 320509, pid: 320500, label: '吴江区' },
          { value: 320581, pid: 320500, label: '常熟市' },
          { value: 320582, pid: 320500, label: '张家港市' },
          { value: 320583, pid: 320500, label: '昆山市' },
          { value: 320585, pid: 320500, label: '太仓市' },
          { value: 320586, pid: 320500, label: '苏州新区' },
          { value: 320587, pid: 320500, label: '工业园区' },
          { value: 320588, pid: 320500, label: '高新区' },
        ],
      },
      {
        value: 320600,
        pid: 320000,
        label: '南通市',
        children: [
          { value: 320602, pid: 320600, label: '崇川区' },
          { value: 320611, pid: 320600, label: '港闸区' },
          { value: 320612, pid: 320600, label: '通州区' },
          { value: 320623, pid: 320600, label: '如东县' },
          { value: 320681, pid: 320600, label: '启东市' },
          { value: 320682, pid: 320600, label: '如皋市' },
          { value: 320684, pid: 320600, label: '海门市' },
          { value: 320685, pid: 320600, label: '海安市' },
          { value: 320686, pid: 320600, label: '经济技术开发区' },
        ],
      },
      {
        value: 320700,
        pid: 320000,
        label: '连云港市',
        children: [
          { value: 320703, pid: 320700, label: '连云区' },
          { value: 320706, pid: 320700, label: '海州区' },
          { value: 320707, pid: 320700, label: '赣榆区' },
          { value: 320722, pid: 320700, label: '东海县' },
          { value: 320723, pid: 320700, label: '灌云县' },
          { value: 320724, pid: 320700, label: '灌南县' },
          { value: 320725, pid: 320700, label: '新海新区' },
          { value: 320726, pid: 320700, label: '连云新城' },
          { value: 320727, pid: 320700, label: '徐圩新区' },
          { value: 320728, pid: 320700, label: '济技术开发区' },
        ],
      },
      {
        value: 320800,
        pid: 320000,
        label: '淮安市',
        children: [
          { value: 320803, pid: 320800, label: '淮安区' },
          { value: 320804, pid: 320800, label: '淮阴区' },
          { value: 320812, pid: 320800, label: '清江浦区' },
          { value: 320813, pid: 320800, label: '洪泽区' },
          { value: 320826, pid: 320800, label: '涟水县' },
          { value: 320830, pid: 320800, label: '盱眙县' },
          { value: 320831, pid: 320800, label: '金湖县' },
          { value: 320832, pid: 320800, label: '经济开发区' },
        ],
      },
      {
        value: 320900,
        pid: 320000,
        label: '盐城市',
        children: [
          { value: 320902, pid: 320900, label: '亭湖区' },
          { value: 320903, pid: 320900, label: '盐都区' },
          { value: 320904, pid: 320900, label: '大丰区' },
          { value: 320921, pid: 320900, label: '响水县' },
          { value: 320922, pid: 320900, label: '滨海县' },
          { value: 320923, pid: 320900, label: '阜宁县' },
          { value: 320924, pid: 320900, label: '射阳县' },
          { value: 320925, pid: 320900, label: '建湖县' },
          { value: 320981, pid: 320900, label: '东台市' },
        ],
      },
      {
        value: 321000,
        pid: 320000,
        label: '扬州市',
        children: [
          { value: 321002, pid: 321000, label: '广陵区' },
          { value: 321003, pid: 321000, label: '邗江区' },
          { value: 321012, pid: 321000, label: '江都区' },
          { value: 321023, pid: 321000, label: '宝应县' },
          { value: 321081, pid: 321000, label: '仪征市' },
          { value: 321084, pid: 321000, label: '高邮市' },
        ],
      },
      {
        value: 321100,
        pid: 320000,
        label: '镇江市',
        children: [
          { value: 321102, pid: 321100, label: '京口区' },
          { value: 321111, pid: 321100, label: '润州区' },
          { value: 321112, pid: 321100, label: '丹徒区' },
          { value: 321181, pid: 321100, label: '丹阳市' },
          { value: 321182, pid: 321100, label: '扬中市' },
          { value: 321183, pid: 321100, label: '句容市' },
          { value: 321184, pid: 321100, label: '镇江新区' },
          { value: 321185, pid: 321100, label: '镇江新区' },
          { value: 321186, pid: 321100, label: '经济开发区' },
        ],
      },
      {
        value: 321200,
        pid: 320000,
        label: '泰州市',
        children: [
          { value: 321202, pid: 321200, label: '海陵区' },
          { value: 321203, pid: 321200, label: '高港区' },
          { value: 321204, pid: 321200, label: '姜堰区' },
          { value: 321281, pid: 321200, label: '兴化市' },
          { value: 321282, pid: 321200, label: '靖江市' },
          { value: 321283, pid: 321200, label: '泰兴市' },
        ],
      },
      {
        value: 321300,
        pid: 320000,
        label: '宿迁市',
        children: [
          { value: 321302, pid: 321300, label: '宿城区' },
          { value: 321311, pid: 321300, label: '宿豫区' },
          { value: 321322, pid: 321300, label: '沭阳县' },
          { value: 321323, pid: 321300, label: '泗阳县' },
          { value: 321324, pid: 321300, label: '泗洪县' },
          { value: 321325, pid: 321300, label: '高新区' },
        ],
      },
    ],
  },
  {
    value: 330000,
    pid: 0,
    label: '浙江省',
    children: [
      {
        value: 330100,
        pid: 330000,
        label: '杭州市',
        children: [
          { value: 330102, pid: 330100, label: '上城区' },
          { value: 330103, pid: 330100, label: '下城区' },
          { value: 330104, pid: 330100, label: '江干区' },
          { value: 330105, pid: 330100, label: '拱墅区' },
          { value: 330106, pid: 330100, label: '西湖区' },
          { value: 330108, pid: 330100, label: '滨江区' },
          { value: 330109, pid: 330100, label: '萧山区' },
          { value: 330110, pid: 330100, label: '余杭区' },
          { value: 330111, pid: 330100, label: '富阳区' },
          { value: 330112, pid: 330100, label: '临安区' },
          { value: 330122, pid: 330100, label: '桐庐县' },
          { value: 330127, pid: 330100, label: '淳安县' },
          { value: 330182, pid: 330100, label: '建德市' },
          { value: 330186, pid: 330100, label: '高新区' },
        ],
      },
      {
        value: 330200,
        pid: 330000,
        label: '宁波市',
        children: [
          { value: 330203, pid: 330200, label: '海曙区' },
          { value: 330205, pid: 330200, label: '江北区' },
          { value: 330206, pid: 330200, label: '北仑区' },
          { value: 330211, pid: 330200, label: '镇海区' },
          { value: 330212, pid: 330200, label: '鄞州区' },
          { value: 330213, pid: 330200, label: '奉化区' },
          { value: 330225, pid: 330200, label: '象山县' },
          { value: 330226, pid: 330200, label: '宁海县' },
          { value: 330281, pid: 330200, label: '余姚市' },
          { value: 330282, pid: 330200, label: '慈溪市' },
          { value: 330284, pid: 330200, label: '杭州湾新区' },
          { value: 330285, pid: 330200, label: '高新区' },
        ],
      },
      {
        value: 330300,
        pid: 330000,
        label: '温州市',
        children: [
          { value: 330302, pid: 330300, label: '鹿城区' },
          { value: 330303, pid: 330300, label: '龙湾区' },
          { value: 330304, pid: 330300, label: '瓯海区' },
          { value: 330305, pid: 330300, label: '洞头区' },
          { value: 330324, pid: 330300, label: '永嘉县' },
          { value: 330326, pid: 330300, label: '平阳县' },
          { value: 330327, pid: 330300, label: '苍南县' },
          { value: 330328, pid: 330300, label: '文成县' },
          { value: 330329, pid: 330300, label: '泰顺县' },
          { value: 330381, pid: 330300, label: '瑞安市' },
          { value: 330382, pid: 330300, label: '乐清市' },
        ],
      },
      {
        value: 330400,
        pid: 330000,
        label: '嘉兴市',
        children: [
          { value: 330402, pid: 330400, label: '南湖区' },
          { value: 330411, pid: 330400, label: '秀洲区' },
          { value: 330421, pid: 330400, label: '嘉善县' },
          { value: 330424, pid: 330400, label: '海盐县' },
          { value: 330481, pid: 330400, label: '海宁市' },
          { value: 330482, pid: 330400, label: '平湖市' },
          { value: 330483, pid: 330400, label: '桐乡市' },
        ],
      },
      {
        value: 330500,
        pid: 330000,
        label: '湖州市',
        children: [
          { value: 330502, pid: 330500, label: '吴兴区' },
          { value: 330503, pid: 330500, label: '南浔区' },
          { value: 330521, pid: 330500, label: '德清县' },
          { value: 330522, pid: 330500, label: '长兴县' },
          { value: 330523, pid: 330500, label: '安吉县' },
        ],
      },
      {
        value: 330600,
        pid: 330000,
        label: '绍兴市',
        children: [
          { value: 330602, pid: 330600, label: '越城区' },
          { value: 330603, pid: 330600, label: '柯桥区' },
          { value: 330604, pid: 330600, label: '上虞区' },
          { value: 330624, pid: 330600, label: '新昌县' },
          { value: 330681, pid: 330600, label: '诸暨市' },
          { value: 330683, pid: 330600, label: '嵊州市' },
        ],
      },
      {
        value: 330700,
        pid: 330000,
        label: '金华市',
        children: [
          { value: 330702, pid: 330700, label: '婺城区' },
          { value: 330703, pid: 330700, label: '金东区' },
          { value: 330723, pid: 330700, label: '武义县' },
          { value: 330726, pid: 330700, label: '浦江县' },
          { value: 330727, pid: 330700, label: '磐安县' },
          { value: 330781, pid: 330700, label: '兰溪市' },
          { value: 330782, pid: 330700, label: '义乌市' },
          { value: 330783, pid: 330700, label: '东阳市' },
          { value: 330784, pid: 330700, label: '永康市' },
        ],
      },
      {
        value: 330800,
        pid: 330000,
        label: '衢州市',
        children: [
          { value: 330802, pid: 330800, label: '柯城区' },
          { value: 330803, pid: 330800, label: '衢江区' },
          { value: 330822, pid: 330800, label: '常山县' },
          { value: 330824, pid: 330800, label: '开化县' },
          { value: 330825, pid: 330800, label: '龙游县' },
          { value: 330881, pid: 330800, label: '江山市' },
        ],
      },
      {
        value: 330900,
        pid: 330000,
        label: '舟山市',
        children: [
          { value: 330902, pid: 330900, label: '定海区' },
          { value: 330903, pid: 330900, label: '普陀区' },
          { value: 330921, pid: 330900, label: '岱山县' },
          { value: 330922, pid: 330900, label: '嵊泗县' },
        ],
      },
      {
        value: 331000,
        pid: 330000,
        label: '台州市',
        children: [
          { value: 331002, pid: 331000, label: '椒江区' },
          { value: 331003, pid: 331000, label: '黄岩区' },
          { value: 331004, pid: 331000, label: '路桥区' },
          { value: 331022, pid: 331000, label: '三门县' },
          { value: 331023, pid: 331000, label: '天台县' },
          { value: 331024, pid: 331000, label: '仙居县' },
          { value: 331081, pid: 331000, label: '温岭市' },
          { value: 331082, pid: 331000, label: '临海市' },
          { value: 331083, pid: 331000, label: '玉环市' },
        ],
      },
      {
        value: 331100,
        pid: 330000,
        label: '丽水市',
        children: [
          { value: 331102, pid: 331100, label: '莲都区' },
          { value: 331121, pid: 331100, label: '青田县' },
          { value: 331122, pid: 331100, label: '缙云县' },
          { value: 331123, pid: 331100, label: '遂昌县' },
          { value: 331124, pid: 331100, label: '松阳县' },
          { value: 331125, pid: 331100, label: '云和县' },
          { value: 331126, pid: 331100, label: '庆元县' },
          { value: 331127, pid: 331100, label: '景宁畲族自治县' },
          { value: 331181, pid: 331100, label: '龙泉市' },
        ],
      },
      {
        value: 331200,
        pid: 330000,
        label: '舟山群岛新区',
        children: [
          { value: 331201, pid: 331200, label: '金塘岛' },
          { value: 331202, pid: 331200, label: '六横岛' },
          { value: 331203, pid: 331200, label: '衢山岛' },
          { value: 331204, pid: 331200, label: '舟山本岛西北部' },
          { value: 331205, pid: 331200, label: '岱山岛西南部' },
          { value: 331206, pid: 331200, label: '泗礁岛' },
          { value: 331207, pid: 331200, label: '朱家尖岛' },
          { value: 331208, pid: 331200, label: '洋山岛' },
          { value: 331209, pid: 331200, label: '长涂岛' },
          { value: 331210, pid: 331200, label: '虾峙岛' },
        ],
      },
    ],
  },
  {
    value: 340000,
    pid: 0,
    label: '安徽省',
    children: [
      {
        value: 340100,
        pid: 340000,
        label: '合肥市',
        children: [
          { value: 340102, pid: 340100, label: '瑶海区' },
          { value: 340103, pid: 340100, label: '庐阳区' },
          { value: 340104, pid: 340100, label: '蜀山区' },
          { value: 340111, pid: 340100, label: '包河区' },
          { value: 340121, pid: 340100, label: '长丰县' },
          { value: 340122, pid: 340100, label: '肥东县' },
          { value: 340123, pid: 340100, label: '肥西县' },
          { value: 340124, pid: 340100, label: '庐江县' },
          { value: 340181, pid: 340100, label: '巢湖市' },
          { value: 340184, pid: 340100, label: '经济开发区' },
          { value: 340185, pid: 340100, label: '高新区' },
          { value: 340186, pid: 340100, label: '北城新区' },
          { value: 340187, pid: 340100, label: '滨湖新区' },
          { value: 340188, pid: 340100, label: '政务文化新区' },
          { value: 340189, pid: 340100, label: '新站综合开发试验区' },
        ],
      },
      {
        value: 340200,
        pid: 340000,
        label: '芜湖市',
        children: [
          { value: 340202, pid: 340200, label: '镜湖区' },
          { value: 340203, pid: 340200, label: '弋江区' },
          { value: 340207, pid: 340200, label: '鸠江区' },
          { value: 340208, pid: 340200, label: '三山区' },
          { value: 340221, pid: 340200, label: '芜湖县' },
          { value: 340222, pid: 340200, label: '繁昌县' },
          { value: 340223, pid: 340200, label: '南陵县' },
          { value: 340225, pid: 340200, label: '无为县' },
          { value: 340226, pid: 340200, label: '经济开发区' },
          { value: 340227, pid: 340200, label: '城东新区' },
        ],
      },
      {
        value: 340300,
        pid: 340000,
        label: '蚌埠市',
        children: [
          { value: 340302, pid: 340300, label: '龙子湖区' },
          { value: 340303, pid: 340300, label: '蚌山区' },
          { value: 340304, pid: 340300, label: '禹会区' },
          { value: 340311, pid: 340300, label: '淮上区' },
          { value: 340321, pid: 340300, label: '怀远县' },
          { value: 340322, pid: 340300, label: '五河县' },
          { value: 340323, pid: 340300, label: '固镇县' },
          { value: 340324, pid: 340300, label: '高新区' },
        ],
      },
      {
        value: 340400,
        pid: 340000,
        label: '淮南市',
        children: [
          { value: 340402, pid: 340400, label: '大通区' },
          { value: 340403, pid: 340400, label: '田家庵区' },
          { value: 340404, pid: 340400, label: '谢家集区' },
          { value: 340405, pid: 340400, label: '八公山区' },
          { value: 340406, pid: 340400, label: '潘集区' },
          { value: 340421, pid: 340400, label: '凤台县' },
          { value: 340422, pid: 340400, label: '寿县' },
          { value: 340423, pid: 340400, label: '山南新区' },
          { value: 340424, pid: 340400, label: '毛集实验区' },
          { value: 340425, pid: 340400, label: '经济开发区' },
        ],
      },
      {
        value: 340500,
        pid: 340000,
        label: '马鞍山市',
        children: [
          { value: 340503, pid: 340500, label: '花山区' },
          { value: 340504, pid: 340500, label: '雨山区' },
          { value: 340506, pid: 340500, label: '博望区' },
          { value: 340521, pid: 340500, label: '当涂县' },
          { value: 340522, pid: 340500, label: '含山县' },
          { value: 340523, pid: 340500, label: '和县' },
        ],
      },
      {
        value: 340600,
        pid: 340000,
        label: '淮北市',
        children: [
          { value: 340602, pid: 340600, label: '杜集区' },
          { value: 340603, pid: 340600, label: '相山区' },
          { value: 340604, pid: 340600, label: '烈山区' },
          { value: 340621, pid: 340600, label: '濉溪县' },
        ],
      },
      {
        value: 340700,
        pid: 340000,
        label: '铜陵市',
        children: [
          { value: 340705, pid: 340700, label: '铜官区' },
          { value: 340706, pid: 340700, label: '义安区' },
          { value: 340711, pid: 340700, label: '郊区' },
          { value: 340722, pid: 340700, label: '枞阳县' },
        ],
      },
      {
        value: 340800,
        pid: 340000,
        label: '安庆市',
        children: [
          { value: 340802, pid: 340800, label: '迎江区' },
          { value: 340803, pid: 340800, label: '大观区' },
          { value: 340811, pid: 340800, label: '宜秀区' },
          { value: 340822, pid: 340800, label: '怀宁县' },
          { value: 340825, pid: 340800, label: '太湖县' },
          { value: 340826, pid: 340800, label: '宿松县' },
          { value: 340827, pid: 340800, label: '望江县' },
          { value: 340828, pid: 340800, label: '岳西县' },
          { value: 340881, pid: 340800, label: '桐城市' },
          { value: 340882, pid: 340800, label: '潜山市' },
        ],
      },
      {
        value: 341000,
        pid: 340000,
        label: '黄山市',
        children: [
          { value: 341002, pid: 341000, label: '屯溪区' },
          { value: 341003, pid: 341000, label: '黄山区' },
          { value: 341004, pid: 341000, label: '徽州区' },
          { value: 341021, pid: 341000, label: '歙县' },
          { value: 341022, pid: 341000, label: '休宁县' },
          { value: 341023, pid: 341000, label: '黟县' },
          { value: 341024, pid: 341000, label: '祁门县' },
        ],
      },
      {
        value: 341100,
        pid: 340000,
        label: '滁州市',
        children: [
          { value: 341102, pid: 341100, label: '琅琊区' },
          { value: 341103, pid: 341100, label: '南谯区' },
          { value: 341122, pid: 341100, label: '来安县' },
          { value: 341124, pid: 341100, label: '全椒县' },
          { value: 341125, pid: 341100, label: '定远县' },
          { value: 341126, pid: 341100, label: '凤阳县' },
          { value: 341181, pid: 341100, label: '天长市' },
          { value: 341182, pid: 341100, label: '明光市' },
        ],
      },
      {
        value: 341200,
        pid: 340000,
        label: '阜阳市',
        children: [
          { value: 341202, pid: 341200, label: '颍州区' },
          { value: 341203, pid: 341200, label: '颍东区' },
          { value: 341204, pid: 341200, label: '颍泉区' },
          { value: 341221, pid: 341200, label: '临泉县' },
          { value: 341222, pid: 341200, label: '太和县' },
          { value: 341225, pid: 341200, label: '阜南县' },
          { value: 341226, pid: 341200, label: '颍上县' },
          { value: 341282, pid: 341200, label: '界首市' },
          { value: 341283, pid: 341200, label: '经济开发区' },
          { value: 341284, pid: 341200, label: '苏滁现代产业园' },
        ],
      },
      {
        value: 341300,
        pid: 340000,
        label: '宿州市',
        children: [
          { value: 341302, pid: 341300, label: '埇桥区' },
          { value: 341321, pid: 341300, label: '砀山县' },
          { value: 341322, pid: 341300, label: '萧县' },
          { value: 341323, pid: 341300, label: '灵璧县' },
          { value: 341324, pid: 341300, label: '泗县' },
          { value: 341325, pid: 341300, label: '经济开发区' },
          { value: 341371, pid: 341300, label: '宿马现代产业园' },
        ],
      },
      {
        value: 341500,
        pid: 340000,
        label: '六安市',
        children: [
          { value: 341502, pid: 341500, label: '金安区' },
          { value: 341503, pid: 341500, label: '裕安区' },
          { value: 341504, pid: 341500, label: '叶集区' },
          { value: 341522, pid: 341500, label: '霍邱县' },
          { value: 341523, pid: 341500, label: '舒城县' },
          { value: 341524, pid: 341500, label: '金寨县' },
          { value: 341525, pid: 341500, label: '霍山县' },
        ],
      },
      {
        value: 341600,
        pid: 340000,
        label: '亳州市',
        children: [
          { value: 341602, pid: 341600, label: '谯城区' },
          { value: 341621, pid: 341600, label: '涡阳县' },
          { value: 341622, pid: 341600, label: '蒙城县' },
          { value: 341623, pid: 341600, label: '利辛县' },
        ],
      },
      {
        value: 341700,
        pid: 340000,
        label: '池州市',
        children: [
          { value: 341702, pid: 341700, label: '贵池区' },
          { value: 341721, pid: 341700, label: '东至县' },
          { value: 341722, pid: 341700, label: '石台县' },
          { value: 341723, pid: 341700, label: '青阳县' },
        ],
      },
      {
        value: 341800,
        pid: 340000,
        label: '宣城市',
        children: [
          { value: 341802, pid: 341800, label: '宣州区' },
          { value: 341821, pid: 341800, label: '郎溪县' },
          { value: 341822, pid: 341800, label: '广德县' },
          { value: 341823, pid: 341800, label: '泾县' },
          { value: 341824, pid: 341800, label: '绩溪县' },
          { value: 341825, pid: 341800, label: '旌德县' },
          { value: 341881, pid: 341800, label: '宁国市' },
        ],
      },
    ],
  },
  {
    value: 350000,
    pid: 0,
    label: '福建省',
    children: [
      {
        value: 350100,
        pid: 350000,
        label: '福州市',
        children: [
          { value: 350102, pid: 350100, label: '鼓楼区' },
          { value: 350103, pid: 350100, label: '台江区' },
          { value: 350104, pid: 350100, label: '仓山区' },
          { value: 350105, pid: 350100, label: '马尾区' },
          { value: 350111, pid: 350100, label: '晋安区' },
          { value: 350112, pid: 350100, label: '长乐区' },
          { value: 350121, pid: 350100, label: '闽侯县' },
          { value: 350122, pid: 350100, label: '连江县' },
          { value: 350123, pid: 350100, label: '罗源县' },
          { value: 350124, pid: 350100, label: '闽清县' },
          { value: 350125, pid: 350100, label: '永泰县' },
          { value: 350128, pid: 350100, label: '平潭县' },
          { value: 350181, pid: 350100, label: '福清市' },
          { value: 350183, pid: 350100, label: '福州新区' },
        ],
      },
      {
        value: 350200,
        pid: 350000,
        label: '厦门市',
        children: [
          { value: 350203, pid: 350200, label: '思明区' },
          { value: 350205, pid: 350200, label: '海沧区' },
          { value: 350206, pid: 350200, label: '湖里区' },
          { value: 350211, pid: 350200, label: '集美区' },
          { value: 350212, pid: 350200, label: '同安区' },
          { value: 350213, pid: 350200, label: '翔安区' },
        ],
      },
      {
        value: 350300,
        pid: 350000,
        label: '莆田市',
        children: [
          { value: 350302, pid: 350300, label: '城厢区' },
          { value: 350303, pid: 350300, label: '涵江区' },
          { value: 350304, pid: 350300, label: '荔城区' },
          { value: 350305, pid: 350300, label: '秀屿区' },
          { value: 350322, pid: 350300, label: '仙游县' },
        ],
      },
      {
        value: 350400,
        pid: 350000,
        label: '三明市',
        children: [
          { value: 350402, pid: 350400, label: '梅列区' },
          { value: 350403, pid: 350400, label: '三元区' },
          { value: 350421, pid: 350400, label: '明溪县' },
          { value: 350423, pid: 350400, label: '清流县' },
          { value: 350424, pid: 350400, label: '宁化县' },
          { value: 350425, pid: 350400, label: '大田县' },
          { value: 350426, pid: 350400, label: '尤溪县' },
          { value: 350427, pid: 350400, label: '沙县' },
          { value: 350428, pid: 350400, label: '将乐县' },
          { value: 350429, pid: 350400, label: '泰宁县' },
          { value: 350430, pid: 350400, label: '建宁县' },
          { value: 350481, pid: 350400, label: '永安市' },
        ],
      },
      {
        value: 350500,
        pid: 350000,
        label: '泉州市',
        children: [
          { value: 350502, pid: 350500, label: '鲤城区' },
          { value: 350503, pid: 350500, label: '丰泽区' },
          { value: 350504, pid: 350500, label: '洛江区' },
          { value: 350505, pid: 350500, label: '泉港区' },
          { value: 350521, pid: 350500, label: '惠安县' },
          { value: 350524, pid: 350500, label: '安溪县' },
          { value: 350525, pid: 350500, label: '永春县' },
          { value: 350526, pid: 350500, label: '德化县' },
          { value: 350527, pid: 350500, label: '金门县' },
          { value: 350581, pid: 350500, label: '石狮市' },
          { value: 350582, pid: 350500, label: '晋江市' },
          { value: 350583, pid: 350500, label: '南安市' },
          { value: 350584, pid: 350500, label: '台商投资区' },
          { value: 350585, pid: 350500, label: '经济技术开发区' },
          { value: 350586, pid: 350500, label: '高新区' },
          { value: 350587, pid: 350500, label: '综合保税区' },
        ],
      },
      {
        value: 350600,
        pid: 350000,
        label: '漳州市',
        children: [
          { value: 350602, pid: 350600, label: '芗城区' },
          { value: 350603, pid: 350600, label: '龙文区' },
          { value: 350622, pid: 350600, label: '云霄县' },
          { value: 350623, pid: 350600, label: '漳浦县' },
          { value: 350624, pid: 350600, label: '诏安县' },
          { value: 350625, pid: 350600, label: '长泰县' },
          { value: 350626, pid: 350600, label: '东山县' },
          { value: 350627, pid: 350600, label: '南靖县' },
          { value: 350628, pid: 350600, label: '平和县' },
          { value: 350629, pid: 350600, label: '华安县' },
          { value: 350681, pid: 350600, label: '龙海市' },
        ],
      },
      {
        value: 350700,
        pid: 350000,
        label: '南平市',
        children: [
          { value: 350702, pid: 350700, label: '延平区' },
          { value: 350703, pid: 350700, label: '建阳区' },
          { value: 350721, pid: 350700, label: '顺昌县' },
          { value: 350722, pid: 350700, label: '浦城县' },
          { value: 350723, pid: 350700, label: '光泽县' },
          { value: 350724, pid: 350700, label: '松溪县' },
          { value: 350725, pid: 350700, label: '政和县' },
          { value: 350781, pid: 350700, label: '邵武市' },
          { value: 350782, pid: 350700, label: '武夷山市' },
          { value: 350783, pid: 350700, label: '建瓯市' },
        ],
      },
      {
        value: 350800,
        pid: 350000,
        label: '龙岩市',
        children: [
          { value: 350802, pid: 350800, label: '新罗区' },
          { value: 350803, pid: 350800, label: '永定区' },
          { value: 350821, pid: 350800, label: '长汀县' },
          { value: 350823, pid: 350800, label: '上杭县' },
          { value: 350824, pid: 350800, label: '武平县' },
          { value: 350825, pid: 350800, label: '连城县' },
          { value: 350881, pid: 350800, label: '漳平市' },
        ],
      },
      {
        value: 350900,
        pid: 350000,
        label: '宁德市',
        children: [
          { value: 350902, pid: 350900, label: '蕉城区' },
          { value: 350921, pid: 350900, label: '霞浦县' },
          { value: 350922, pid: 350900, label: '古田县' },
          { value: 350923, pid: 350900, label: '屏南县' },
          { value: 350924, pid: 350900, label: '寿宁县' },
          { value: 350925, pid: 350900, label: '周宁县' },
          { value: 350926, pid: 350900, label: '柘荣县' },
          { value: 350981, pid: 350900, label: '福安市' },
          { value: 350982, pid: 350900, label: '福鼎市' },
          { value: 350983, pid: 350900, label: '东侨开发区' },
        ],
      },
    ],
  },
  {
    value: 360000,
    pid: 0,
    label: '江西省',
    children: [
      {
        value: 360100,
        pid: 360000,
        label: '南昌市',
        children: [
          { value: 360102, pid: 360100, label: '东湖区' },
          { value: 360103, pid: 360100, label: '西湖区' },
          { value: 360104, pid: 360100, label: '青云谱区' },
          { value: 360105, pid: 360100, label: '湾里区' },
          { value: 360111, pid: 360100, label: '青山湖区' },
          { value: 360112, pid: 360100, label: '新建区' },
          { value: 360121, pid: 360100, label: '南昌县' },
          { value: 360123, pid: 360100, label: '安义县' },
          { value: 360124, pid: 360100, label: '进贤县' },
          { value: 360125, pid: 360100, label: '红谷滩新区' },
          { value: 360126, pid: 360100, label: '高新区' },
          { value: 360127, pid: 360100, label: '经济开发区' },
          { value: 360128, pid: 360100, label: '小蓝开发区' },
          { value: 360129, pid: 360100, label: '桑海开发区' },
          { value: 360130, pid: 360100, label: '望城新区' },
          { value: 360131, pid: 360100, label: '赣江新区' },
        ],
      },
      {
        value: 360200,
        pid: 360000,
        label: '景德镇市',
        children: [
          { value: 360202, pid: 360200, label: '昌江区' },
          { value: 360203, pid: 360200, label: '珠山区' },
          { value: 360222, pid: 360200, label: '浮梁县' },
          { value: 360281, pid: 360200, label: '乐平市' },
        ],
      },
      {
        value: 360300,
        pid: 360000,
        label: '萍乡市',
        children: [
          { value: 360302, pid: 360300, label: '安源区' },
          { value: 360313, pid: 360300, label: '湘东区' },
          { value: 360321, pid: 360300, label: '莲花县' },
          { value: 360322, pid: 360300, label: '上栗县' },
          { value: 360323, pid: 360300, label: '芦溪县' },
        ],
      },
      {
        value: 360400,
        pid: 360000,
        label: '九江市',
        children: [
          { value: 360402, pid: 360400, label: '濂溪区' },
          { value: 360403, pid: 360400, label: '浔阳区' },
          { value: 360404, pid: 360400, label: '柴桑区' },
          { value: 360423, pid: 360400, label: '武宁县' },
          { value: 360424, pid: 360400, label: '修水县' },
          { value: 360425, pid: 360400, label: '永修县' },
          { value: 360426, pid: 360400, label: '德安县' },
          { value: 360428, pid: 360400, label: '都昌县' },
          { value: 360429, pid: 360400, label: '湖口县' },
          { value: 360430, pid: 360400, label: '彭泽县' },
          { value: 360481, pid: 360400, label: '瑞昌市' },
          { value: 360482, pid: 360400, label: '共青城市' },
          { value: 360483, pid: 360400, label: '庐山市' },
          { value: 360484, pid: 360400, label: '经济技术开发区' },
          { value: 360485, pid: 360400, label: '八里湖新区' },
          { value: 360486, pid: 360400, label: '庐山风景名胜区' },
        ],
      },
      {
        value: 360500,
        pid: 360000,
        label: '新余市',
        children: [
          { value: 360502, pid: 360500, label: '渝水区' },
          { value: 360521, pid: 360500, label: '分宜县' },
        ],
      },
      {
        value: 360600,
        pid: 360000,
        label: '鹰潭市',
        children: [
          { value: 360602, pid: 360600, label: '月湖区' },
          { value: 360603, pid: 360600, label: '余江区' },
          { value: 360681, pid: 360600, label: '贵溪市' },
          { value: 360682, pid: 360600, label: '高新区' },
        ],
      },
      {
        value: 360700,
        pid: 360000,
        label: '赣州市',
        children: [
          { value: 360702, pid: 360700, label: '章贡区' },
          { value: 360703, pid: 360700, label: '南康区' },
          { value: 360704, pid: 360700, label: '赣县区' },
          { value: 360722, pid: 360700, label: '信丰县' },
          { value: 360723, pid: 360700, label: '大余县' },
          { value: 360724, pid: 360700, label: '上犹县' },
          { value: 360725, pid: 360700, label: '崇义县' },
          { value: 360726, pid: 360700, label: '安远县' },
          { value: 360727, pid: 360700, label: '龙南县' },
          { value: 360728, pid: 360700, label: '定南县' },
          { value: 360729, pid: 360700, label: '全南县' },
          { value: 360730, pid: 360700, label: '宁都县' },
          { value: 360731, pid: 360700, label: '于都县' },
          { value: 360732, pid: 360700, label: '兴国县' },
          { value: 360733, pid: 360700, label: '会昌县' },
          { value: 360734, pid: 360700, label: '寻乌县' },
          { value: 360735, pid: 360700, label: '石城县' },
          { value: 360781, pid: 360700, label: '瑞金市' },
          { value: 360782, pid: 360700, label: '章康新区' },
        ],
      },
      {
        value: 360800,
        pid: 360000,
        label: '吉安市',
        children: [
          { value: 360802, pid: 360800, label: '吉州区' },
          { value: 360803, pid: 360800, label: '青原区' },
          { value: 360821, pid: 360800, label: '吉安县' },
          { value: 360822, pid: 360800, label: '吉水县' },
          { value: 360823, pid: 360800, label: '峡江县' },
          { value: 360824, pid: 360800, label: '新干县' },
          { value: 360825, pid: 360800, label: '永丰县' },
          { value: 360826, pid: 360800, label: '泰和县' },
          { value: 360827, pid: 360800, label: '遂川县' },
          { value: 360828, pid: 360800, label: '万安县' },
          { value: 360829, pid: 360800, label: '安福县' },
          { value: 360830, pid: 360800, label: '永新县' },
          { value: 360881, pid: 360800, label: '井冈山市' },
        ],
      },
      {
        value: 360900,
        pid: 360000,
        label: '宜春市',
        children: [
          { value: 360902, pid: 360900, label: '袁州区' },
          { value: 360921, pid: 360900, label: '奉新县' },
          { value: 360922, pid: 360900, label: '万载县' },
          { value: 360923, pid: 360900, label: '上高县' },
          { value: 360924, pid: 360900, label: '宜丰县' },
          { value: 360925, pid: 360900, label: '靖安县' },
          { value: 360926, pid: 360900, label: '铜鼓县' },
          { value: 360981, pid: 360900, label: '丰城市' },
          { value: 360982, pid: 360900, label: '樟树市' },
          { value: 360983, pid: 360900, label: '高安市' },
        ],
      },
      {
        value: 361000,
        pid: 360000,
        label: '抚州市',
        children: [
          { value: 361002, pid: 361000, label: '临川区' },
          { value: 361003, pid: 361000, label: '东乡区' },
          { value: 361021, pid: 361000, label: '南城县' },
          { value: 361022, pid: 361000, label: '黎川县' },
          { value: 361023, pid: 361000, label: '南丰县' },
          { value: 361024, pid: 361000, label: '崇仁县' },
          { value: 361025, pid: 361000, label: '乐安县' },
          { value: 361026, pid: 361000, label: '宜黄县' },
          { value: 361027, pid: 361000, label: '金溪县' },
          { value: 361028, pid: 361000, label: '资溪县' },
          { value: 361030, pid: 361000, label: '广昌县' },
        ],
      },
      {
        value: 361100,
        pid: 360000,
        label: '上饶市',
        children: [
          { value: 361102, pid: 361100, label: '信州区' },
          { value: 361103, pid: 361100, label: '广丰区' },
          { value: 361121, pid: 361100, label: '上饶县' },
          { value: 361123, pid: 361100, label: '玉山县' },
          { value: 361124, pid: 361100, label: '铅山县' },
          { value: 361125, pid: 361100, label: '横峰县' },
          { value: 361126, pid: 361100, label: '弋阳县' },
          { value: 361127, pid: 361100, label: '余干县' },
          { value: 361128, pid: 361100, label: '鄱阳县' },
          { value: 361129, pid: 361100, label: '万年县' },
          { value: 361130, pid: 361100, label: '婺源县' },
          { value: 361181, pid: 361100, label: '德兴市' },
        ],
      },
    ],
  },
  {
    value: 370000,
    pid: 0,
    label: '山东省',
    children: [
      {
        value: 370100,
        pid: 370000,
        label: '济南市',
        children: [
          { value: 370102, pid: 370100, label: '历下区' },
          { value: 370103, pid: 370100, label: '市中区' },
          { value: 370104, pid: 370100, label: '槐荫区' },
          { value: 370105, pid: 370100, label: '天桥区' },
          { value: 370112, pid: 370100, label: '历城区' },
          { value: 370113, pid: 370100, label: '长清区' },
          { value: 370114, pid: 370100, label: '章丘区' },
          { value: 370115, pid: 370100, label: '济阳区' },
          { value: 370116, pid: 370100, label: '莱芜区' },
          { value: 370117, pid: 370100, label: '钢城区' },
          { value: 370124, pid: 370100, label: '平阴县' },
          { value: 370126, pid: 370100, label: '商河县' },
          { value: 370182, pid: 370100, label: '高新区' },
        ],
      },
      {
        value: 370200,
        pid: 370000,
        label: '青岛市',
        children: [
          { value: 370202, pid: 370200, label: '市南区' },
          { value: 370203, pid: 370200, label: '市北区' },
          { value: 370211, pid: 370200, label: '黄岛区' },
          { value: 370212, pid: 370200, label: '崂山区' },
          { value: 370213, pid: 370200, label: '李沧区' },
          { value: 370214, pid: 370200, label: '城阳区' },
          { value: 370215, pid: 370200, label: '即墨区' },
          { value: 370281, pid: 370200, label: '胶州市' },
          { value: 370283, pid: 370200, label: '平度市' },
          { value: 370285, pid: 370200, label: '莱西市' },
          { value: 370286, pid: 370200, label: '西海岸新区' },
          { value: 370287, pid: 370200, label: '高新区' },
        ],
      },
      {
        value: 370300,
        pid: 370000,
        label: '淄博市',
        children: [
          { value: 370302, pid: 370300, label: '淄川区' },
          { value: 370303, pid: 370300, label: '张店区' },
          { value: 370304, pid: 370300, label: '博山区' },
          { value: 370305, pid: 370300, label: '临淄区' },
          { value: 370306, pid: 370300, label: '周村区' },
          { value: 370321, pid: 370300, label: '桓台县' },
          { value: 370322, pid: 370300, label: '高青县' },
          { value: 370323, pid: 370300, label: '沂源县' },
          { value: 370324, pid: 370300, label: '高新区' },
        ],
      },
      {
        value: 370400,
        pid: 370000,
        label: '枣庄市',
        children: [
          { value: 370402, pid: 370400, label: '市中区' },
          { value: 370403, pid: 370400, label: '薛城区' },
          { value: 370404, pid: 370400, label: '峄城区' },
          { value: 370405, pid: 370400, label: '台儿庄区' },
          { value: 370406, pid: 370400, label: '山亭区' },
          { value: 370481, pid: 370400, label: '滕州市' },
          { value: 370482, pid: 370400, label: '高新区' },
        ],
      },
      {
        value: 370500,
        pid: 370000,
        label: '东营市',
        children: [
          { value: 370502, pid: 370500, label: '东营区' },
          { value: 370503, pid: 370500, label: '河口区' },
          { value: 370505, pid: 370500, label: '垦利区' },
          { value: 370522, pid: 370500, label: '利津县' },
          { value: 370523, pid: 370500, label: '广饶县' },
        ],
      },
      {
        value: 370600,
        pid: 370000,
        label: '烟台市',
        children: [
          { value: 370602, pid: 370600, label: '芝罘区' },
          { value: 370611, pid: 370600, label: '福山区' },
          { value: 370612, pid: 370600, label: '牟平区' },
          { value: 370613, pid: 370600, label: '莱山区' },
          { value: 370634, pid: 370600, label: '长岛县' },
          { value: 370681, pid: 370600, label: '龙口市' },
          { value: 370682, pid: 370600, label: '莱阳市' },
          { value: 370683, pid: 370600, label: '莱州市' },
          { value: 370684, pid: 370600, label: '蓬莱市' },
          { value: 370685, pid: 370600, label: '招远市' },
          { value: 370686, pid: 370600, label: '栖霞市' },
          { value: 370687, pid: 370600, label: '海阳市' },
          { value: 370688, pid: 370600, label: '高新区' },
          { value: 370689, pid: 370600, label: '经济开发区' },
        ],
      },
      {
        value: 370700,
        pid: 370000,
        label: '潍坊市',
        children: [
          { value: 370702, pid: 370700, label: '潍城区' },
          { value: 370703, pid: 370700, label: '寒亭区' },
          { value: 370704, pid: 370700, label: '坊子区' },
          { value: 370705, pid: 370700, label: '奎文区' },
          { value: 370724, pid: 370700, label: '临朐县' },
          { value: 370725, pid: 370700, label: '昌乐县' },
          { value: 370781, pid: 370700, label: '青州市' },
          { value: 370782, pid: 370700, label: '诸城市' },
          { value: 370783, pid: 370700, label: '寿光市' },
          { value: 370784, pid: 370700, label: '安丘市' },
          { value: 370785, pid: 370700, label: '高密市' },
          { value: 370786, pid: 370700, label: '昌邑市' },
          { value: 370787, pid: 370700, label: '高新区' },
        ],
      },
      {
        value: 370800,
        pid: 370000,
        label: '济宁市',
        children: [
          { value: 370811, pid: 370800, label: '任城区' },
          { value: 370812, pid: 370800, label: '兖州区' },
          { value: 370826, pid: 370800, label: '微山县' },
          { value: 370827, pid: 370800, label: '鱼台县' },
          { value: 370828, pid: 370800, label: '金乡县' },
          { value: 370829, pid: 370800, label: '嘉祥县' },
          { value: 370830, pid: 370800, label: '汶上县' },
          { value: 370831, pid: 370800, label: '泗水县' },
          { value: 370832, pid: 370800, label: '梁山县' },
          { value: 370881, pid: 370800, label: '曲阜市' },
          { value: 370883, pid: 370800, label: '邹城市' },
          { value: 370884, pid: 370800, label: '高新区' },
        ],
      },
      {
        value: 370900,
        pid: 370000,
        label: '泰安市',
        children: [
          { value: 370902, pid: 370900, label: '泰山区' },
          { value: 370911, pid: 370900, label: '岱岳区' },
          { value: 370921, pid: 370900, label: '宁阳县' },
          { value: 370923, pid: 370900, label: '东平县' },
          { value: 370982, pid: 370900, label: '新泰市' },
          { value: 370983, pid: 370900, label: '肥城市' },
        ],
      },
      {
        value: 371000,
        pid: 370000,
        label: '威海市',
        children: [
          { value: 371002, pid: 371000, label: '环翠区' },
          { value: 371003, pid: 371000, label: '文登区' },
          { value: 371082, pid: 371000, label: '荣成市' },
          { value: 371083, pid: 371000, label: '乳山市' },
          { value: 371084, pid: 371000, label: '火炬高新区' },
          { value: 371085, pid: 371000, label: '经济技术开发区' },
          { value: 371086, pid: 371000, label: '临港经济技术开发区' },
        ],
      },
      {
        value: 371100,
        pid: 370000,
        label: '日照市',
        children: [
          { value: 371102, pid: 371100, label: '东港区' },
          { value: 371103, pid: 371100, label: '岚山区' },
          { value: 371121, pid: 371100, label: '五莲县' },
          { value: 371122, pid: 371100, label: '莒县' },
        ],
      },
      {
        value: 371300,
        pid: 370000,
        label: '临沂市',
        children: [
          { value: 371302, pid: 371300, label: '兰山区' },
          { value: 371311, pid: 371300, label: '罗庄区' },
          { value: 371312, pid: 371300, label: '河东区' },
          { value: 371321, pid: 371300, label: '沂南县' },
          { value: 371322, pid: 371300, label: '郯城县' },
          { value: 371323, pid: 371300, label: '沂水县' },
          { value: 371324, pid: 371300, label: '兰陵县' },
          { value: 371325, pid: 371300, label: '费县' },
          { value: 371326, pid: 371300, label: '平邑县' },
          { value: 371327, pid: 371300, label: '莒南县' },
          { value: 371328, pid: 371300, label: '蒙阴县' },
          { value: 371329, pid: 371300, label: '临沭县' },
        ],
      },
      {
        value: 371400,
        pid: 370000,
        label: '德州市',
        children: [
          { value: 371402, pid: 371400, label: '德城区' },
          { value: 371403, pid: 371400, label: '陵城区' },
          { value: 371422, pid: 371400, label: '宁津县' },
          { value: 371423, pid: 371400, label: '庆云县' },
          { value: 371424, pid: 371400, label: '临邑县' },
          { value: 371425, pid: 371400, label: '齐河县' },
          { value: 371426, pid: 371400, label: '平原县' },
          { value: 371427, pid: 371400, label: '夏津县' },
          { value: 371428, pid: 371400, label: '武城县' },
          { value: 371481, pid: 371400, label: '乐陵市' },
          { value: 371482, pid: 371400, label: '禹城市' },
        ],
      },
      {
        value: 371500,
        pid: 370000,
        label: '聊城市',
        children: [
          { value: 371502, pid: 371500, label: '东昌府区' },
          { value: 371521, pid: 371500, label: '阳谷县' },
          { value: 371522, pid: 371500, label: '莘县' },
          { value: 371523, pid: 371500, label: '茌平县' },
          { value: 371524, pid: 371500, label: '东阿县' },
          { value: 371525, pid: 371500, label: '冠县' },
          { value: 371526, pid: 371500, label: '高唐县' },
          { value: 371581, pid: 371500, label: '临清市' },
        ],
      },
      {
        value: 371600,
        pid: 370000,
        label: '滨州市',
        children: [
          { value: 371602, pid: 371600, label: '滨城区' },
          { value: 371603, pid: 371600, label: '沾化区' },
          { value: 371621, pid: 371600, label: '惠民县' },
          { value: 371622, pid: 371600, label: '阳信县' },
          { value: 371623, pid: 371600, label: '无棣县' },
          { value: 371625, pid: 371600, label: '博兴县' },
          { value: 371681, pid: 371600, label: '邹平市' },
          { value: 371682, pid: 371600, label: '北海新区' },
        ],
      },
      {
        value: 371700,
        pid: 370000,
        label: '菏泽市',
        children: [
          { value: 371702, pid: 371700, label: '牡丹区' },
          { value: 371703, pid: 371700, label: '定陶区' },
          { value: 371721, pid: 371700, label: '曹县' },
          { value: 371722, pid: 371700, label: '单县' },
          { value: 371723, pid: 371700, label: '成武县' },
          { value: 371724, pid: 371700, label: '巨野县' },
          { value: 371725, pid: 371700, label: '郓城县' },
          { value: 371726, pid: 371700, label: '鄄城县' },
          { value: 371728, pid: 371700, label: '东明县' },
          { value: 371772, pid: 371700, label: '高新开发区' },
        ],
      },
    ],
  },
  {
    value: 410000,
    pid: 0,
    label: '河南省',
    children: [
      {
        value: 410100,
        pid: 410000,
        label: '郑州市',
        children: [
          { value: 410102, pid: 410100, label: '中原区' },
          { value: 410103, pid: 410100, label: '二七区' },
          { value: 410104, pid: 410100, label: '管城回族区' },
          { value: 410105, pid: 410100, label: '金水区' },
          { value: 410106, pid: 410100, label: '上街区' },
          { value: 410108, pid: 410100, label: '惠济区' },
          { value: 410122, pid: 410100, label: '中牟县' },
          { value: 410181, pid: 410100, label: '巩义市' },
          { value: 410182, pid: 410100, label: '荥阳市' },
          { value: 410183, pid: 410100, label: '新密市' },
          { value: 410184, pid: 410100, label: '新郑市' },
          { value: 410185, pid: 410100, label: '登封市' },
          { value: 410186, pid: 410100, label: '郑东新区' },
          { value: 410187, pid: 410100, label: '郑汴新区' },
          { value: 410188, pid: 410100, label: '高新开发区' },
          { value: 410189, pid: 410100, label: '经济开发区' },
          { value: 410190, pid: 410100, label: '航空港区' },
        ],
      },
      {
        value: 410200,
        pid: 410000,
        label: '开封市',
        children: [
          { value: 410202, pid: 410200, label: '龙亭区' },
          { value: 410203, pid: 410200, label: '顺河回族区' },
          { value: 410204, pid: 410200, label: '鼓楼区' },
          { value: 410205, pid: 410200, label: '禹王台区' },
          { value: 410212, pid: 410200, label: '祥符区' },
          { value: 410221, pid: 410200, label: '杞县' },
          { value: 410222, pid: 410200, label: '通许县' },
          { value: 410223, pid: 410200, label: '尉氏县' },
          { value: 410225, pid: 410200, label: '兰考县' },
          { value: 410226, pid: 410200, label: '经济技术开发区' },
        ],
      },
      {
        value: 410300,
        pid: 410000,
        label: '洛阳市',
        children: [
          { value: 410302, pid: 410300, label: '老城区' },
          { value: 410303, pid: 410300, label: '西工区' },
          { value: 410304, pid: 410300, label: '瀍河回族区' },
          { value: 410305, pid: 410300, label: '涧西区' },
          { value: 410306, pid: 410300, label: '吉利区' },
          { value: 410311, pid: 410300, label: '洛龙区' },
          { value: 410322, pid: 410300, label: '孟津县' },
          { value: 410323, pid: 410300, label: '新安县' },
          { value: 410324, pid: 410300, label: '栾川县' },
          { value: 410325, pid: 410300, label: '嵩县' },
          { value: 410326, pid: 410300, label: '汝阳县' },
          { value: 410327, pid: 410300, label: '宜阳县' },
          { value: 410328, pid: 410300, label: '洛宁县' },
          { value: 410329, pid: 410300, label: '伊川县' },
          { value: 410381, pid: 410300, label: '偃师市' },
          { value: 410382, pid: 410300, label: '洛阳新区' },
          { value: 410383, pid: 410300, label: '高新区' },
        ],
      },
      {
        value: 410400,
        pid: 410000,
        label: '平顶山市',
        children: [
          { value: 410402, pid: 410400, label: '新华区' },
          { value: 410403, pid: 410400, label: '卫东区' },
          { value: 410404, pid: 410400, label: '石龙区' },
          { value: 410411, pid: 410400, label: '湛河区' },
          { value: 410421, pid: 410400, label: '宝丰县' },
          { value: 410422, pid: 410400, label: '叶县' },
          { value: 410423, pid: 410400, label: '鲁山县' },
          { value: 410425, pid: 410400, label: '郏县' },
          { value: 410481, pid: 410400, label: '舞钢市' },
          { value: 410482, pid: 410400, label: '汝州市' },
          { value: 410483, pid: 410400, label: '高新区' },
          { value: 410484, pid: 410400, label: '新城区' },
        ],
      },
      {
        value: 410500,
        pid: 410000,
        label: '安阳市',
        children: [
          { value: 410502, pid: 410500, label: '文峰区' },
          { value: 410503, pid: 410500, label: '北关区' },
          { value: 410505, pid: 410500, label: '殷都区' },
          { value: 410506, pid: 410500, label: '龙安区' },
          { value: 410522, pid: 410500, label: '安阳县' },
          { value: 410523, pid: 410500, label: '汤阴县' },
          { value: 410526, pid: 410500, label: '滑县' },
          { value: 410527, pid: 410500, label: '内黄县' },
          { value: 410581, pid: 410500, label: '林州市' },
          { value: 410582, pid: 410500, label: '安阳新区' },
        ],
      },
      {
        value: 410600,
        pid: 410000,
        label: '鹤壁市',
        children: [
          { value: 410602, pid: 410600, label: '鹤山区' },
          { value: 410603, pid: 410600, label: '山城区' },
          { value: 410611, pid: 410600, label: '淇滨区' },
          { value: 410621, pid: 410600, label: '浚县' },
          { value: 410622, pid: 410600, label: '淇县' },
        ],
      },
      {
        value: 410700,
        pid: 410000,
        label: '新乡市',
        children: [
          { value: 410702, pid: 410700, label: '红旗区' },
          { value: 410703, pid: 410700, label: '卫滨区' },
          { value: 410704, pid: 410700, label: '凤泉区' },
          { value: 410711, pid: 410700, label: '牧野区' },
          { value: 410721, pid: 410700, label: '新乡县' },
          { value: 410724, pid: 410700, label: '获嘉县' },
          { value: 410725, pid: 410700, label: '原阳县' },
          { value: 410726, pid: 410700, label: '延津县' },
          { value: 410727, pid: 410700, label: '封丘县' },
          { value: 410728, pid: 410700, label: '长垣县' },
          { value: 410781, pid: 410700, label: '卫辉市' },
          { value: 410782, pid: 410700, label: '辉县市' },
        ],
      },
      {
        value: 410800,
        pid: 410000,
        label: '焦作市',
        children: [
          { value: 410802, pid: 410800, label: '解放区' },
          { value: 410803, pid: 410800, label: '中站区' },
          { value: 410804, pid: 410800, label: '马村区' },
          { value: 410811, pid: 410800, label: '山阳区' },
          { value: 410821, pid: 410800, label: '修武县' },
          { value: 410822, pid: 410800, label: '博爱县' },
          { value: 410823, pid: 410800, label: '武陟县' },
          { value: 410825, pid: 410800, label: '温县' },
          { value: 410882, pid: 410800, label: '沁阳市' },
          { value: 410883, pid: 410800, label: '孟州市' },
        ],
      },
      {
        value: 410900,
        pid: 410000,
        label: '濮阳市',
        children: [
          { value: 410902, pid: 410900, label: '华龙区' },
          { value: 410922, pid: 410900, label: '清丰县' },
          { value: 410923, pid: 410900, label: '南乐县' },
          { value: 410926, pid: 410900, label: '范县' },
          { value: 410927, pid: 410900, label: '台前县' },
          { value: 410928, pid: 410900, label: '濮阳县' },
        ],
      },
      {
        value: 411000,
        pid: 410000,
        label: '许昌市',
        children: [
          { value: 411002, pid: 411000, label: '魏都区' },
          { value: 411003, pid: 411000, label: '建安区' },
          { value: 411024, pid: 411000, label: '鄢陵县' },
          { value: 411025, pid: 411000, label: '襄城县' },
          { value: 411081, pid: 411000, label: '禹州市' },
          { value: 411082, pid: 411000, label: '长葛市' },
        ],
      },
      {
        value: 411100,
        pid: 410000,
        label: '漯河市',
        children: [
          { value: 411102, pid: 411100, label: '源汇区' },
          { value: 411103, pid: 411100, label: '郾城区' },
          { value: 411104, pid: 411100, label: '召陵区' },
          { value: 411121, pid: 411100, label: '舞阳县' },
          { value: 411122, pid: 411100, label: '临颍县' },
        ],
      },
      {
        value: 411200,
        pid: 410000,
        label: '三门峡市',
        children: [
          { value: 411202, pid: 411200, label: '湖滨区' },
          { value: 411203, pid: 411200, label: '陕州区' },
          { value: 411221, pid: 411200, label: '渑池县' },
          { value: 411224, pid: 411200, label: '卢氏县' },
          { value: 411281, pid: 411200, label: '义马市' },
          { value: 411282, pid: 411200, label: '灵宝市' },
        ],
      },
      {
        value: 411300,
        pid: 410000,
        label: '南阳市',
        children: [
          { value: 411302, pid: 411300, label: '宛城区' },
          { value: 411303, pid: 411300, label: '卧龙区' },
          { value: 411321, pid: 411300, label: '南召县' },
          { value: 411322, pid: 411300, label: '方城县' },
          { value: 411323, pid: 411300, label: '西峡县' },
          { value: 411324, pid: 411300, label: '镇平县' },
          { value: 411325, pid: 411300, label: '内乡县' },
          { value: 411326, pid: 411300, label: '淅川县' },
          { value: 411327, pid: 411300, label: '社旗县' },
          { value: 411328, pid: 411300, label: '唐河县' },
          { value: 411329, pid: 411300, label: '新野县' },
          { value: 411330, pid: 411300, label: '桐柏县' },
          { value: 411381, pid: 411300, label: '邓州市' },
        ],
      },
      {
        value: 411400,
        pid: 410000,
        label: '商丘市',
        children: [
          { value: 411402, pid: 411400, label: '梁园区' },
          { value: 411403, pid: 411400, label: '睢阳区' },
          { value: 411421, pid: 411400, label: '民权县' },
          { value: 411422, pid: 411400, label: '睢县' },
          { value: 411423, pid: 411400, label: '宁陵县' },
          { value: 411424, pid: 411400, label: '柘城县' },
          { value: 411425, pid: 411400, label: '虞城县' },
          { value: 411426, pid: 411400, label: '夏邑县' },
          { value: 411481, pid: 411400, label: '永城市' },
        ],
      },
      {
        value: 411500,
        pid: 410000,
        label: '信阳市',
        children: [
          { value: 411502, pid: 411500, label: '浉河区' },
          { value: 411503, pid: 411500, label: '平桥区' },
          { value: 411521, pid: 411500, label: '罗山县' },
          { value: 411522, pid: 411500, label: '光山县' },
          { value: 411523, pid: 411500, label: '新县' },
          { value: 411524, pid: 411500, label: '商城县' },
          { value: 411525, pid: 411500, label: '固始县' },
          { value: 411526, pid: 411500, label: '潢川县' },
          { value: 411527, pid: 411500, label: '淮滨县' },
          { value: 411528, pid: 411500, label: '息县' },
        ],
      },
      {
        value: 411600,
        pid: 410000,
        label: '周口市',
        children: [
          { value: 411602, pid: 411600, label: '川汇区' },
          { value: 411621, pid: 411600, label: '扶沟县' },
          { value: 411622, pid: 411600, label: '西华县' },
          { value: 411623, pid: 411600, label: '商水县' },
          { value: 411624, pid: 411600, label: '沈丘县' },
          { value: 411625, pid: 411600, label: '郸城县' },
          { value: 411626, pid: 411600, label: '淮阳县' },
          { value: 411627, pid: 411600, label: '太康县' },
          { value: 411628, pid: 411600, label: '鹿邑县' },
          { value: 411681, pid: 411600, label: '项城市' },
          { value: 411682, pid: 411600, label: '东新区' },
          { value: 411683, pid: 411600, label: '经济开发区' },
        ],
      },
      {
        value: 411700,
        pid: 410000,
        label: '驻马店市',
        children: [
          { value: 411702, pid: 411700, label: '驿城区' },
          { value: 411721, pid: 411700, label: '西平县' },
          { value: 411722, pid: 411700, label: '上蔡县' },
          { value: 411723, pid: 411700, label: '平舆县' },
          { value: 411724, pid: 411700, label: '正阳县' },
          { value: 411725, pid: 411700, label: '确山县' },
          { value: 411726, pid: 411700, label: '泌阳县' },
          { value: 411727, pid: 411700, label: '汝南县' },
          { value: 411728, pid: 411700, label: '遂平县' },
          { value: 411729, pid: 411700, label: '新蔡县' },
          { value: 411771, pid: 411700, label: '经济开发区' },
        ],
      },
      {
        value: 419001,
        pid: 410000,
        label: '济源市',
        children: [
          { value: 419011, pid: 419001, label: '沁园街道' },
          { value: 419012, pid: 419001, label: '济水街道' },
          { value: 419013, pid: 419001, label: '北海街道' },
          { value: 419014, pid: 419001, label: '天坛街道' },
          { value: 419015, pid: 419001, label: '玉泉街道' },
          { value: 419016, pid: 419001, label: '克井镇' },
          { value: 419017, pid: 419001, label: '五龙口镇' },
          { value: 419018, pid: 419001, label: '梨林镇' },
          { value: 419019, pid: 419001, label: '轵城镇' },
          { value: 419020, pid: 419001, label: '承留镇' },
          { value: 419021, pid: 419001, label: '坡头镇' },
          { value: 419022, pid: 419001, label: '大峪镇' },
          { value: 419023, pid: 419001, label: '邵原镇' },
          { value: 419024, pid: 419001, label: '思礼镇' },
          { value: 419025, pid: 419001, label: '王屋镇' },
          { value: 419026, pid: 419001, label: '下冶镇' },
        ],
      },
    ],
  },
  {
    value: 420000,
    pid: 0,
    label: '湖北省',
    children: [
      {
        value: 420100,
        pid: 420000,
        label: '武汉市',
        children: [
          { value: 420102, pid: 420100, label: '江岸区' },
          { value: 420103, pid: 420100, label: '江汉区' },
          { value: 420104, pid: 420100, label: '硚口区' },
          { value: 420105, pid: 420100, label: '汉阳区' },
          { value: 420106, pid: 420100, label: '武昌区' },
          { value: 420107, pid: 420100, label: '青山区' },
          { value: 420111, pid: 420100, label: '洪山区' },
          { value: 420112, pid: 420100, label: '东西湖区' },
          { value: 420113, pid: 420100, label: '汉南区' },
          { value: 420114, pid: 420100, label: '蔡甸区' },
          { value: 420115, pid: 420100, label: '江夏区' },
          { value: 420116, pid: 420100, label: '黄陂区' },
          { value: 420117, pid: 420100, label: '新洲区' },
          { value: 420118, pid: 420100, label: '经济技术开发区' },
        ],
      },
      {
        value: 420200,
        pid: 420000,
        label: '黄石市',
        children: [
          { value: 420202, pid: 420200, label: '黄石港区' },
          { value: 420203, pid: 420200, label: '西塞山区' },
          { value: 420204, pid: 420200, label: '下陆区' },
          { value: 420205, pid: 420200, label: '铁山区' },
          { value: 420222, pid: 420200, label: '阳新县' },
          { value: 420281, pid: 420200, label: '大冶市' },
          { value: 420282, pid: 420200, label: '经济开发区' },
        ],
      },
      {
        value: 420300,
        pid: 420000,
        label: '十堰市',
        children: [
          { value: 420302, pid: 420300, label: '茅箭区' },
          { value: 420303, pid: 420300, label: '张湾区' },
          { value: 420304, pid: 420300, label: '郧阳区' },
          { value: 420322, pid: 420300, label: '郧西县' },
          { value: 420323, pid: 420300, label: '竹山县' },
          { value: 420324, pid: 420300, label: '竹溪县' },
          { value: 420325, pid: 420300, label: '房县' },
          { value: 420381, pid: 420300, label: '丹江口市' },
        ],
      },
      {
        value: 420500,
        pid: 420000,
        label: '宜昌市',
        children: [
          { value: 420502, pid: 420500, label: '西陵区' },
          { value: 420503, pid: 420500, label: '伍家岗区' },
          { value: 420504, pid: 420500, label: '点军区' },
          { value: 420505, pid: 420500, label: '猇亭区' },
          { value: 420506, pid: 420500, label: '夷陵区' },
          { value: 420525, pid: 420500, label: '远安县' },
          { value: 420526, pid: 420500, label: '兴山县' },
          { value: 420527, pid: 420500, label: '秭归县' },
          { value: 420528, pid: 420500, label: '长阳土家族自治县' },
          { value: 420529, pid: 420500, label: '五峰土家族自治县' },
          { value: 420581, pid: 420500, label: '宜都市' },
          { value: 420582, pid: 420500, label: '当阳市' },
          { value: 420583, pid: 420500, label: '枝江市' },
          { value: 420584, pid: 420500, label: '宜昌新区' },
        ],
      },
      {
        value: 420600,
        pid: 420000,
        label: '襄阳市',
        children: [
          { value: 420602, pid: 420600, label: '襄城区' },
          { value: 420606, pid: 420600, label: '樊城区' },
          { value: 420607, pid: 420600, label: '襄州区' },
          { value: 420624, pid: 420600, label: '南漳县' },
          { value: 420625, pid: 420600, label: '谷城县' },
          { value: 420626, pid: 420600, label: '保康县' },
          { value: 420682, pid: 420600, label: '老河口市' },
          { value: 420683, pid: 420600, label: '枣阳市' },
          { value: 420684, pid: 420600, label: '宜城市' },
          { value: 420685, pid: 420600, label: '高新区' },
          { value: 420686, pid: 420600, label: '经济开发区' },
        ],
      },
      {
        value: 420700,
        pid: 420000,
        label: '鄂州市',
        children: [
          { value: 420702, pid: 420700, label: '梁子湖区' },
          { value: 420703, pid: 420700, label: '华容区' },
          { value: 420704, pid: 420700, label: '鄂城区' },
        ],
      },
      {
        value: 420800,
        pid: 420000,
        label: '荆门市',
        children: [
          { value: 420802, pid: 420800, label: '东宝区' },
          { value: 420804, pid: 420800, label: '掇刀区' },
          { value: 420822, pid: 420800, label: '沙洋县' },
          { value: 420881, pid: 420800, label: '钟祥市' },
          { value: 420882, pid: 420800, label: '京山市' },
        ],
      },
      {
        value: 420900,
        pid: 420000,
        label: '孝感市',
        children: [
          { value: 420902, pid: 420900, label: '孝南区' },
          { value: 420921, pid: 420900, label: '孝昌县' },
          { value: 420922, pid: 420900, label: '大悟县' },
          { value: 420923, pid: 420900, label: '云梦县' },
          { value: 420981, pid: 420900, label: '应城市' },
          { value: 420982, pid: 420900, label: '安陆市' },
          { value: 420984, pid: 420900, label: '汉川市' },
        ],
      },
      {
        value: 421000,
        pid: 420000,
        label: '荆州市',
        children: [
          { value: 421002, pid: 421000, label: '沙市区' },
          { value: 421003, pid: 421000, label: '荆州区' },
          { value: 421022, pid: 421000, label: '公安县' },
          { value: 421023, pid: 421000, label: '监利县' },
          { value: 421024, pid: 421000, label: '江陵县' },
          { value: 421081, pid: 421000, label: '石首市' },
          { value: 421083, pid: 421000, label: '洪湖市' },
          { value: 421087, pid: 421000, label: '松滋市' },
        ],
      },
      {
        value: 421100,
        pid: 420000,
        label: '黄冈市',
        children: [
          { value: 421102, pid: 421100, label: '黄州区' },
          { value: 421121, pid: 421100, label: '团风县' },
          { value: 421122, pid: 421100, label: '红安县' },
          { value: 421123, pid: 421100, label: '罗田县' },
          { value: 421124, pid: 421100, label: '英山县' },
          { value: 421125, pid: 421100, label: '浠水县' },
          { value: 421126, pid: 421100, label: '蕲春县' },
          { value: 421127, pid: 421100, label: '黄梅县' },
          { value: 421181, pid: 421100, label: '麻城市' },
          { value: 421182, pid: 421100, label: '武穴市' },
          { value: 421183, pid: 421100, label: '城东新区' },
        ],
      },
      {
        value: 421200,
        pid: 420000,
        label: '咸宁市',
        children: [
          { value: 421202, pid: 421200, label: '咸安区' },
          { value: 421221, pid: 421200, label: '嘉鱼县' },
          { value: 421222, pid: 421200, label: '通城县' },
          { value: 421223, pid: 421200, label: '崇阳县' },
          { value: 421224, pid: 421200, label: '通山县' },
          { value: 421281, pid: 421200, label: '赤壁市' },
        ],
      },
      {
        value: 421300,
        pid: 420000,
        label: '随州市',
        children: [
          { value: 421303, pid: 421300, label: '曾都区' },
          { value: 421321, pid: 421300, label: '随县' },
          { value: 421381, pid: 421300, label: '广水市' },
        ],
      },
      {
        value: 422800,
        pid: 420000,
        label: '恩施土家族苗族自治州',
        children: [
          { value: 422801, pid: 422800, label: '恩施市' },
          { value: 422802, pid: 422800, label: '利川市' },
          { value: 422822, pid: 422800, label: '建始县' },
          { value: 422823, pid: 422800, label: '巴东县' },
          { value: 422825, pid: 422800, label: '宣恩县' },
          { value: 422826, pid: 422800, label: '咸丰县' },
          { value: 422827, pid: 422800, label: '来凤县' },
          { value: 422828, pid: 422800, label: '鹤峰县' },
        ],
      },
      {
        value: 429004,
        pid: 420000,
        label: '仙桃市',
        children: [
          { value: 429401, pid: 429004, label: '沙嘴街道' },
          { value: 429402, pid: 429004, label: '干河街道' },
          { value: 429403, pid: 429004, label: '龙华山街道' },
          { value: 429404, pid: 429004, label: '郑场镇' },
          { value: 429405, pid: 429004, label: '毛嘴镇' },
          { value: 429406, pid: 429004, label: '豆河镇' },
          { value: 429407, pid: 429004, label: '三伏潭镇' },
          { value: 429408, pid: 429004, label: '胡场镇' },
          { value: 429409, pid: 429004, label: '长埫口镇' },
          { value: 429410, pid: 429004, label: '西流河镇' },
          { value: 429411, pid: 429004, label: '沙湖镇' },
          { value: 429412, pid: 429004, label: '杨林尾镇' },
          { value: 429413, pid: 429004, label: '彭场镇' },
          { value: 429414, pid: 429004, label: '张沟镇' },
          { value: 429415, pid: 429004, label: '郭河镇' },
          { value: 429416, pid: 429004, label: '沔城镇' },
          { value: 429417, pid: 429004, label: '通海口镇' },
          { value: 429418, pid: 429004, label: '陈场镇' },
          { value: 429419, pid: 429004, label: '高新区' },
          { value: 429420, pid: 429004, label: '经济开发区' },
          { value: 429421, pid: 429004, label: '工业园区' },
          { value: 429422, pid: 429004, label: '九合垸原种场' },
          { value: 429423, pid: 429004, label: '沙湖原种场' },
          { value: 429424, pid: 429004, label: '排湖渔场' },
          { value: 429425, pid: 429004, label: '五湖渔场' },
          { value: 429426, pid: 429004, label: '赵西垸林场' },
          { value: 429427, pid: 429004, label: '刘家垸林场' },
          { value: 429428, pid: 429004, label: '畜禽良种场' },
        ],
      },
      {
        value: 429005,
        pid: 420000,
        label: '潜江市',
        children: [
          { value: 429501, pid: 429005, label: '园林' },
          { value: 429502, pid: 429005, label: '广华' },
          { value: 429503, pid: 429005, label: '杨市' },
          { value: 429504, pid: 429005, label: '周矶' },
          { value: 429505, pid: 429005, label: '泽口' },
          { value: 429506, pid: 429005, label: '泰丰' },
          { value: 429507, pid: 429005, label: '高场' },
          { value: 429508, pid: 429005, label: '熊口镇' },
          { value: 429509, pid: 429005, label: '竹根滩镇' },
          { value: 429510, pid: 429005, label: '高石碑镇' },
          { value: 429511, pid: 429005, label: '老新镇' },
          { value: 429512, pid: 429005, label: '王场镇' },
          { value: 429513, pid: 429005, label: '渔洋镇' },
          { value: 429514, pid: 429005, label: '龙湾镇' },
          { value: 429515, pid: 429005, label: '浩口镇' },
          { value: 429516, pid: 429005, label: '积玉口镇' },
          { value: 429517, pid: 429005, label: '张金镇' },
          { value: 429518, pid: 429005, label: '白鹭湖管理区' },
          { value: 429519, pid: 429005, label: '总口管理区' },
          { value: 429520, pid: 429005, label: '熊口农场管理区' },
          { value: 429521, pid: 429005, label: '运粮湖管理区' },
          { value: 429522, pid: 429005, label: '后湖管理区' },
          { value: 429523, pid: 429005, label: '周矶管理区' },
          { value: 429524, pid: 429005, label: '经济开发区' },
        ],
      },
      {
        value: 429006,
        pid: 420000,
        label: '天门市',
        children: [
          { value: 429601, pid: 429006, label: '竟陵街道' },
          { value: 429602, pid: 429006, label: '杨林街道' },
          { value: 429603, pid: 429006, label: '佛子山镇' },
          { value: 429604, pid: 429006, label: '多宝镇' },
          { value: 429605, pid: 429006, label: '拖市镇' },
          { value: 429606, pid: 429006, label: '张港镇' },
          { value: 429607, pid: 429006, label: '蒋场镇' },
          { value: 429608, pid: 429006, label: '汪场镇' },
          { value: 429609, pid: 429006, label: '渔薪镇' },
          { value: 429610, pid: 429006, label: '黄潭镇' },
          { value: 429611, pid: 429006, label: '岳口镇' },
          { value: 429612, pid: 429006, label: '横林镇' },
          { value: 429613, pid: 429006, label: '彭市镇' },
          { value: 429614, pid: 429006, label: '麻洋镇' },
          { value: 429615, pid: 429006, label: '多祥镇' },
          { value: 429616, pid: 429006, label: '干驿镇' },
          { value: 429617, pid: 429006, label: '马湾镇' },
          { value: 429618, pid: 429006, label: '卢市镇' },
          { value: 429619, pid: 429006, label: '小板镇' },
          { value: 429620, pid: 429006, label: '九真镇' },
          { value: 429621, pid: 429006, label: '皂市镇' },
          { value: 429622, pid: 429006, label: '胡市镇' },
          { value: 429623, pid: 429006, label: '石河镇' },
          { value: 429624, pid: 429006, label: '净潭乡' },
          { value: 429625, pid: 429006, label: '蒋湖农场' },
          { value: 429626, pid: 429006, label: '白茅湖农场' },
          { value: 429627, pid: 429006, label: '沉湖林业科技示范区' },
          { value: 429628, pid: 429006, label: '天门工业园' },
          { value: 429629, pid: 429006, label: '侨乡街道开发区' },
        ],
      },
      {
        value: 429021,
        pid: 420000,
        label: '神农架林区',
        children: [
          { value: 429022, pid: 429021, label: '松柏镇' },
          { value: 429023, pid: 429021, label: '阳日镇' },
          { value: 429024, pid: 429021, label: '木鱼镇' },
          { value: 429025, pid: 429021, label: '红坪镇' },
          { value: 429026, pid: 429021, label: '新华镇' },
          { value: 429027, pid: 429021, label: '大九湖' },
          { value: 429028, pid: 429021, label: '宋洛' },
          { value: 429029, pid: 429021, label: '下谷坪乡' },
        ],
      },
    ],
  },
  {
    value: 430000,
    pid: 0,
    label: '湖南省',
    children: [
      {
        value: 430100,
        pid: 430000,
        label: '长沙市',
        children: [
          { value: 430102, pid: 430100, label: '芙蓉区' },
          { value: 430103, pid: 430100, label: '天心区' },
          { value: 430104, pid: 430100, label: '岳麓区' },
          { value: 430105, pid: 430100, label: '开福区' },
          { value: 430111, pid: 430100, label: '雨花区' },
          { value: 430112, pid: 430100, label: '望城区' },
          { value: 430121, pid: 430100, label: '长沙县' },
          { value: 430181, pid: 430100, label: '浏阳市' },
          { value: 430182, pid: 430100, label: '宁乡市' },
          { value: 430183, pid: 430100, label: '湘江新区' },
        ],
      },
      {
        value: 430200,
        pid: 430000,
        label: '株洲市',
        children: [
          { value: 430202, pid: 430200, label: '荷塘区' },
          { value: 430203, pid: 430200, label: '芦淞区' },
          { value: 430204, pid: 430200, label: '石峰区' },
          { value: 430211, pid: 430200, label: '天元区' },
          { value: 430212, pid: 430200, label: '渌口区' },
          { value: 430223, pid: 430200, label: '攸县' },
          { value: 430224, pid: 430200, label: '茶陵县' },
          { value: 430225, pid: 430200, label: '炎陵县' },
          { value: 430281, pid: 430200, label: '醴陵市' },
        ],
      },
      {
        value: 430300,
        pid: 430000,
        label: '湘潭市',
        children: [
          { value: 430302, pid: 430300, label: '雨湖区' },
          { value: 430304, pid: 430300, label: '岳塘区' },
          { value: 430321, pid: 430300, label: '湘潭县' },
          { value: 430381, pid: 430300, label: '湘乡市' },
          { value: 430382, pid: 430300, label: '韶山市' },
          { value: 430383, pid: 430300, label: '高新区' },
        ],
      },
      {
        value: 430400,
        pid: 430000,
        label: '衡阳市',
        children: [
          { value: 430405, pid: 430400, label: '珠晖区' },
          { value: 430406, pid: 430400, label: '雁峰区' },
          { value: 430407, pid: 430400, label: '石鼓区' },
          { value: 430408, pid: 430400, label: '蒸湘区' },
          { value: 430412, pid: 430400, label: '南岳区' },
          { value: 430421, pid: 430400, label: '衡阳县' },
          { value: 430422, pid: 430400, label: '衡南县' },
          { value: 430423, pid: 430400, label: '衡山县' },
          { value: 430424, pid: 430400, label: '衡东县' },
          { value: 430426, pid: 430400, label: '祁东县' },
          { value: 430481, pid: 430400, label: '耒阳市' },
          { value: 430482, pid: 430400, label: '常宁市' },
          { value: 430483, pid: 430400, label: '高新区' },
          { value: 430484, pid: 430400, label: '综合保税区' },
        ],
      },
      {
        value: 430500,
        pid: 430000,
        label: '邵阳市',
        children: [
          { value: 430502, pid: 430500, label: '双清区' },
          { value: 430503, pid: 430500, label: '大祥区' },
          { value: 430511, pid: 430500, label: '北塔区' },
          { value: 430521, pid: 430500, label: '邵东县' },
          { value: 430522, pid: 430500, label: '新邵县' },
          { value: 430523, pid: 430500, label: '邵阳县' },
          { value: 430524, pid: 430500, label: '隆回县' },
          { value: 430525, pid: 430500, label: '洞口县' },
          { value: 430527, pid: 430500, label: '绥宁县' },
          { value: 430528, pid: 430500, label: '新宁县' },
          { value: 430529, pid: 430500, label: '城步苗族自治县' },
          { value: 430581, pid: 430500, label: '武冈市' },
        ],
      },
      {
        value: 430600,
        pid: 430000,
        label: '岳阳市',
        children: [
          { value: 430602, pid: 430600, label: '岳阳楼区' },
          { value: 430603, pid: 430600, label: '云溪区' },
          { value: 430611, pid: 430600, label: '君山区' },
          { value: 430621, pid: 430600, label: '岳阳县' },
          { value: 430623, pid: 430600, label: '华容县' },
          { value: 430624, pid: 430600, label: '湘阴县' },
          { value: 430626, pid: 430600, label: '平江县' },
          { value: 430681, pid: 430600, label: '汨罗市' },
          { value: 430682, pid: 430600, label: '临湘市' },
        ],
      },
      {
        value: 430700,
        pid: 430000,
        label: '常德市',
        children: [
          { value: 430702, pid: 430700, label: '武陵区' },
          { value: 430703, pid: 430700, label: '鼎城区' },
          { value: 430721, pid: 430700, label: '安乡县' },
          { value: 430722, pid: 430700, label: '汉寿县' },
          { value: 430723, pid: 430700, label: '澧县' },
          { value: 430724, pid: 430700, label: '临澧县' },
          { value: 430725, pid: 430700, label: '桃源县' },
          { value: 430726, pid: 430700, label: '石门县' },
          { value: 430781, pid: 430700, label: '津市市' },
        ],
      },
      {
        value: 430800,
        pid: 430000,
        label: '张家界市',
        children: [
          { value: 430802, pid: 430800, label: '永定区' },
          { value: 430811, pid: 430800, label: '武陵源区' },
          { value: 430821, pid: 430800, label: '慈利县' },
          { value: 430822, pid: 430800, label: '桑植县' },
        ],
      },
      {
        value: 430900,
        pid: 430000,
        label: '益阳市',
        children: [
          { value: 430902, pid: 430900, label: '资阳区' },
          { value: 430903, pid: 430900, label: '赫山区' },
          { value: 430921, pid: 430900, label: '南县' },
          { value: 430922, pid: 430900, label: '桃江县' },
          { value: 430923, pid: 430900, label: '安化县' },
          { value: 430981, pid: 430900, label: '沅江市' },
        ],
      },
      {
        value: 431000,
        pid: 430000,
        label: '郴州市',
        children: [
          { value: 431002, pid: 431000, label: '北湖区' },
          { value: 431003, pid: 431000, label: '苏仙区' },
          { value: 431021, pid: 431000, label: '桂阳县' },
          { value: 431022, pid: 431000, label: '宜章县' },
          { value: 431023, pid: 431000, label: '永兴县' },
          { value: 431024, pid: 431000, label: '嘉禾县' },
          { value: 431025, pid: 431000, label: '临武县' },
          { value: 431026, pid: 431000, label: '汝城县' },
          { value: 431027, pid: 431000, label: '桂东县' },
          { value: 431028, pid: 431000, label: '安仁县' },
          { value: 431081, pid: 431000, label: '资兴市' },
        ],
      },
      {
        value: 431100,
        pid: 430000,
        label: '永州市',
        children: [
          { value: 431102, pid: 431100, label: '零陵区' },
          { value: 431103, pid: 431100, label: '冷水滩区' },
          { value: 431121, pid: 431100, label: '祁阳县' },
          { value: 431122, pid: 431100, label: '东安县' },
          { value: 431123, pid: 431100, label: '双牌县' },
          { value: 431124, pid: 431100, label: '道县' },
          { value: 431125, pid: 431100, label: '江永县' },
          { value: 431126, pid: 431100, label: '宁远县' },
          { value: 431127, pid: 431100, label: '蓝山县' },
          { value: 431128, pid: 431100, label: '新田县' },
          { value: 431129, pid: 431100, label: '江华瑶族自治县' },
        ],
      },
      {
        value: 431200,
        pid: 430000,
        label: '怀化市',
        children: [
          { value: 431202, pid: 431200, label: '鹤城区' },
          { value: 431221, pid: 431200, label: '中方县' },
          { value: 431222, pid: 431200, label: '沅陵县' },
          { value: 431223, pid: 431200, label: '辰溪县' },
          { value: 431224, pid: 431200, label: '溆浦县' },
          { value: 431225, pid: 431200, label: '会同县' },
          { value: 431226, pid: 431200, label: '麻阳苗族自治县' },
          { value: 431227, pid: 431200, label: '新晃侗族自治县' },
          { value: 431228, pid: 431200, label: '芷江侗族自治县' },
          { value: 431229, pid: 431200, label: '靖州苗族侗族自治县' },
          { value: 431230, pid: 431200, label: '通道侗族自治县' },
          { value: 431281, pid: 431200, label: '洪江市' },
        ],
      },
      {
        value: 431300,
        pid: 430000,
        label: '娄底市',
        children: [
          { value: 431302, pid: 431300, label: '娄星区' },
          { value: 431321, pid: 431300, label: '双峰县' },
          { value: 431322, pid: 431300, label: '新化县' },
          { value: 431381, pid: 431300, label: '冷水江市' },
          { value: 431382, pid: 431300, label: '涟源市' },
        ],
      },
      {
        value: 433100,
        pid: 430000,
        label: '湘西土家族苗族自治州',
        children: [
          { value: 433101, pid: 433100, label: '吉首市' },
          { value: 433122, pid: 433100, label: '泸溪县' },
          { value: 433123, pid: 433100, label: '凤凰县' },
          { value: 433124, pid: 433100, label: '花垣县' },
          { value: 433125, pid: 433100, label: '保靖县' },
          { value: 433126, pid: 433100, label: '古丈县' },
          { value: 433127, pid: 433100, label: '永顺县' },
          { value: 433130, pid: 433100, label: '龙山县' },
        ],
      },
    ],
  },
  {
    value: 440000,
    pid: 0,
    label: '广东省',
    children: [
      {
        value: 440100,
        pid: 440000,
        label: '广州市',
        children: [
          { value: 440103, pid: 440100, label: '荔湾区' },
          { value: 440104, pid: 440100, label: '越秀区' },
          { value: 440105, pid: 440100, label: '海珠区' },
          { value: 440106, pid: 440100, label: '天河区' },
          { value: 440111, pid: 440100, label: '白云区' },
          { value: 440112, pid: 440100, label: '黄埔区' },
          { value: 440113, pid: 440100, label: '番禺区' },
          { value: 440114, pid: 440100, label: '花都区' },
          { value: 440115, pid: 440100, label: '南沙区' },
          { value: 440117, pid: 440100, label: '从化区' },
          { value: 440118, pid: 440100, label: '增城区' },
        ],
      },
      {
        value: 440200,
        pid: 440000,
        label: '韶关市',
        children: [
          { value: 440203, pid: 440200, label: '武江区' },
          { value: 440204, pid: 440200, label: '浈江区' },
          { value: 440205, pid: 440200, label: '曲江区' },
          { value: 440222, pid: 440200, label: '始兴县' },
          { value: 440224, pid: 440200, label: '仁化县' },
          { value: 440229, pid: 440200, label: '翁源县' },
          { value: 440232, pid: 440200, label: '乳源瑶族自治县' },
          { value: 440233, pid: 440200, label: '新丰县' },
          { value: 440281, pid: 440200, label: '乐昌市' },
          { value: 440282, pid: 440200, label: '南雄市' },
        ],
      },
      {
        value: 440300,
        pid: 440000,
        label: '深圳市',
        children: [
          { value: 440303, pid: 440300, label: '罗湖区' },
          { value: 440304, pid: 440300, label: '福田区' },
          { value: 440305, pid: 440300, label: '南山区' },
          { value: 440306, pid: 440300, label: '宝安区' },
          { value: 440307, pid: 440300, label: '龙岗区' },
          { value: 440308, pid: 440300, label: '盐田区' },
          { value: 440309, pid: 440300, label: '龙华区' },
          { value: 440310, pid: 440300, label: '坪山区' },
          { value: 440311, pid: 440300, label: '光明区' },
          { value: 440312, pid: 440300, label: '大鹏新区' },
        ],
      },
      {
        value: 440400,
        pid: 440000,
        label: '珠海市',
        children: [
          { value: 440402, pid: 440400, label: '香洲区' },
          { value: 440403, pid: 440400, label: '斗门区' },
          { value: 440404, pid: 440400, label: '金湾区' },
          { value: 440405, pid: 440400, label: '横琴新区' },
          { value: 440406, pid: 440400, label: '经济开发区' },
        ],
      },
      {
        value: 440500,
        pid: 440000,
        label: '汕头市',
        children: [
          { value: 440507, pid: 440500, label: '龙湖区' },
          { value: 440511, pid: 440500, label: '金平区' },
          { value: 440512, pid: 440500, label: '濠江区' },
          { value: 440513, pid: 440500, label: '潮阳区' },
          { value: 440514, pid: 440500, label: '潮南区' },
          { value: 440515, pid: 440500, label: '澄海区' },
          { value: 440523, pid: 440500, label: '南澳县' },
        ],
      },
      {
        value: 440600,
        pid: 440000,
        label: '佛山市',
        children: [
          { value: 440604, pid: 440600, label: '禅城区' },
          { value: 440605, pid: 440600, label: '南海区' },
          { value: 440606, pid: 440600, label: '顺德区' },
          { value: 440607, pid: 440600, label: '三水区' },
          { value: 440608, pid: 440600, label: '高明区' },
        ],
      },
      {
        value: 440700,
        pid: 440000,
        label: '江门市',
        children: [
          { value: 440703, pid: 440700, label: '蓬江区' },
          { value: 440704, pid: 440700, label: '江海区' },
          { value: 440705, pid: 440700, label: '新会区' },
          { value: 440781, pid: 440700, label: '台山市' },
          { value: 440783, pid: 440700, label: '开平市' },
          { value: 440784, pid: 440700, label: '鹤山市' },
          { value: 440785, pid: 440700, label: '恩平市' },
        ],
      },
      {
        value: 440800,
        pid: 440000,
        label: '湛江市',
        children: [
          { value: 440802, pid: 440800, label: '赤坎区' },
          { value: 440803, pid: 440800, label: '霞山区' },
          { value: 440804, pid: 440800, label: '坡头区' },
          { value: 440811, pid: 440800, label: '麻章区' },
          { value: 440823, pid: 440800, label: '遂溪县' },
          { value: 440825, pid: 440800, label: '徐闻县' },
          { value: 440881, pid: 440800, label: '廉江市' },
          { value: 440882, pid: 440800, label: '雷州市' },
          { value: 440883, pid: 440800, label: '吴川市' },
          { value: 440884, pid: 440800, label: '经济开发区' },
        ],
      },
      {
        value: 440900,
        pid: 440000,
        label: '茂名市',
        children: [
          { value: 440902, pid: 440900, label: '茂南区' },
          { value: 440904, pid: 440900, label: '电白区' },
          { value: 440981, pid: 440900, label: '高州市' },
          { value: 440982, pid: 440900, label: '化州市' },
          { value: 440983, pid: 440900, label: '信宜市' },
        ],
      },
      {
        value: 441200,
        pid: 440000,
        label: '肇庆市',
        children: [
          { value: 441202, pid: 441200, label: '端州区' },
          { value: 441203, pid: 441200, label: '鼎湖区' },
          { value: 441204, pid: 441200, label: '高要区' },
          { value: 441223, pid: 441200, label: '广宁县' },
          { value: 441224, pid: 441200, label: '怀集县' },
          { value: 441225, pid: 441200, label: '封开县' },
          { value: 441226, pid: 441200, label: '德庆县' },
          { value: 441284, pid: 441200, label: '四会市' },
        ],
      },
      {
        value: 441300,
        pid: 440000,
        label: '惠州市',
        children: [
          { value: 441302, pid: 441300, label: '惠城区' },
          { value: 441303, pid: 441300, label: '惠阳区' },
          { value: 441322, pid: 441300, label: '博罗县' },
          { value: 441323, pid: 441300, label: '惠东县' },
          { value: 441324, pid: 441300, label: '龙门县' },
          { value: 441325, pid: 441300, label: '大亚湾区' },
        ],
      },
      {
        value: 441400,
        pid: 440000,
        label: '梅州市',
        children: [
          { value: 441402, pid: 441400, label: '梅江区' },
          { value: 441403, pid: 441400, label: '梅县区' },
          { value: 441422, pid: 441400, label: '大埔县' },
          { value: 441423, pid: 441400, label: '丰顺县' },
          { value: 441424, pid: 441400, label: '五华县' },
          { value: 441426, pid: 441400, label: '平远县' },
          { value: 441427, pid: 441400, label: '蕉岭县' },
          { value: 441481, pid: 441400, label: '兴宁市' },
        ],
      },
      {
        value: 441500,
        pid: 440000,
        label: '汕尾市',
        children: [
          { value: 441502, pid: 441500, label: '城区' },
          { value: 441521, pid: 441500, label: '海丰县' },
          { value: 441523, pid: 441500, label: '陆河县' },
          { value: 441581, pid: 441500, label: '陆丰市' },
        ],
      },
      {
        value: 441600,
        pid: 440000,
        label: '河源市',
        children: [
          { value: 441602, pid: 441600, label: '源城区' },
          { value: 441621, pid: 441600, label: '紫金县' },
          { value: 441622, pid: 441600, label: '龙川县' },
          { value: 441623, pid: 441600, label: '连平县' },
          { value: 441624, pid: 441600, label: '和平县' },
          { value: 441625, pid: 441600, label: '东源县' },
        ],
      },
      {
        value: 441700,
        pid: 440000,
        label: '阳江市',
        children: [
          { value: 441702, pid: 441700, label: '江城区' },
          { value: 441704, pid: 441700, label: '阳东区' },
          { value: 441721, pid: 441700, label: '阳西县' },
          { value: 441781, pid: 441700, label: '阳春市' },
        ],
      },
      {
        value: 441800,
        pid: 440000,
        label: '清远市',
        children: [
          { value: 441802, pid: 441800, label: '清城区' },
          { value: 441803, pid: 441800, label: '清新区' },
          { value: 441821, pid: 441800, label: '佛冈县' },
          { value: 441823, pid: 441800, label: '阳山县' },
          { value: 441825, pid: 441800, label: '连山壮族瑶族自治县' },
          { value: 441826, pid: 441800, label: '连南瑶族自治县' },
          { value: 441881, pid: 441800, label: '英德市' },
          { value: 441882, pid: 441800, label: '连州市' },
        ],
      },
      {
        value: 441900,
        pid: 440000,
        label: '东莞市',
        children: [
          { value: 441901, pid: 441900, label: '莞城区' },
          { value: 441902, pid: 441900, label: '南城区' },
          { value: 441903, pid: 441900, label: '东城区' },
          { value: 441904, pid: 441900, label: '万江区' },
          { value: 441905, pid: 441900, label: '石碣镇' },
          { value: 441906, pid: 441900, label: '石龙镇' },
          { value: 441907, pid: 441900, label: '茶山镇' },
          { value: 441908, pid: 441900, label: '石排镇' },
          { value: 441909, pid: 441900, label: '企石镇' },
          { value: 441910, pid: 441900, label: '横沥镇' },
          { value: 441911, pid: 441900, label: '桥头镇' },
          { value: 441912, pid: 441900, label: '谢岗镇' },
          { value: 441913, pid: 441900, label: '东坑镇' },
          { value: 441914, pid: 441900, label: '常平镇' },
          { value: 441915, pid: 441900, label: '寮步镇' },
          { value: 441916, pid: 441900, label: '大朗镇' },
          { value: 441917, pid: 441900, label: '麻涌镇' },
          { value: 441918, pid: 441900, label: '中堂镇' },
          { value: 441919, pid: 441900, label: '高埗镇' },
          { value: 441920, pid: 441900, label: '樟木头镇' },
          { value: 441921, pid: 441900, label: '大岭山镇' },
          { value: 441922, pid: 441900, label: '望牛墩镇' },
          { value: 441923, pid: 441900, label: '黄江镇' },
          { value: 441924, pid: 441900, label: '洪梅镇' },
          { value: 441925, pid: 441900, label: '清溪镇' },
          { value: 441926, pid: 441900, label: '沙田镇' },
          { value: 441927, pid: 441900, label: '道滘镇' },
          { value: 441928, pid: 441900, label: '塘厦镇' },
          { value: 441929, pid: 441900, label: '虎门镇' },
          { value: 441930, pid: 441900, label: '厚街镇' },
          { value: 441931, pid: 441900, label: '凤岗镇' },
          { value: 441932, pid: 441900, label: '长安镇' },
          { value: 441933, pid: 441900, label: '松山湖高新区' },
        ],
      },
      {
        value: 442000,
        pid: 440000,
        label: '中山市',
        children: [
          { value: 442001, pid: 442000, label: '石岐区' },
          { value: 442002, pid: 442000, label: '东区' },
          { value: 442003, pid: 442000, label: '西区' },
          { value: 442004, pid: 442000, label: '南区' },
          { value: 442005, pid: 442000, label: '五桂山区' },
          { value: 442006, pid: 442000, label: '火炬开发区' },
          { value: 442007, pid: 442000, label: '黄圃镇' },
          { value: 442008, pid: 442000, label: '南头镇' },
          { value: 442009, pid: 442000, label: '东凤镇' },
          { value: 442010, pid: 442000, label: '阜沙镇' },
          { value: 442011, pid: 442000, label: '小榄镇' },
          { value: 442012, pid: 442000, label: '东升镇' },
          { value: 442013, pid: 442000, label: '古镇镇' },
          { value: 442014, pid: 442000, label: '横栏镇' },
          { value: 442015, pid: 442000, label: '三角镇' },
          { value: 442016, pid: 442000, label: '民众镇' },
          { value: 442017, pid: 442000, label: '南朗镇' },
          { value: 442018, pid: 442000, label: '港口镇' },
          { value: 442019, pid: 442000, label: '大涌镇' },
          { value: 442020, pid: 442000, label: '沙溪镇' },
          { value: 442021, pid: 442000, label: '三乡镇' },
          { value: 442022, pid: 442000, label: '板芙镇' },
          { value: 442023, pid: 442000, label: '神湾镇' },
          { value: 442024, pid: 442000, label: '坦洲镇' },
        ],
      },
      {
        value: 445100,
        pid: 440000,
        label: '潮州市',
        children: [
          { value: 445102, pid: 445100, label: '湘桥区' },
          { value: 445103, pid: 445100, label: '潮安区' },
          { value: 445122, pid: 445100, label: '饶平县' },
        ],
      },
      {
        value: 445200,
        pid: 440000,
        label: '揭阳市',
        children: [
          { value: 445202, pid: 445200, label: '榕城区' },
          { value: 445203, pid: 445200, label: '揭东区' },
          { value: 445222, pid: 445200, label: '揭西县' },
          { value: 445224, pid: 445200, label: '惠来县' },
          { value: 445281, pid: 445200, label: '普宁市' },
        ],
      },
      {
        value: 445300,
        pid: 440000,
        label: '云浮市',
        children: [
          { value: 445302, pid: 445300, label: '云城区' },
          { value: 445303, pid: 445300, label: '云安区' },
          { value: 445321, pid: 445300, label: '新兴县' },
          { value: 445322, pid: 445300, label: '郁南县' },
          { value: 445381, pid: 445300, label: '罗定市' },
        ],
      },
    ],
  },
  {
    value: 450000,
    pid: 0,
    label: '广西壮族自治区',
    children: [
      {
        value: 450100,
        pid: 450000,
        label: '南宁市',
        children: [
          { value: 450102, pid: 450100, label: '兴宁区' },
          { value: 450103, pid: 450100, label: '青秀区' },
          { value: 450105, pid: 450100, label: '江南区' },
          { value: 450107, pid: 450100, label: '西乡塘区' },
          { value: 450108, pid: 450100, label: '良庆区' },
          { value: 450109, pid: 450100, label: '邕宁区' },
          { value: 450110, pid: 450100, label: '武鸣区' },
          { value: 450123, pid: 450100, label: '隆安县' },
          { value: 450124, pid: 450100, label: '马山县' },
          { value: 450125, pid: 450100, label: '上林县' },
          { value: 450126, pid: 450100, label: '宾阳县' },
          { value: 450127, pid: 450100, label: '横县' },
          { value: 450128, pid: 450100, label: '埌东新区' },
        ],
      },
      {
        value: 450200,
        pid: 450000,
        label: '柳州市',
        children: [
          { value: 450202, pid: 450200, label: '城中区' },
          { value: 450203, pid: 450200, label: '鱼峰区' },
          { value: 450204, pid: 450200, label: '柳南区' },
          { value: 450205, pid: 450200, label: '柳北区' },
          { value: 450206, pid: 450200, label: '柳江区' },
          { value: 450222, pid: 450200, label: '柳城县' },
          { value: 450223, pid: 450200, label: '鹿寨县' },
          { value: 450224, pid: 450200, label: '融安县' },
          { value: 450225, pid: 450200, label: '融水苗族自治县' },
          { value: 450226, pid: 450200, label: '三江侗族自治县' },
          { value: 450227, pid: 450200, label: '柳东新区' },
        ],
      },
      {
        value: 450300,
        pid: 450000,
        label: '桂林市',
        children: [
          { value: 450302, pid: 450300, label: '秀峰区' },
          { value: 450303, pid: 450300, label: '叠彩区' },
          { value: 450304, pid: 450300, label: '象山区' },
          { value: 450305, pid: 450300, label: '七星区' },
          { value: 450311, pid: 450300, label: '雁山区' },
          { value: 450312, pid: 450300, label: '临桂区' },
          { value: 450321, pid: 450300, label: '阳朔县' },
          { value: 450323, pid: 450300, label: '灵川县' },
          { value: 450324, pid: 450300, label: '全州县' },
          { value: 450325, pid: 450300, label: '兴安县' },
          { value: 450326, pid: 450300, label: '永福县' },
          { value: 450327, pid: 450300, label: '灌阳县' },
          { value: 450328, pid: 450300, label: '龙胜各族自治县' },
          { value: 450329, pid: 450300, label: '资源县' },
          { value: 450330, pid: 450300, label: '平乐县' },
          { value: 450332, pid: 450300, label: '恭城瑶族自治县' },
          { value: 450381, pid: 450300, label: '荔浦市' },
        ],
      },
      {
        value: 450400,
        pid: 450000,
        label: '梧州市',
        children: [
          { value: 450403, pid: 450400, label: '万秀区' },
          { value: 450405, pid: 450400, label: '长洲区' },
          { value: 450406, pid: 450400, label: '龙圩区' },
          { value: 450421, pid: 450400, label: '苍梧县' },
          { value: 450422, pid: 450400, label: '藤县' },
          { value: 450423, pid: 450400, label: '蒙山县' },
          { value: 450481, pid: 450400, label: '岑溪市' },
        ],
      },
      {
        value: 450500,
        pid: 450000,
        label: '北海市',
        children: [
          { value: 450502, pid: 450500, label: '海城区' },
          { value: 450503, pid: 450500, label: '银海区' },
          { value: 450512, pid: 450500, label: '铁山港区' },
          { value: 450521, pid: 450500, label: '合浦县' },
        ],
      },
      {
        value: 450600,
        pid: 450000,
        label: '防城港市',
        children: [
          { value: 450602, pid: 450600, label: '港口区' },
          { value: 450603, pid: 450600, label: '防城区' },
          { value: 450621, pid: 450600, label: '上思县' },
          { value: 450681, pid: 450600, label: '东兴市' },
        ],
      },
      {
        value: 450700,
        pid: 450000,
        label: '钦州市',
        children: [
          { value: 450702, pid: 450700, label: '钦南区' },
          { value: 450703, pid: 450700, label: '钦北区' },
          { value: 450721, pid: 450700, label: '灵山县' },
          { value: 450722, pid: 450700, label: '浦北县' },
        ],
      },
      {
        value: 450800,
        pid: 450000,
        label: '贵港市',
        children: [
          { value: 450802, pid: 450800, label: '港北区' },
          { value: 450803, pid: 450800, label: '港南区' },
          { value: 450804, pid: 450800, label: '覃塘区' },
          { value: 450821, pid: 450800, label: '平南县' },
          { value: 450881, pid: 450800, label: '桂平市' },
        ],
      },
      {
        value: 450900,
        pid: 450000,
        label: '玉林市',
        children: [
          { value: 450902, pid: 450900, label: '玉州区' },
          { value: 450903, pid: 450900, label: '福绵区' },
          { value: 450921, pid: 450900, label: '容县' },
          { value: 450922, pid: 450900, label: '陆川县' },
          { value: 450923, pid: 450900, label: '博白县' },
          { value: 450924, pid: 450900, label: '兴业县' },
          { value: 450981, pid: 450900, label: '北流市' },
          { value: 450982, pid: 450900, label: '玉东新区' },
          { value: 450983, pid: 450900, label: '高新区' },
        ],
      },
      {
        value: 451000,
        pid: 450000,
        label: '百色市',
        children: [
          { value: 451002, pid: 451000, label: '右江区' },
          { value: 451021, pid: 451000, label: '田阳县' },
          { value: 451022, pid: 451000, label: '田东县' },
          { value: 451023, pid: 451000, label: '平果县' },
          { value: 451024, pid: 451000, label: '德保县' },
          { value: 451026, pid: 451000, label: '那坡县' },
          { value: 451027, pid: 451000, label: '凌云县' },
          { value: 451028, pid: 451000, label: '乐业县' },
          { value: 451029, pid: 451000, label: '田林县' },
          { value: 451030, pid: 451000, label: '西林县' },
          { value: 451031, pid: 451000, label: '隆林各族自治县' },
          { value: 451081, pid: 451000, label: '靖西市' },
        ],
      },
      {
        value: 451100,
        pid: 450000,
        label: '贺州市',
        children: [
          { value: 451102, pid: 451100, label: '八步区' },
          { value: 451103, pid: 451100, label: '平桂区' },
          { value: 451121, pid: 451100, label: '昭平县' },
          { value: 451122, pid: 451100, label: '钟山县' },
          { value: 451123, pid: 451100, label: '富川瑶族自治县' },
        ],
      },
      {
        value: 451200,
        pid: 450000,
        label: '河池市',
        children: [
          { value: 451202, pid: 451200, label: '金城江区' },
          { value: 451203, pid: 451200, label: '宜州区' },
          { value: 451221, pid: 451200, label: '南丹县' },
          { value: 451222, pid: 451200, label: '天峨县' },
          { value: 451223, pid: 451200, label: '凤山县' },
          { value: 451224, pid: 451200, label: '东兰县' },
          { value: 451225, pid: 451200, label: '罗城仫佬族自治县' },
          { value: 451226, pid: 451200, label: '环江毛南族自治县' },
          { value: 451227, pid: 451200, label: '巴马瑶族自治县' },
          { value: 451228, pid: 451200, label: '都安瑶族自治县' },
          { value: 451229, pid: 451200, label: '大化瑶族自治县' },
        ],
      },
      {
        value: 451300,
        pid: 450000,
        label: '来宾市',
        children: [
          { value: 451302, pid: 451300, label: '兴宾区' },
          { value: 451321, pid: 451300, label: '忻城县' },
          { value: 451322, pid: 451300, label: '象州县' },
          { value: 451323, pid: 451300, label: '武宣县' },
          { value: 451324, pid: 451300, label: '金秀瑶族自治县' },
          { value: 451381, pid: 451300, label: '合山市' },
        ],
      },
      {
        value: 451400,
        pid: 450000,
        label: '崇左市',
        children: [
          { value: 451402, pid: 451400, label: '江州区' },
          { value: 451421, pid: 451400, label: '扶绥县' },
          { value: 451422, pid: 451400, label: '宁明县' },
          { value: 451423, pid: 451400, label: '龙州县' },
          { value: 451424, pid: 451400, label: '大新县' },
          { value: 451425, pid: 451400, label: '天等县' },
          { value: 451481, pid: 451400, label: '凭祥市' },
        ],
      },
    ],
  },
  {
    value: 460000,
    pid: 0,
    label: '海南省',
    children: [
      {
        value: 460100,
        pid: 460000,
        label: '海口市',
        children: [
          { value: 460105, pid: 460100, label: '秀英区' },
          { value: 460106, pid: 460100, label: '龙华区' },
          { value: 460107, pid: 460100, label: '琼山区' },
          { value: 460108, pid: 460100, label: '美兰区' },
          { value: 460109, pid: 460100, label: '江东新区' },
        ],
      },
      {
        value: 460200,
        pid: 460000,
        label: '三亚市',
        children: [
          { value: 460202, pid: 460200, label: '海棠区' },
          { value: 460203, pid: 460200, label: '吉阳区' },
          { value: 460204, pid: 460200, label: '天涯区' },
          { value: 460205, pid: 460200, label: '崖州区' },
        ],
      },
      {
        value: 460300,
        pid: 460000,
        label: '三沙市',
        children: [
          { value: 460321, pid: 460300, label: '西沙群岛' },
          { value: 460322, pid: 460300, label: '南沙群岛' },
          { value: 460323, pid: 460300, label: '中沙群岛' },
        ],
      },
      {
        value: 460400,
        pid: 460000,
        label: '儋州市',
        children: [
          { value: 460401, pid: 460400, label: '洋浦经济开发区' },
          { value: 460402, pid: 460400, label: '那大镇' },
          { value: 460403, pid: 460400, label: '南丰镇' },
          { value: 460404, pid: 460400, label: '雅星镇' },
          { value: 460405, pid: 460400, label: '和庆镇' },
          { value: 460406, pid: 460400, label: '大成镇' },
          { value: 460407, pid: 460400, label: '新州镇' },
          { value: 460408, pid: 460400, label: '光村镇' },
          { value: 460409, pid: 460400, label: '东成镇' },
          { value: 460410, pid: 460400, label: '中和镇' },
          { value: 460411, pid: 460400, label: '峨蔓镇' },
          { value: 460412, pid: 460400, label: '兰洋镇' },
          { value: 460413, pid: 460400, label: '王五镇' },
          { value: 460414, pid: 460400, label: '排浦镇' },
          { value: 460415, pid: 460400, label: '海头镇' },
          { value: 460416, pid: 460400, label: '木棠镇' },
          { value: 460417, pid: 460400, label: '白马井镇' },
          { value: 460418, pid: 460400, label: '三都镇' },
          { value: 460419, pid: 460400, label: '西培农场' },
          { value: 460420, pid: 460400, label: '西联农场' },
          { value: 460421, pid: 460400, label: '蓝洋农场' },
          { value: 460422, pid: 460400, label: '八一农场' },
          { value: 460423, pid: 460400, label: '西华农场' },
          { value: 460424, pid: 460400, label: '西庆农场' },
          { value: 460425, pid: 460400, label: '西流农场' },
          { value: 460426, pid: 460400, label: '新盈农场' },
          { value: 460427, pid: 460400, label: '龙山农场' },
          { value: 460428, pid: 460400, label: '红岭农场' },
        ],
      },
      {
        value: 469001,
        pid: 460000,
        label: '五指山市',
        children: [
          { value: 469101, pid: 469001, label: '通什镇' },
          { value: 469102, pid: 469001, label: '南圣镇' },
          { value: 469103, pid: 469001, label: '毛阳镇' },
          { value: 469104, pid: 469001, label: '番阳镇' },
          { value: 469105, pid: 469001, label: '畅好乡' },
          { value: 469106, pid: 469001, label: '毛道乡' },
          { value: 469107, pid: 469001, label: '水满乡' },
        ],
      },
      {
        value: 469002,
        pid: 460000,
        label: '琼海市',
        children: [
          { value: 469201, pid: 469002, label: '嘉积镇' },
          { value: 469202, pid: 469002, label: '万泉镇' },
          { value: 469203, pid: 469002, label: '石壁镇' },
          { value: 469204, pid: 469002, label: '中原镇' },
          { value: 469205, pid: 469002, label: '博鳌镇' },
          { value: 469206, pid: 469002, label: '阳江镇' },
          { value: 469207, pid: 469002, label: '龙江镇' },
          { value: 469208, pid: 469002, label: '潭门镇' },
          { value: 469209, pid: 469002, label: '塔洋镇' },
          { value: 469210, pid: 469002, label: '长坡镇' },
          { value: 469211, pid: 469002, label: '大路镇' },
          { value: 469212, pid: 469002, label: '会山镇' },
          { value: 469213, pid: 469002, label: '东太农场' },
          { value: 469214, pid: 469002, label: '东红农场' },
          { value: 469215, pid: 469002, label: '东升农场' },
          { value: 469216, pid: 469002, label: '南俸农场' },
          { value: 469217, pid: 469002, label: '彬村山华侨农场' },
        ],
      },
      {
        value: 469005,
        pid: 460000,
        label: '文昌市',
        children: [
          { value: 469501, pid: 469005, label: '文城镇' },
          { value: 469502, pid: 469005, label: '重兴镇' },
          { value: 469503, pid: 469005, label: '蓬莱镇' },
          { value: 469504, pid: 469005, label: '会文镇' },
          { value: 469505, pid: 469005, label: '东路镇' },
          { value: 469506, pid: 469005, label: '潭牛镇' },
          { value: 469507, pid: 469005, label: '东阁镇' },
          { value: 469508, pid: 469005, label: '文教镇' },
          { value: 469509, pid: 469005, label: '东郊镇' },
          { value: 469510, pid: 469005, label: '龙楼镇' },
          { value: 469511, pid: 469005, label: '昌洒镇' },
          { value: 469512, pid: 469005, label: '翁田镇' },
          { value: 469513, pid: 469005, label: '抱罗镇' },
          { value: 469514, pid: 469005, label: '冯坡镇' },
          { value: 469515, pid: 469005, label: '锦山镇' },
          { value: 469516, pid: 469005, label: '铺前镇' },
          { value: 469517, pid: 469005, label: '公坡镇' },
          { value: 469518, pid: 469005, label: '迈号镇' },
          { value: 469519, pid: 469005, label: '清谰镇' },
          { value: 469520, pid: 469005, label: '南阳镇' },
          { value: 469521, pid: 469005, label: '新桥镇' },
          { value: 469522, pid: 469005, label: '头苑镇' },
          { value: 469523, pid: 469005, label: '宝芳乡' },
          { value: 469524, pid: 469005, label: '龙马乡' },
          { value: 469525, pid: 469005, label: '湖山乡' },
          { value: 469526, pid: 469005, label: '东路农场' },
          { value: 469527, pid: 469005, label: '南阳农场' },
          { value: 469528, pid: 469005, label: '罗豆农场' },
        ],
      },
      {
        value: 469006,
        pid: 460000,
        label: '万宁市',
        children: [
          { value: 469601, pid: 469006, label: '万城镇' },
          { value: 469602, pid: 469006, label: '龙滚镇' },
          { value: 469603, pid: 469006, label: '和乐镇' },
          { value: 469604, pid: 469006, label: '后安镇' },
          { value: 469605, pid: 469006, label: '大茂镇' },
          { value: 469606, pid: 469006, label: '东澳镇' },
          { value: 469607, pid: 469006, label: '礼纪镇' },
          { value: 469608, pid: 469006, label: '长丰镇' },
          { value: 469609, pid: 469006, label: '山根镇' },
          { value: 469610, pid: 469006, label: '北大镇' },
          { value: 469611, pid: 469006, label: '南桥镇' },
          { value: 469612, pid: 469006, label: '三更罗镇' },
          { value: 469613, pid: 469006, label: '东岭农场' },
          { value: 469614, pid: 469006, label: '南林农场' },
          { value: 469615, pid: 469006, label: '东兴农场' },
          { value: 469616, pid: 469006, label: '东和农场' },
          { value: 469617, pid: 469006, label: '新中农场' },
          { value: 469618, pid: 469006, label: '兴隆华侨农场' },
        ],
      },
      {
        value: 469007,
        pid: 460000,
        label: '东方市',
        children: [
          { value: 469701, pid: 469007, label: '八所镇' },
          { value: 469702, pid: 469007, label: '东河镇' },
          { value: 469703, pid: 469007, label: '大田镇' },
          { value: 469704, pid: 469007, label: '感城镇' },
          { value: 469705, pid: 469007, label: '板桥镇' },
          { value: 469706, pid: 469007, label: '三家镇' },
          { value: 469707, pid: 469007, label: '四更镇' },
          { value: 469708, pid: 469007, label: '新龙镇' },
          { value: 469709, pid: 469007, label: '天安乡' },
          { value: 469710, pid: 469007, label: '江边乡' },
          { value: 469711, pid: 469007, label: '广坝农场' },
          { value: 469712, pid: 469007, label: '东方华侨农场' },
        ],
      },
      {
        value: 469021,
        pid: 460000,
        label: '定安县',
        children: [
          { value: 469801, pid: 469021, label: '定城镇' },
          { value: 469802, pid: 469021, label: '新竹镇' },
          { value: 469803, pid: 469021, label: '龙湖镇' },
          { value: 469804, pid: 469021, label: '雷鸣镇' },
          { value: 469805, pid: 469021, label: '龙门镇' },
          { value: 469806, pid: 469021, label: '龙河镇' },
          { value: 469807, pid: 469021, label: '岭口镇' },
          { value: 469808, pid: 469021, label: '翰林镇' },
          { value: 469809, pid: 469021, label: '富文镇' },
          { value: 469810, pid: 469021, label: '黄竹镇' },
          { value: 469811, pid: 469021, label: '金鸡岭农场' },
          { value: 469812, pid: 469021, label: '中瑞农场' },
          { value: 469813, pid: 469021, label: '南海农场' },
          { value: 469814, pid: 469021, label: '城区' },
        ],
      },
      {
        value: 469022,
        pid: 460000,
        label: '屯昌县',
        children: [
          { value: 469821, pid: 469022, label: '屯城镇' },
          { value: 469822, pid: 469022, label: '新兴镇' },
          { value: 469823, pid: 469022, label: '枫木镇' },
          { value: 469824, pid: 469022, label: '乌坡镇' },
          { value: 469825, pid: 469022, label: '南吕镇' },
          { value: 469826, pid: 469022, label: '南坤镇' },
          { value: 469827, pid: 469022, label: '坡心镇' },
          { value: 469828, pid: 469022, label: '西昌镇' },
          { value: 469829, pid: 469022, label: '中建农场' },
          { value: 469830, pid: 469022, label: '中坤农场' },
          { value: 469831, pid: 469022, label: '县城内' },
        ],
      },
      {
        value: 469023,
        pid: 460000,
        label: '澄迈县',
        children: [
          { value: 469841, pid: 469023, label: '金江镇' },
          { value: 469842, pid: 469023, label: '老城镇' },
          { value: 469843, pid: 469023, label: '瑞溪镇' },
          { value: 469844, pid: 469023, label: '永发镇' },
          { value: 469845, pid: 469023, label: '加乐镇' },
          { value: 469846, pid: 469023, label: '文儒镇' },
          { value: 469847, pid: 469023, label: '中兴镇' },
          { value: 469848, pid: 469023, label: '仁兴镇' },
          { value: 469849, pid: 469023, label: '福山镇' },
          { value: 469850, pid: 469023, label: '桥头镇' },
          { value: 469851, pid: 469023, label: '大丰镇' },
          { value: 469852, pid: 469023, label: '红光农场' },
          { value: 469853, pid: 469023, label: '西达农场' },
          { value: 469854, pid: 469023, label: '金安农场' },
          { value: 469855, pid: 469023, label: '城区' },
        ],
      },
      {
        value: 469024,
        pid: 460000,
        label: '临高县',
        children: [
          { value: 469861, pid: 469024, label: '临城镇' },
          { value: 469862, pid: 469024, label: '波莲镇' },
          { value: 469863, pid: 469024, label: '东英镇' },
          { value: 469864, pid: 469024, label: '博厚镇' },
          { value: 469865, pid: 469024, label: '皇桐镇' },
          { value: 469866, pid: 469024, label: '多文镇' },
          { value: 469867, pid: 469024, label: '和舍镇' },
          { value: 469868, pid: 469024, label: '南宝镇' },
          { value: 469869, pid: 469024, label: '新盈镇' },
          { value: 469870, pid: 469024, label: '调楼镇' },
          { value: 469871, pid: 469024, label: '加来镇' },
          { value: 469872, pid: 469024, label: '红华农场' },
          { value: 469873, pid: 469024, label: '加来农场' },
          { value: 469874, pid: 469024, label: '城区' },
        ],
      },
      {
        value: 469025,
        pid: 460000,
        label: '白沙黎族自治县',
        children: [
          { value: 469881, pid: 469025, label: '牙叉镇' },
          { value: 469882, pid: 469025, label: '七坊镇' },
          { value: 469883, pid: 469025, label: '邦溪镇' },
          { value: 469884, pid: 469025, label: '打安镇' },
          { value: 469885, pid: 469025, label: '细水乡' },
          { value: 469886, pid: 469025, label: '元门乡' },
          { value: 469887, pid: 469025, label: '南开乡' },
          { value: 469888, pid: 469025, label: '阜龙乡' },
          { value: 469889, pid: 469025, label: '青松乡' },
          { value: 469890, pid: 469025, label: '金波乡' },
          { value: 469891, pid: 469025, label: '荣邦乡' },
          { value: 469892, pid: 469025, label: '白沙农场' },
          { value: 469893, pid: 469025, label: '龙江农场' },
          { value: 469894, pid: 469025, label: '邦溪农场' },
          { value: 469895, pid: 469025, label: '城区' },
        ],
      },
      {
        value: 469026,
        pid: 460000,
        label: '昌江黎族自治县',
        children: [
          { value: 469901, pid: 469026, label: '石碌镇' },
          { value: 469902, pid: 469026, label: '叉河镇' },
          { value: 469903, pid: 469026, label: '十月田镇' },
          { value: 469904, pid: 469026, label: '乌烈镇' },
          { value: 469905, pid: 469026, label: '海尾镇' },
          { value: 469906, pid: 469026, label: '南罗镇' },
          { value: 469907, pid: 469026, label: '太坡镇' },
          { value: 469908, pid: 469026, label: '昌化镇' },
          { value: 469909, pid: 469026, label: '七叉镇' },
          { value: 469910, pid: 469026, label: '保平乡' },
          { value: 469911, pid: 469026, label: '昌城乡' },
          { value: 469912, pid: 469026, label: '王下乡' },
          { value: 469913, pid: 469026, label: '霸王岭林场' },
          { value: 469914, pid: 469026, label: '红林农场' },
          { value: 469915, pid: 469026, label: '城区' },
        ],
      },
      {
        value: 469027,
        pid: 460000,
        label: '乐东黎族自治县',
        children: [
          { value: 469920, pid: 469027, label: '抱由镇' },
          { value: 469921, pid: 469027, label: '万冲镇' },
          { value: 469922, pid: 469027, label: '大安镇' },
          { value: 469923, pid: 469027, label: '志仲镇' },
          { value: 469924, pid: 469027, label: '千家镇' },
          { value: 469925, pid: 469027, label: '九所镇' },
          { value: 469926, pid: 469027, label: '利国镇' },
          { value: 469927, pid: 469027, label: '黄流镇' },
          { value: 469928, pid: 469027, label: '佛罗镇' },
          { value: 469929, pid: 469027, label: '尖峰镇' },
          { value: 469930, pid: 469027, label: '莺歌海镇' },
          { value: 469931, pid: 469027, label: '乐中农场' },
          { value: 469932, pid: 469027, label: '山荣农场' },
          { value: 469933, pid: 469027, label: '乐光农场' },
          { value: 469934, pid: 469027, label: '报伦农场' },
          { value: 469935, pid: 469027, label: '福报农场' },
          { value: 469936, pid: 469027, label: '保国农场' },
          { value: 469937, pid: 469027, label: '保显农场' },
          { value: 469938, pid: 469027, label: '尖峰岭林业' },
          { value: 469939, pid: 469027, label: '莺歌海盐场' },
          { value: 469940, pid: 469027, label: '城区' },
        ],
      },
      {
        value: 469028,
        pid: 460000,
        label: '陵水黎族自治县',
        children: [
          { value: 469941, pid: 469028, label: '椰林镇' },
          { value: 469942, pid: 469028, label: '光坡镇' },
          { value: 469943, pid: 469028, label: '三才镇' },
          { value: 469944, pid: 469028, label: '英州镇' },
          { value: 469945, pid: 469028, label: '隆广镇' },
          { value: 469946, pid: 469028, label: '文罗镇' },
          { value: 469947, pid: 469028, label: '本号镇' },
          { value: 469948, pid: 469028, label: '新村镇' },
          { value: 469949, pid: 469028, label: '黎安镇' },
          { value: 469950, pid: 469028, label: '提蒙乡' },
          { value: 469951, pid: 469028, label: '群英乡' },
          { value: 469952, pid: 469028, label: '岭门农场' },
          { value: 469953, pid: 469028, label: '南平农场' },
          { value: 469954, pid: 469028, label: '城区' },
        ],
      },
      {
        value: 469029,
        pid: 460000,
        label: '保亭黎族苗族自治县',
        children: [
          { value: 469961, pid: 469029, label: '保城镇' },
          { value: 469962, pid: 469029, label: '什玲镇' },
          { value: 469963, pid: 469029, label: '加茂镇' },
          { value: 469964, pid: 469029, label: '响水镇' },
          { value: 469965, pid: 469029, label: '新政镇' },
          { value: 469966, pid: 469029, label: '三道镇' },
          { value: 469967, pid: 469029, label: '六弓乡' },
          { value: 469968, pid: 469029, label: '南林乡' },
          { value: 469969, pid: 469029, label: '毛感乡' },
          { value: 469970, pid: 469029, label: '新星农场' },
          { value: 469971, pid: 469029, label: '金江农场' },
          { value: 469972, pid: 469029, label: '三道农场' },
        ],
      },
      {
        value: 469030,
        pid: 460000,
        label: '琼中黎族苗族自治县',
        children: [
          { value: 469981, pid: 469030, label: '营根镇' },
          { value: 469982, pid: 469030, label: '湾岭镇' },
          { value: 469983, pid: 469030, label: '黎母山镇' },
          { value: 469984, pid: 469030, label: '和平镇' },
          { value: 469985, pid: 469030, label: '长征镇' },
          { value: 469986, pid: 469030, label: '红毛镇' },
          { value: 469987, pid: 469030, label: '中平镇' },
          { value: 469988, pid: 469030, label: '上安乡' },
          { value: 469989, pid: 469030, label: '什运乡' },
          { value: 469990, pid: 469030, label: '吊罗山乡' },
          { value: 469991, pid: 469030, label: '阳江农场' },
          { value: 469992, pid: 469030, label: '乌石农场' },
          { value: 469993, pid: 469030, label: '加钗农场' },
          { value: 469994, pid: 469030, label: '长征农场' },
          { value: 469995, pid: 469030, label: '城区' },
        ],
      },
    ],
  },
  {
    value: 500000,
    pid: 0,
    label: '重庆',
    children: [
      {
        value: 500100,
        pid: 500000,
        label: '重庆市',
        children: [
          { value: 500101, pid: 500100, label: '万州区' },
          { value: 500102, pid: 500100, label: '涪陵区' },
          { value: 500103, pid: 500100, label: '渝中区' },
          { value: 500104, pid: 500100, label: '大渡口区' },
          { value: 500105, pid: 500100, label: '江北区' },
          { value: 500106, pid: 500100, label: '沙坪坝区' },
          { value: 500107, pid: 500100, label: '九龙坡区' },
          { value: 500108, pid: 500100, label: '南岸区' },
          { value: 500109, pid: 500100, label: '北碚区' },
          { value: 500110, pid: 500100, label: '綦江区' },
          { value: 500111, pid: 500100, label: '大足区' },
          { value: 500112, pid: 500100, label: '渝北区' },
          { value: 500113, pid: 500100, label: '巴南区' },
          { value: 500114, pid: 500100, label: '黔江区' },
          { value: 500115, pid: 500100, label: '长寿区' },
          { value: 500116, pid: 500100, label: '江津区' },
          { value: 500117, pid: 500100, label: '合川区' },
          { value: 500118, pid: 500100, label: '永川区' },
          { value: 500119, pid: 500100, label: '南川区' },
          { value: 500120, pid: 500100, label: '璧山区' },
          { value: 500151, pid: 500100, label: '铜梁区' },
          { value: 500152, pid: 500100, label: '潼南区' },
          { value: 500153, pid: 500100, label: '荣昌区' },
          { value: 500154, pid: 500100, label: '开州区' },
          { value: 500155, pid: 500100, label: '梁平区' },
          { value: 500156, pid: 500100, label: '武隆区' },
          { value: 500229, pid: 500100, label: '城口县' },
          { value: 500230, pid: 500100, label: '丰都县' },
          { value: 500231, pid: 500100, label: '垫江县' },
          { value: 500233, pid: 500100, label: '忠县' },
          { value: 500235, pid: 500100, label: '云阳县' },
          { value: 500236, pid: 500100, label: '奉节县' },
          { value: 500237, pid: 500100, label: '巫山县' },
          { value: 500238, pid: 500100, label: '巫溪县' },
          { value: 500240, pid: 500100, label: '石柱土家族自治县' },
          { value: 500241, pid: 500100, label: '秀山土家族苗族自治县' },
          { value: 500242, pid: 500100, label: '酉阳土家族苗族自治县' },
          { value: 500243, pid: 500100, label: '彭水苗族土家族自治县' },
          { value: 500300, pid: 500100, label: '两江新区' },
          { value: 500301, pid: 500100, label: '高新区' },
          { value: 500302, pid: 500100, label: '璧山高新区' },
        ],
      },
    ],
  },
  {
    value: 510000,
    pid: 0,
    label: '四川省',
    children: [
      {
        value: 510100,
        pid: 510000,
        label: '成都市',
        children: [
          { value: 510104, pid: 510100, label: '锦江区' },
          { value: 510105, pid: 510100, label: '青羊区' },
          { value: 510106, pid: 510100, label: '金牛区' },
          { value: 510107, pid: 510100, label: '武侯区' },
          { value: 510108, pid: 510100, label: '成华区' },
          { value: 510112, pid: 510100, label: '龙泉驿区' },
          { value: 510113, pid: 510100, label: '青白江区' },
          { value: 510114, pid: 510100, label: '新都区' },
          { value: 510115, pid: 510100, label: '温江区' },
          { value: 510116, pid: 510100, label: '双流区' },
          { value: 510117, pid: 510100, label: '郫都区' },
          { value: 510121, pid: 510100, label: '金堂县' },
          { value: 510129, pid: 510100, label: '大邑县' },
          { value: 510131, pid: 510100, label: '蒲江县' },
          { value: 510132, pid: 510100, label: '新津县' },
          { value: 510181, pid: 510100, label: '都江堰市' },
          { value: 510182, pid: 510100, label: '彭州市' },
          { value: 510183, pid: 510100, label: '邛崃市' },
          { value: 510184, pid: 510100, label: '崇州市' },
          { value: 510185, pid: 510100, label: '简阳市' },
          { value: 510186, pid: 510100, label: '天府新区' },
          { value: 510187, pid: 510100, label: '高新南区' },
          { value: 510188, pid: 510100, label: '高新西区' },
        ],
      },
      {
        value: 510300,
        pid: 510000,
        label: '自贡市',
        children: [
          { value: 510302, pid: 510300, label: '自流井区' },
          { value: 510303, pid: 510300, label: '贡井区' },
          { value: 510304, pid: 510300, label: '大安区' },
          { value: 510311, pid: 510300, label: '沿滩区' },
          { value: 510321, pid: 510300, label: '荣县' },
          { value: 510322, pid: 510300, label: '富顺县' },
          { value: 510323, pid: 510300, label: '高新区' },
        ],
      },
      {
        value: 510400,
        pid: 510000,
        label: '攀枝花市',
        children: [
          { value: 510402, pid: 510400, label: '东区' },
          { value: 510403, pid: 510400, label: '西区' },
          { value: 510411, pid: 510400, label: '仁和区' },
          { value: 510421, pid: 510400, label: '米易县' },
          { value: 510422, pid: 510400, label: '盐边县' },
        ],
      },
      {
        value: 510500,
        pid: 510000,
        label: '泸州市',
        children: [
          { value: 510502, pid: 510500, label: '江阳区' },
          { value: 510503, pid: 510500, label: '纳溪区' },
          { value: 510504, pid: 510500, label: '龙马潭区' },
          { value: 510521, pid: 510500, label: '泸县' },
          { value: 510522, pid: 510500, label: '合江县' },
          { value: 510524, pid: 510500, label: '叙永县' },
          { value: 510525, pid: 510500, label: '古蔺县' },
        ],
      },
      {
        value: 510600,
        pid: 510000,
        label: '德阳市',
        children: [
          { value: 510603, pid: 510600, label: '旌阳区' },
          { value: 510604, pid: 510600, label: '罗江区' },
          { value: 510623, pid: 510600, label: '中江县' },
          { value: 510681, pid: 510600, label: '广汉市' },
          { value: 510682, pid: 510600, label: '什邡市' },
          { value: 510683, pid: 510600, label: '绵竹市' },
        ],
      },
      {
        value: 510700,
        pid: 510000,
        label: '绵阳市',
        children: [
          { value: 510703, pid: 510700, label: '涪城区' },
          { value: 510704, pid: 510700, label: '游仙区' },
          { value: 510705, pid: 510700, label: '安州区' },
          { value: 510722, pid: 510700, label: '三台县' },
          { value: 510723, pid: 510700, label: '盐亭县' },
          { value: 510725, pid: 510700, label: '梓潼县' },
          { value: 510726, pid: 510700, label: '北川羌族自治县' },
          { value: 510727, pid: 510700, label: '平武县' },
          { value: 510781, pid: 510700, label: '江油市' },
          { value: 510782, pid: 510700, label: '高新区' },
          { value: 510783, pid: 510700, label: '经开区' },
        ],
      },
      {
        value: 510800,
        pid: 510000,
        label: '广元市',
        children: [
          { value: 510802, pid: 510800, label: '利州区' },
          { value: 510811, pid: 510800, label: '昭化区' },
          { value: 510812, pid: 510800, label: '朝天区' },
          { value: 510821, pid: 510800, label: '旺苍县' },
          { value: 510822, pid: 510800, label: '青川县' },
          { value: 510823, pid: 510800, label: '剑阁县' },
          { value: 510824, pid: 510800, label: '苍溪县' },
        ],
      },
      {
        value: 510900,
        pid: 510000,
        label: '遂宁市',
        children: [
          { value: 510903, pid: 510900, label: '船山区' },
          { value: 510904, pid: 510900, label: '安居区' },
          { value: 510921, pid: 510900, label: '蓬溪县' },
          { value: 510922, pid: 510900, label: '射洪县' },
          { value: 510923, pid: 510900, label: '大英县' },
          { value: 510924, pid: 510900, label: '经济技术开发区' },
        ],
      },
      {
        value: 511000,
        pid: 510000,
        label: '内江市',
        children: [
          { value: 511002, pid: 511000, label: '市中区' },
          { value: 511011, pid: 511000, label: '东兴区' },
          { value: 511024, pid: 511000, label: '威远县' },
          { value: 511025, pid: 511000, label: '资中县' },
          { value: 511083, pid: 511000, label: '隆昌市' },
        ],
      },
      {
        value: 511100,
        pid: 510000,
        label: '乐山市',
        children: [
          { value: 511102, pid: 511100, label: '市中区' },
          { value: 511111, pid: 511100, label: '沙湾区' },
          { value: 511112, pid: 511100, label: '五通桥区' },
          { value: 511113, pid: 511100, label: '金口河区' },
          { value: 511123, pid: 511100, label: '犍为县' },
          { value: 511124, pid: 511100, label: '井研县' },
          { value: 511126, pid: 511100, label: '夹江县' },
          { value: 511129, pid: 511100, label: '沐川县' },
          { value: 511132, pid: 511100, label: '峨边彝族自治县' },
          { value: 511133, pid: 511100, label: '马边彝族自治县' },
          { value: 511181, pid: 511100, label: '峨眉山市' },
        ],
      },
      {
        value: 511300,
        pid: 510000,
        label: '南充市',
        children: [
          { value: 511302, pid: 511300, label: '顺庆区' },
          { value: 511303, pid: 511300, label: '高坪区' },
          { value: 511304, pid: 511300, label: '嘉陵区' },
          { value: 511321, pid: 511300, label: '南部县' },
          { value: 511322, pid: 511300, label: '营山县' },
          { value: 511323, pid: 511300, label: '蓬安县' },
          { value: 511324, pid: 511300, label: '仪陇县' },
          { value: 511325, pid: 511300, label: '西充县' },
          { value: 511381, pid: 511300, label: '阆中市' },
        ],
      },
      {
        value: 511400,
        pid: 510000,
        label: '眉山市',
        children: [
          { value: 511402, pid: 511400, label: '东坡区' },
          { value: 511403, pid: 511400, label: '彭山区' },
          { value: 511421, pid: 511400, label: '仁寿县' },
          { value: 511423, pid: 511400, label: '洪雅县' },
          { value: 511424, pid: 511400, label: '丹棱县' },
          { value: 511425, pid: 511400, label: '青神县' },
        ],
      },
      {
        value: 511500,
        pid: 510000,
        label: '宜宾市',
        children: [
          { value: 511502, pid: 511500, label: '翠屏区' },
          { value: 511503, pid: 511500, label: '南溪区' },
          { value: 511504, pid: 511500, label: '叙州区' },
          { value: 511523, pid: 511500, label: '江安县' },
          { value: 511524, pid: 511500, label: '长宁县' },
          { value: 511525, pid: 511500, label: '高县' },
          { value: 511526, pid: 511500, label: '珙县' },
          { value: 511527, pid: 511500, label: '筠连县' },
          { value: 511528, pid: 511500, label: '兴文县' },
          { value: 511529, pid: 511500, label: '屏山县' },
        ],
      },
      {
        value: 511600,
        pid: 510000,
        label: '广安市',
        children: [
          { value: 511602, pid: 511600, label: '广安区' },
          { value: 511603, pid: 511600, label: '前锋区' },
          { value: 511621, pid: 511600, label: '岳池县' },
          { value: 511622, pid: 511600, label: '武胜县' },
          { value: 511623, pid: 511600, label: '邻水县' },
          { value: 511681, pid: 511600, label: '华蓥市' },
        ],
      },
      {
        value: 511700,
        pid: 510000,
        label: '达州市',
        children: [
          { value: 511702, pid: 511700, label: '通川区' },
          { value: 511703, pid: 511700, label: '达川区' },
          { value: 511722, pid: 511700, label: '宣汉县' },
          { value: 511723, pid: 511700, label: '开江县' },
          { value: 511724, pid: 511700, label: '大竹县' },
          { value: 511725, pid: 511700, label: '渠县' },
          { value: 511781, pid: 511700, label: '万源市' },
        ],
      },
      {
        value: 511800,
        pid: 510000,
        label: '雅安市',
        children: [
          { value: 511802, pid: 511800, label: '雨城区' },
          { value: 511803, pid: 511800, label: '名山区' },
          { value: 511822, pid: 511800, label: '荥经县' },
          { value: 511823, pid: 511800, label: '汉源县' },
          { value: 511824, pid: 511800, label: '石棉县' },
          { value: 511825, pid: 511800, label: '天全县' },
          { value: 511826, pid: 511800, label: '芦山县' },
          { value: 511827, pid: 511800, label: '宝兴县' },
        ],
      },
      {
        value: 511900,
        pid: 510000,
        label: '巴中市',
        children: [
          { value: 511902, pid: 511900, label: '巴州区' },
          { value: 511903, pid: 511900, label: '恩阳区' },
          { value: 511921, pid: 511900, label: '通江县' },
          { value: 511922, pid: 511900, label: '南江县' },
          { value: 511923, pid: 511900, label: '平昌县' },
        ],
      },
      {
        value: 512000,
        pid: 510000,
        label: '资阳市',
        children: [
          { value: 512002, pid: 512000, label: '雁江区' },
          { value: 512021, pid: 512000, label: '安岳县' },
          { value: 512022, pid: 512000, label: '乐至县' },
        ],
      },
      {
        value: 513200,
        pid: 510000,
        label: '阿坝藏族羌族自治州',
        children: [
          { value: 513201, pid: 513200, label: '马尔康市' },
          { value: 513221, pid: 513200, label: '汶川县' },
          { value: 513222, pid: 513200, label: '理县' },
          { value: 513223, pid: 513200, label: '茂县' },
          { value: 513224, pid: 513200, label: '松潘县' },
          { value: 513225, pid: 513200, label: '九寨沟县' },
          { value: 513226, pid: 513200, label: '金川县' },
          { value: 513227, pid: 513200, label: '小金县' },
          { value: 513228, pid: 513200, label: '黑水县' },
          { value: 513230, pid: 513200, label: '壤塘县' },
          { value: 513231, pid: 513200, label: '阿坝县' },
          { value: 513232, pid: 513200, label: '若尔盖县' },
          { value: 513233, pid: 513200, label: '红原县' },
        ],
      },
      {
        value: 513300,
        pid: 510000,
        label: '甘孜藏族自治州',
        children: [
          { value: 513301, pid: 513300, label: '康定市' },
          { value: 513322, pid: 513300, label: '泸定县' },
          { value: 513323, pid: 513300, label: '丹巴县' },
          { value: 513324, pid: 513300, label: '九龙县' },
          { value: 513325, pid: 513300, label: '雅江县' },
          { value: 513326, pid: 513300, label: '道孚县' },
          { value: 513327, pid: 513300, label: '炉霍县' },
          { value: 513328, pid: 513300, label: '甘孜县' },
          { value: 513329, pid: 513300, label: '新龙县' },
          { value: 513330, pid: 513300, label: '德格县' },
          { value: 513331, pid: 513300, label: '白玉县' },
          { value: 513332, pid: 513300, label: '石渠县' },
          { value: 513333, pid: 513300, label: '色达县' },
          { value: 513334, pid: 513300, label: '理塘县' },
          { value: 513335, pid: 513300, label: '巴塘县' },
          { value: 513336, pid: 513300, label: '乡城县' },
          { value: 513337, pid: 513300, label: '稻城县' },
          { value: 513338, pid: 513300, label: '得荣县' },
        ],
      },
      {
        value: 513400,
        pid: 510000,
        label: '凉山彝族自治州',
        children: [
          { value: 513401, pid: 513400, label: '西昌市' },
          { value: 513422, pid: 513400, label: '木里藏族自治县' },
          { value: 513423, pid: 513400, label: '盐源县' },
          { value: 513424, pid: 513400, label: '德昌县' },
          { value: 513425, pid: 513400, label: '会理县' },
          { value: 513426, pid: 513400, label: '会东县' },
          { value: 513427, pid: 513400, label: '宁南县' },
          { value: 513428, pid: 513400, label: '普格县' },
          { value: 513429, pid: 513400, label: '布拖县' },
          { value: 513430, pid: 513400, label: '金阳县' },
          { value: 513431, pid: 513400, label: '昭觉县' },
          { value: 513432, pid: 513400, label: '喜德县' },
          { value: 513433, pid: 513400, label: '冕宁县' },
          { value: 513434, pid: 513400, label: '越西县' },
          { value: 513435, pid: 513400, label: '甘洛县' },
          { value: 513436, pid: 513400, label: '美姑县' },
          { value: 513437, pid: 513400, label: '雷波县' },
        ],
      },
    ],
  },
  {
    value: 520000,
    pid: 0,
    label: '贵州省',
    children: [
      {
        value: 520100,
        pid: 520000,
        label: '贵阳市',
        children: [
          { value: 520102, pid: 520100, label: '南明区' },
          { value: 520103, pid: 520100, label: '云岩区' },
          { value: 520111, pid: 520100, label: '花溪区' },
          { value: 520112, pid: 520100, label: '乌当区' },
          { value: 520113, pid: 520100, label: '白云区' },
          { value: 520115, pid: 520100, label: '观山湖区' },
          { value: 520121, pid: 520100, label: '开阳县' },
          { value: 520122, pid: 520100, label: '息烽县' },
          { value: 520123, pid: 520100, label: '修文县' },
          { value: 520181, pid: 520100, label: '清镇市' },
          { value: 520182, pid: 520100, label: '贵安新区' },
          { value: 520183, pid: 520100, label: '高新区' },
        ],
      },
      {
        value: 520200,
        pid: 520000,
        label: '六盘水市',
        children: [
          { value: 520201, pid: 520200, label: '钟山区' },
          { value: 520203, pid: 520200, label: '六枝特区' },
          { value: 520221, pid: 520200, label: '水城县' },
          { value: 520281, pid: 520200, label: '盘州市' },
        ],
      },
      {
        value: 520300,
        pid: 520000,
        label: '遵义市',
        children: [
          { value: 520302, pid: 520300, label: '红花岗区' },
          { value: 520303, pid: 520300, label: '汇川区' },
          { value: 520304, pid: 520300, label: '播州区' },
          { value: 520322, pid: 520300, label: '桐梓县' },
          { value: 520323, pid: 520300, label: '绥阳县' },
          { value: 520324, pid: 520300, label: '正安县' },
          { value: 520325, pid: 520300, label: '道真仡佬族苗族自治县' },
          { value: 520326, pid: 520300, label: '务川仡佬族苗族自治县' },
          { value: 520327, pid: 520300, label: '凤冈县' },
          { value: 520328, pid: 520300, label: '湄潭县' },
          { value: 520329, pid: 520300, label: '余庆县' },
          { value: 520330, pid: 520300, label: '习水县' },
          { value: 520381, pid: 520300, label: '赤水市' },
          { value: 520382, pid: 520300, label: '仁怀市' },
        ],
      },
      {
        value: 520400,
        pid: 520000,
        label: '安顺市',
        children: [
          { value: 520402, pid: 520400, label: '西秀区' },
          { value: 520403, pid: 520400, label: '平坝区' },
          { value: 520422, pid: 520400, label: '普定县' },
          { value: 520423, pid: 520400, label: '镇宁布依族苗族自治县' },
          { value: 520424, pid: 520400, label: '关岭布依族苗族自治县' },
          { value: 520425, pid: 520400, label: '紫云苗族布依族自治县' },
        ],
      },
      {
        value: 520500,
        pid: 520000,
        label: '毕节市',
        children: [
          { value: 520502, pid: 520500, label: '七星关区' },
          { value: 520521, pid: 520500, label: '大方县' },
          { value: 520522, pid: 520500, label: '黔西县' },
          { value: 520523, pid: 520500, label: '金沙县' },
          { value: 520524, pid: 520500, label: '织金县' },
          { value: 520525, pid: 520500, label: '纳雍县' },
          { value: 520526, pid: 520500, label: '威宁彝族回族苗族自治县' },
          { value: 520527, pid: 520500, label: '赫章县' },
        ],
      },
      {
        value: 520600,
        pid: 520000,
        label: '铜仁市',
        children: [
          { value: 520602, pid: 520600, label: '碧江区' },
          { value: 520603, pid: 520600, label: '万山区' },
          { value: 520621, pid: 520600, label: '江口县' },
          { value: 520622, pid: 520600, label: '玉屏侗族自治县' },
          { value: 520623, pid: 520600, label: '石阡县' },
          { value: 520624, pid: 520600, label: '思南县' },
          { value: 520625, pid: 520600, label: '印江土家族苗族自治县' },
          { value: 520626, pid: 520600, label: '德江县' },
          { value: 520627, pid: 520600, label: '沿河土家族自治县' },
          { value: 520628, pid: 520600, label: '松桃苗族自治县' },
        ],
      },
      {
        value: 522300,
        pid: 520000,
        label: '黔西南布依族苗族自治州',
        children: [
          { value: 522301, pid: 522300, label: '兴义市 ' },
          { value: 522302, pid: 522300, label: '兴仁市' },
          { value: 522323, pid: 522300, label: '普安县' },
          { value: 522324, pid: 522300, label: '晴隆县' },
          { value: 522325, pid: 522300, label: '贞丰县' },
          { value: 522326, pid: 522300, label: '望谟县' },
          { value: 522327, pid: 522300, label: '册亨县' },
          { value: 522328, pid: 522300, label: '安龙县' },
        ],
      },
      {
        value: 522600,
        pid: 520000,
        label: '黔东南苗族侗族自治州',
        children: [
          { value: 522601, pid: 522600, label: '凯里市' },
          { value: 522622, pid: 522600, label: '黄平县' },
          { value: 522623, pid: 522600, label: '施秉县' },
          { value: 522624, pid: 522600, label: '三穗县' },
          { value: 522625, pid: 522600, label: '镇远县' },
          { value: 522626, pid: 522600, label: '岑巩县' },
          { value: 522627, pid: 522600, label: '天柱县' },
          { value: 522628, pid: 522600, label: '锦屏县' },
          { value: 522629, pid: 522600, label: '剑河县' },
          { value: 522630, pid: 522600, label: '台江县' },
          { value: 522631, pid: 522600, label: '黎平县' },
          { value: 522632, pid: 522600, label: '榕江县' },
          { value: 522633, pid: 522600, label: '从江县' },
          { value: 522634, pid: 522600, label: '雷山县' },
          { value: 522635, pid: 522600, label: '麻江县' },
          { value: 522636, pid: 522600, label: '丹寨县' },
        ],
      },
      {
        value: 522700,
        pid: 520000,
        label: '黔南布依族苗族自治州',
        children: [
          { value: 522701, pid: 522700, label: '都匀市' },
          { value: 522702, pid: 522700, label: '福泉市' },
          { value: 522722, pid: 522700, label: '荔波县' },
          { value: 522723, pid: 522700, label: '贵定县' },
          { value: 522725, pid: 522700, label: '瓮安县' },
          { value: 522726, pid: 522700, label: '独山县' },
          { value: 522727, pid: 522700, label: '平塘县' },
          { value: 522728, pid: 522700, label: '罗甸县' },
          { value: 522729, pid: 522700, label: '长顺县' },
          { value: 522730, pid: 522700, label: '龙里县' },
          { value: 522731, pid: 522700, label: '惠水县' },
          { value: 522732, pid: 522700, label: '三都水族自治县' },
        ],
      },
    ],
  },
  {
    value: 530000,
    pid: 0,
    label: '云南省',
    children: [
      {
        value: 530100,
        pid: 530000,
        label: '昆明市',
        children: [
          { value: 530102, pid: 530100, label: '五华区' },
          { value: 530103, pid: 530100, label: '盘龙区' },
          { value: 530111, pid: 530100, label: '官渡区' },
          { value: 530112, pid: 530100, label: '西山区' },
          { value: 530113, pid: 530100, label: '东川区' },
          { value: 530114, pid: 530100, label: '呈贡区' },
          { value: 530115, pid: 530100, label: '晋宁区' },
          { value: 530124, pid: 530100, label: '富民县' },
          { value: 530125, pid: 530100, label: '宜良县' },
          { value: 530126, pid: 530100, label: '石林彝族自治县' },
          { value: 530127, pid: 530100, label: '嵩明县' },
          { value: 530128, pid: 530100, label: '禄劝彝族苗族自治县' },
          { value: 530129, pid: 530100, label: '寻甸回族彝族自治县 ' },
          { value: 530181, pid: 530100, label: '安宁市' },
          { value: 530182, pid: 530100, label: '滇中新区' },
          { value: 530183, pid: 530100, label: '高新区' },
        ],
      },
      {
        value: 530300,
        pid: 530000,
        label: '曲靖市',
        children: [
          { value: 530302, pid: 530300, label: '麒麟区' },
          { value: 530303, pid: 530300, label: '沾益区' },
          { value: 530304, pid: 530300, label: '马龙区' },
          { value: 530322, pid: 530300, label: '陆良县' },
          { value: 530323, pid: 530300, label: '师宗县' },
          { value: 530324, pid: 530300, label: '罗平县' },
          { value: 530325, pid: 530300, label: '富源县' },
          { value: 530326, pid: 530300, label: '会泽县' },
          { value: 530381, pid: 530300, label: '宣威市' },
        ],
      },
      {
        value: 530400,
        pid: 530000,
        label: '玉溪市',
        children: [
          { value: 530402, pid: 530400, label: '红塔区' },
          { value: 530403, pid: 530400, label: '江川区' },
          { value: 530422, pid: 530400, label: '澄江县' },
          { value: 530423, pid: 530400, label: '通海县' },
          { value: 530424, pid: 530400, label: '华宁县' },
          { value: 530425, pid: 530400, label: '易门县' },
          { value: 530426, pid: 530400, label: '峨山彝族自治县' },
          { value: 530427, pid: 530400, label: '新平彝族傣族自治县' },
          { value: 530428, pid: 530400, label: '元江哈尼族彝族傣族自治县' },
        ],
      },
      {
        value: 530500,
        pid: 530000,
        label: '保山市',
        children: [
          { value: 530502, pid: 530500, label: '隆阳区' },
          { value: 530521, pid: 530500, label: '施甸县' },
          { value: 530523, pid: 530500, label: '龙陵县' },
          { value: 530524, pid: 530500, label: '昌宁县' },
          { value: 530581, pid: 530500, label: '腾冲市' },
        ],
      },
      {
        value: 530600,
        pid: 530000,
        label: '昭通市',
        children: [
          { value: 530602, pid: 530600, label: '昭阳区' },
          { value: 530621, pid: 530600, label: '鲁甸县' },
          { value: 530622, pid: 530600, label: '巧家县' },
          { value: 530623, pid: 530600, label: '盐津县' },
          { value: 530624, pid: 530600, label: '大关县' },
          { value: 530625, pid: 530600, label: '永善县' },
          { value: 530626, pid: 530600, label: '绥江县' },
          { value: 530627, pid: 530600, label: '镇雄县' },
          { value: 530628, pid: 530600, label: '彝良县' },
          { value: 530629, pid: 530600, label: '威信县' },
          { value: 530681, pid: 530600, label: '水富市' },
        ],
      },
      {
        value: 530700,
        pid: 530000,
        label: '丽江市',
        children: [
          { value: 530702, pid: 530700, label: '古城区' },
          { value: 530721, pid: 530700, label: '玉龙纳西族自治县' },
          { value: 530722, pid: 530700, label: '永胜县' },
          { value: 530723, pid: 530700, label: '华坪县' },
          { value: 530724, pid: 530700, label: '宁蒗彝族自治县' },
        ],
      },
      {
        value: 530800,
        pid: 530000,
        label: '普洱市',
        children: [
          { value: 530802, pid: 530800, label: '思茅区' },
          { value: 530821, pid: 530800, label: '宁洱哈尼族彝族自治县' },
          { value: 530822, pid: 530800, label: '墨江哈尼族自治县' },
          { value: 530823, pid: 530800, label: '景东彝族自治县' },
          { value: 530824, pid: 530800, label: '景谷傣族彝族自治县' },
          { value: 530825, pid: 530800, label: '镇沅彝族哈尼族拉祜族自治县' },
          { value: 530826, pid: 530800, label: '江城哈尼族彝族自治县' },
          { value: 530827, pid: 530800, label: '孟连傣族拉祜族佤族自治县' },
          { value: 530828, pid: 530800, label: '澜沧拉祜族自治县' },
          { value: 530829, pid: 530800, label: '西盟佤族自治县' },
        ],
      },
      {
        value: 530900,
        pid: 530000,
        label: '临沧市',
        children: [
          { value: 530902, pid: 530900, label: '临翔区' },
          { value: 530921, pid: 530900, label: '凤庆县' },
          { value: 530922, pid: 530900, label: '云县' },
          { value: 530923, pid: 530900, label: '永德县' },
          { value: 530924, pid: 530900, label: '镇康县' },
          { value: 530925, pid: 530900, label: '双江拉祜族佤族布朗族傣族自治县' },
          { value: 530926, pid: 530900, label: '耿马傣族佤族自治县' },
          { value: 530927, pid: 530900, label: '沧源佤族自治县' },
        ],
      },
      {
        value: 532300,
        pid: 530000,
        label: '楚雄彝族自治州',
        children: [
          { value: 532301, pid: 532300, label: '楚雄市' },
          { value: 532322, pid: 532300, label: '双柏县' },
          { value: 532323, pid: 532300, label: '牟定县' },
          { value: 532324, pid: 532300, label: '南华县' },
          { value: 532325, pid: 532300, label: '姚安县' },
          { value: 532326, pid: 532300, label: '大姚县' },
          { value: 532327, pid: 532300, label: '永仁县' },
          { value: 532328, pid: 532300, label: '元谋县' },
          { value: 532329, pid: 532300, label: '武定县' },
          { value: 532331, pid: 532300, label: '禄丰县' },
        ],
      },
      {
        value: 532500,
        pid: 530000,
        label: '红河哈尼族彝族自治州',
        children: [
          { value: 532501, pid: 532500, label: '个旧市' },
          { value: 532502, pid: 532500, label: '开远市' },
          { value: 532503, pid: 532500, label: '蒙自市' },
          { value: 532504, pid: 532500, label: '弥勒市' },
          { value: 532523, pid: 532500, label: '屏边苗族自治县' },
          { value: 532524, pid: 532500, label: '建水县' },
          { value: 532525, pid: 532500, label: '石屏县' },
          { value: 532527, pid: 532500, label: '泸西县' },
          { value: 532528, pid: 532500, label: '元阳县' },
          { value: 532529, pid: 532500, label: '红河县' },
          { value: 532530, pid: 532500, label: '金平苗族瑶族傣族自治县' },
          { value: 532531, pid: 532500, label: '绿春县' },
          { value: 532532, pid: 532500, label: '河口瑶族自治县' },
        ],
      },
      {
        value: 532600,
        pid: 530000,
        label: '文山壮族苗族自治州',
        children: [
          { value: 532601, pid: 532600, label: '文山市' },
          { value: 532622, pid: 532600, label: '砚山县' },
          { value: 532623, pid: 532600, label: '西畴县' },
          { value: 532624, pid: 532600, label: '麻栗坡县' },
          { value: 532625, pid: 532600, label: '马关县' },
          { value: 532626, pid: 532600, label: '丘北县' },
          { value: 532627, pid: 532600, label: '广南县' },
          { value: 532628, pid: 532600, label: '富宁县' },
        ],
      },
      {
        value: 532800,
        pid: 530000,
        label: '西双版纳傣族自治州',
        children: [
          { value: 532801, pid: 532800, label: '景洪市' },
          { value: 532822, pid: 532800, label: '勐海县' },
          { value: 532823, pid: 532800, label: '勐腊县' },
        ],
      },
      {
        value: 532900,
        pid: 530000,
        label: '大理白族自治州',
        children: [
          { value: 532901, pid: 532900, label: '大理市' },
          { value: 532922, pid: 532900, label: '漾濞彝族自治县' },
          { value: 532923, pid: 532900, label: '祥云县' },
          { value: 532924, pid: 532900, label: '宾川县' },
          { value: 532925, pid: 532900, label: '弥渡县' },
          { value: 532926, pid: 532900, label: '南涧彝族自治县' },
          { value: 532927, pid: 532900, label: '巍山彝族回族自治县' },
          { value: 532928, pid: 532900, label: '永平县' },
          { value: 532929, pid: 532900, label: '云龙县' },
          { value: 532930, pid: 532900, label: '洱源县' },
          { value: 532931, pid: 532900, label: '剑川县' },
          { value: 532932, pid: 532900, label: '鹤庆县' },
        ],
      },
      {
        value: 533100,
        pid: 530000,
        label: '德宏傣族景颇族自治州',
        children: [
          { value: 533102, pid: 533100, label: '瑞丽市' },
          { value: 533103, pid: 533100, label: '芒市' },
          { value: 533122, pid: 533100, label: '梁河县' },
          { value: 533123, pid: 533100, label: '盈江县' },
          { value: 533124, pid: 533100, label: '陇川县' },
        ],
      },
      {
        value: 533300,
        pid: 530000,
        label: '怒江傈僳族自治州',
        children: [
          { value: 533301, pid: 533300, label: '泸水市' },
          { value: 533323, pid: 533300, label: '福贡县' },
          { value: 533324, pid: 533300, label: '贡山独龙族怒族自治县' },
          { value: 533325, pid: 533300, label: '兰坪白族普米族自治县' },
        ],
      },
      {
        value: 533400,
        pid: 530000,
        label: '迪庆藏族自治州',
        children: [
          { value: 533401, pid: 533400, label: '香格里拉市' },
          { value: 533422, pid: 533400, label: '德钦县' },
          { value: 533423, pid: 533400, label: '维西傈僳族自治县' },
        ],
      },
    ],
  },
  {
    value: 540000,
    pid: 0,
    label: '西藏自治区',
    children: [
      {
        value: 540100,
        pid: 540000,
        label: '拉萨市',
        children: [
          { value: 540102, pid: 540100, label: '城关区' },
          { value: 540103, pid: 540100, label: '堆龙德庆区' },
          { value: 540104, pid: 540100, label: '达孜区' },
          { value: 540121, pid: 540100, label: '林周县' },
          { value: 540122, pid: 540100, label: '当雄县' },
          { value: 540123, pid: 540100, label: '尼木县' },
          { value: 540124, pid: 540100, label: '曲水县' },
          { value: 540127, pid: 540100, label: '墨竹工卡县' },
          { value: 540171, pid: 540100, label: '格尔木藏青工业园区' },
          { value: 540173, pid: 540100, label: '西藏文化旅游创意园区' },
          { value: 540174, pid: 540100, label: '达孜工业园区' },
        ],
      },
      {
        value: 540200,
        pid: 540000,
        label: '日喀则市',
        children: [
          { value: 540202, pid: 540200, label: '桑珠孜区' },
          { value: 540221, pid: 540200, label: '南木林县' },
          { value: 540222, pid: 540200, label: '江孜县' },
          { value: 540223, pid: 540200, label: '定日县' },
          { value: 540224, pid: 540200, label: '萨迦县' },
          { value: 540225, pid: 540200, label: '拉孜县' },
          { value: 540226, pid: 540200, label: '昂仁县' },
          { value: 540227, pid: 540200, label: '谢通门县' },
          { value: 540228, pid: 540200, label: '白朗县' },
          { value: 540229, pid: 540200, label: '仁布县' },
          { value: 540230, pid: 540200, label: '康马县' },
          { value: 540231, pid: 540200, label: '定结县' },
          { value: 540232, pid: 540200, label: '仲巴县' },
          { value: 540233, pid: 540200, label: '亚东县' },
          { value: 540234, pid: 540200, label: '吉隆县' },
          { value: 540235, pid: 540200, label: '聂拉木县' },
          { value: 540236, pid: 540200, label: '萨嘎县' },
          { value: 540237, pid: 540200, label: '岗巴县' },
        ],
      },
      {
        value: 540300,
        pid: 540000,
        label: '昌都市',
        children: [
          { value: 540302, pid: 540300, label: '卡若区' },
          { value: 540321, pid: 540300, label: '江达县' },
          { value: 540322, pid: 540300, label: '贡觉县' },
          { value: 540323, pid: 540300, label: '类乌齐县' },
          { value: 540324, pid: 540300, label: '丁青县' },
          { value: 540325, pid: 540300, label: '察雅县' },
          { value: 540326, pid: 540300, label: '八宿县' },
          { value: 540327, pid: 540300, label: '左贡县' },
          { value: 540328, pid: 540300, label: '芒康县' },
          { value: 540329, pid: 540300, label: '洛隆县' },
          { value: 540330, pid: 540300, label: '边坝县' },
        ],
      },
      {
        value: 540400,
        pid: 540000,
        label: '林芝市',
        children: [
          { value: 540402, pid: 540400, label: '巴宜区' },
          { value: 540421, pid: 540400, label: '工布江达县' },
          { value: 540422, pid: 540400, label: '米林县' },
          { value: 540423, pid: 540400, label: '墨脱县' },
          { value: 540424, pid: 540400, label: '波密县' },
          { value: 540425, pid: 540400, label: '察隅县' },
          { value: 540426, pid: 540400, label: '朗县' },
        ],
      },
      {
        value: 540500,
        pid: 540000,
        label: '山南市',
        children: [
          { value: 540502, pid: 540500, label: '乃东区' },
          { value: 540521, pid: 540500, label: '扎囊县' },
          { value: 540522, pid: 540500, label: '贡嘎县' },
          { value: 540523, pid: 540500, label: '桑日县' },
          { value: 540524, pid: 540500, label: '琼结县' },
          { value: 540525, pid: 540500, label: '曲松县' },
          { value: 540526, pid: 540500, label: '措美县' },
          { value: 540527, pid: 540500, label: '洛扎县' },
          { value: 540528, pid: 540500, label: '加查县' },
          { value: 540529, pid: 540500, label: '隆子县' },
          { value: 540530, pid: 540500, label: '错那县' },
          { value: 540531, pid: 540500, label: '浪卡子县' },
        ],
      },
      {
        value: 540600,
        pid: 540000,
        label: '那曲市',
        children: [
          { value: 540602, pid: 540600, label: '色尼区' },
          { value: 540621, pid: 540600, label: '嘉黎县' },
          { value: 540622, pid: 540600, label: '比如县' },
          { value: 540623, pid: 540600, label: '聂荣县' },
          { value: 540624, pid: 540600, label: '安多县' },
          { value: 540625, pid: 540600, label: '申扎县' },
          { value: 540626, pid: 540600, label: '索县' },
          { value: 540627, pid: 540600, label: '班戈县' },
          { value: 540628, pid: 540600, label: '巴青县' },
          { value: 540629, pid: 540600, label: '尼玛县' },
          { value: 540630, pid: 540600, label: '双湖县' },
        ],
      },
      {
        value: 542500,
        pid: 540000,
        label: '阿里地区',
        children: [
          { value: 542521, pid: 542500, label: '普兰县' },
          { value: 542522, pid: 542500, label: '札达县' },
          { value: 542523, pid: 542500, label: '噶尔县' },
          { value: 542524, pid: 542500, label: '日土县' },
          { value: 542525, pid: 542500, label: '革吉县' },
          { value: 542526, pid: 542500, label: '改则县' },
          { value: 542527, pid: 542500, label: '措勤县' },
        ],
      },
    ],
  },
  {
    value: 610000,
    pid: 0,
    label: '陕西省',
    children: [
      {
        value: 610100,
        pid: 610000,
        label: '西安市',
        children: [
          { value: 610102, pid: 610100, label: '新城区' },
          { value: 610103, pid: 610100, label: '碑林区' },
          { value: 610104, pid: 610100, label: '莲湖区' },
          { value: 610111, pid: 610100, label: '灞桥区' },
          { value: 610112, pid: 610100, label: '未央区' },
          { value: 610113, pid: 610100, label: '雁塔区' },
          { value: 610114, pid: 610100, label: '阎良区' },
          { value: 610115, pid: 610100, label: '临潼区' },
          { value: 610116, pid: 610100, label: '长安区' },
          { value: 610117, pid: 610100, label: '高陵区' },
          { value: 610118, pid: 610100, label: '鄠邑区' },
          { value: 610122, pid: 610100, label: '蓝田县' },
          { value: 610124, pid: 610100, label: '周至县' },
          { value: 610125, pid: 610100, label: '西咸新区' },
          { value: 610127, pid: 610100, label: '曲江新区' },
          { value: 610128, pid: 610100, label: '高新区' },
        ],
      },
      {
        value: 610200,
        pid: 610000,
        label: '铜川市',
        children: [
          { value: 610202, pid: 610200, label: '王益区' },
          { value: 610203, pid: 610200, label: '印台区' },
          { value: 610204, pid: 610200, label: '耀州区' },
          { value: 610222, pid: 610200, label: '宜君县' },
        ],
      },
      {
        value: 610300,
        pid: 610000,
        label: '宝鸡市',
        children: [
          { value: 610302, pid: 610300, label: '渭滨区' },
          { value: 610303, pid: 610300, label: '金台区' },
          { value: 610304, pid: 610300, label: '陈仓区' },
          { value: 610322, pid: 610300, label: '凤翔县' },
          { value: 610323, pid: 610300, label: '岐山县' },
          { value: 610324, pid: 610300, label: '扶风县' },
          { value: 610326, pid: 610300, label: '眉县' },
          { value: 610327, pid: 610300, label: '陇县' },
          { value: 610328, pid: 610300, label: '千阳县' },
          { value: 610329, pid: 610300, label: '麟游县' },
          { value: 610330, pid: 610300, label: '凤县' },
          { value: 610331, pid: 610300, label: '太白县' },
          { value: 610332, pid: 610300, label: '高新区' },
        ],
      },
      {
        value: 610400,
        pid: 610000,
        label: '咸阳市',
        children: [
          { value: 610402, pid: 610400, label: '秦都区' },
          { value: 610403, pid: 610400, label: '杨陵区' },
          { value: 610404, pid: 610400, label: '渭城区' },
          { value: 610422, pid: 610400, label: '三原县' },
          { value: 610423, pid: 610400, label: '泾阳县' },
          { value: 610424, pid: 610400, label: '乾县' },
          { value: 610425, pid: 610400, label: '礼泉县' },
          { value: 610426, pid: 610400, label: '永寿县' },
          { value: 610428, pid: 610400, label: '长武县' },
          { value: 610429, pid: 610400, label: '旬邑县' },
          { value: 610430, pid: 610400, label: '淳化县' },
          { value: 610431, pid: 610400, label: '武功县' },
          { value: 610481, pid: 610400, label: '兴平市' },
          { value: 610482, pid: 610400, label: '彬州市' },
          { value: 610483, pid: 610400, label: '高新区' },
        ],
      },
      {
        value: 610500,
        pid: 610000,
        label: '渭南市',
        children: [
          { value: 610502, pid: 610500, label: '临渭区' },
          { value: 610503, pid: 610500, label: '华州区' },
          { value: 610522, pid: 610500, label: '潼关县' },
          { value: 610523, pid: 610500, label: '大荔县' },
          { value: 610524, pid: 610500, label: '合阳县' },
          { value: 610525, pid: 610500, label: '澄城县' },
          { value: 610526, pid: 610500, label: '蒲城县' },
          { value: 610527, pid: 610500, label: '白水县' },
          { value: 610528, pid: 610500, label: '富平县' },
          { value: 610581, pid: 610500, label: '韩城市' },
          { value: 610582, pid: 610500, label: '华阴市' },
        ],
      },
      {
        value: 610600,
        pid: 610000,
        label: '延安市',
        children: [
          { value: 610602, pid: 610600, label: '宝塔区' },
          { value: 610603, pid: 610600, label: '安塞区' },
          { value: 610621, pid: 610600, label: '延长县' },
          { value: 610622, pid: 610600, label: '延川县' },
          { value: 610623, pid: 610600, label: '子长县' },
          { value: 610625, pid: 610600, label: '志丹县' },
          { value: 610626, pid: 610600, label: '吴起县' },
          { value: 610627, pid: 610600, label: '甘泉县' },
          { value: 610628, pid: 610600, label: '富县' },
          { value: 610629, pid: 610600, label: '洛川县' },
          { value: 610630, pid: 610600, label: '宜川县' },
          { value: 610631, pid: 610600, label: '黄龙县' },
          { value: 610632, pid: 610600, label: '黄陵县' },
        ],
      },
      {
        value: 610700,
        pid: 610000,
        label: '汉中市',
        children: [
          { value: 610702, pid: 610700, label: '汉台区' },
          { value: 610703, pid: 610700, label: '南郑区' },
          { value: 610722, pid: 610700, label: '城固县' },
          { value: 610723, pid: 610700, label: '洋县' },
          { value: 610724, pid: 610700, label: '西乡县' },
          { value: 610725, pid: 610700, label: '勉县' },
          { value: 610726, pid: 610700, label: '宁强县' },
          { value: 610727, pid: 610700, label: '略阳县' },
          { value: 610728, pid: 610700, label: '镇巴县' },
          { value: 610729, pid: 610700, label: '留坝县' },
          { value: 610730, pid: 610700, label: '佛坪县' },
        ],
      },
      {
        value: 610800,
        pid: 610000,
        label: '榆林市',
        children: [
          { value: 610802, pid: 610800, label: '榆阳区' },
          { value: 610803, pid: 610800, label: '横山区' },
          { value: 610822, pid: 610800, label: '府谷县' },
          { value: 610824, pid: 610800, label: '靖边县' },
          { value: 610825, pid: 610800, label: '定边县' },
          { value: 610826, pid: 610800, label: '绥德县' },
          { value: 610827, pid: 610800, label: '米脂县' },
          { value: 610828, pid: 610800, label: '佳县' },
          { value: 610829, pid: 610800, label: '吴堡县' },
          { value: 610830, pid: 610800, label: '清涧县' },
          { value: 610831, pid: 610800, label: '子洲县' },
          { value: 610881, pid: 610800, label: '神木市' },
        ],
      },
      {
        value: 610900,
        pid: 610000,
        label: '安康市',
        children: [
          { value: 610902, pid: 610900, label: '汉滨区' },
          { value: 610921, pid: 610900, label: '汉阴县' },
          { value: 610922, pid: 610900, label: '石泉县' },
          { value: 610923, pid: 610900, label: '宁陕县' },
          { value: 610924, pid: 610900, label: '紫阳县' },
          { value: 610925, pid: 610900, label: '岚皋县' },
          { value: 610926, pid: 610900, label: '平利县' },
          { value: 610927, pid: 610900, label: '镇坪县' },
          { value: 610928, pid: 610900, label: '旬阳县' },
          { value: 610929, pid: 610900, label: '白河县' },
        ],
      },
      {
        value: 611000,
        pid: 610000,
        label: '商洛市',
        children: [
          { value: 611002, pid: 611000, label: '商州区' },
          { value: 611021, pid: 611000, label: '洛南县' },
          { value: 611022, pid: 611000, label: '丹凤县' },
          { value: 611023, pid: 611000, label: '商南县' },
          { value: 611024, pid: 611000, label: '山阳县' },
          { value: 611025, pid: 611000, label: '镇安县' },
          { value: 611026, pid: 611000, label: '柞水县' },
        ],
      },
    ],
  },
  {
    value: 620000,
    pid: 0,
    label: '甘肃省',
    children: [
      {
        value: 620100,
        pid: 620000,
        label: '兰州市',
        children: [
          { value: 620102, pid: 620100, label: '城关区' },
          { value: 620103, pid: 620100, label: '七里河区' },
          { value: 620104, pid: 620100, label: '西固区' },
          { value: 620105, pid: 620100, label: '安宁区' },
          { value: 620111, pid: 620100, label: '红古区' },
          { value: 620121, pid: 620100, label: '永登县' },
          { value: 620122, pid: 620100, label: '皋兰县' },
          { value: 620123, pid: 620100, label: '榆中县' },
          { value: 620124, pid: 620100, label: '兰州新区' },
          { value: 620125, pid: 620100, label: '高新区' },
          { value: 620126, pid: 620100, label: '经济开发区' },
        ],
      },
      {
        value: 620200,
        pid: 620000,
        label: '嘉峪关市',
        children: [
          { value: 620201, pid: 620200, label: '雄关区' },
          { value: 620202, pid: 620200, label: '长城区' },
          { value: 620203, pid: 620200, label: '镜铁区' },
        ],
      },
      {
        value: 620300,
        pid: 620000,
        label: '金昌市',
        children: [
          { value: 620302, pid: 620300, label: '金川区' },
          { value: 620321, pid: 620300, label: '永昌县' },
        ],
      },
      {
        value: 620400,
        pid: 620000,
        label: '白银市',
        children: [
          { value: 620402, pid: 620400, label: '白银区' },
          { value: 620403, pid: 620400, label: '平川区' },
          { value: 620421, pid: 620400, label: '靖远县' },
          { value: 620422, pid: 620400, label: '会宁县' },
          { value: 620423, pid: 620400, label: '景泰县' },
        ],
      },
      {
        value: 620500,
        pid: 620000,
        label: '天水市',
        children: [
          { value: 620502, pid: 620500, label: '秦州区' },
          { value: 620503, pid: 620500, label: '麦积区' },
          { value: 620521, pid: 620500, label: '清水县' },
          { value: 620522, pid: 620500, label: '秦安县' },
          { value: 620523, pid: 620500, label: '甘谷县' },
          { value: 620524, pid: 620500, label: '武山县' },
          { value: 620525, pid: 620500, label: '张家川回族自治县' },
        ],
      },
      {
        value: 620600,
        pid: 620000,
        label: '武威市',
        children: [
          { value: 620602, pid: 620600, label: '凉州区' },
          { value: 620621, pid: 620600, label: '民勤县' },
          { value: 620622, pid: 620600, label: '古浪县' },
          { value: 620623, pid: 620600, label: '天祝藏族自治县' },
        ],
      },
      {
        value: 620700,
        pid: 620000,
        label: '张掖市',
        children: [
          { value: 620702, pid: 620700, label: '甘州区' },
          { value: 620721, pid: 620700, label: '肃南裕固族自治县' },
          { value: 620722, pid: 620700, label: '民乐县' },
          { value: 620723, pid: 620700, label: '临泽县' },
          { value: 620724, pid: 620700, label: '高台县' },
          { value: 620725, pid: 620700, label: '山丹县' },
        ],
      },
      {
        value: 620800,
        pid: 620000,
        label: '平凉市',
        children: [
          { value: 620802, pid: 620800, label: '崆峒区' },
          { value: 620821, pid: 620800, label: '泾川县' },
          { value: 620822, pid: 620800, label: '灵台县' },
          { value: 620823, pid: 620800, label: '崇信县' },
          { value: 620825, pid: 620800, label: '庄浪县' },
          { value: 620826, pid: 620800, label: '静宁县' },
          { value: 620881, pid: 620800, label: '华亭市' },
        ],
      },
      {
        value: 620900,
        pid: 620000,
        label: '酒泉市',
        children: [
          { value: 620902, pid: 620900, label: '肃州区' },
          { value: 620921, pid: 620900, label: '金塔县' },
          { value: 620922, pid: 620900, label: '瓜州县' },
          { value: 620923, pid: 620900, label: '肃北蒙古族自治县' },
          { value: 620924, pid: 620900, label: '阿克塞哈萨克族自治县' },
          { value: 620981, pid: 620900, label: '玉门市' },
          { value: 620982, pid: 620900, label: '敦煌市' },
        ],
      },
      {
        value: 621000,
        pid: 620000,
        label: '庆阳市',
        children: [
          { value: 621002, pid: 621000, label: '西峰区' },
          { value: 621021, pid: 621000, label: '庆城县' },
          { value: 621022, pid: 621000, label: '环县' },
          { value: 621023, pid: 621000, label: '华池县' },
          { value: 621024, pid: 621000, label: '合水县' },
          { value: 621025, pid: 621000, label: '正宁县' },
          { value: 621026, pid: 621000, label: '宁县' },
          { value: 621027, pid: 621000, label: '镇原县' },
        ],
      },
      {
        value: 621100,
        pid: 620000,
        label: '定西市',
        children: [
          { value: 621102, pid: 621100, label: '安定区' },
          { value: 621121, pid: 621100, label: '通渭县' },
          { value: 621122, pid: 621100, label: '陇西县' },
          { value: 621123, pid: 621100, label: '渭源县' },
          { value: 621124, pid: 621100, label: '临洮县' },
          { value: 621125, pid: 621100, label: '漳县' },
          { value: 621126, pid: 621100, label: '岷县' },
        ],
      },
      {
        value: 621200,
        pid: 620000,
        label: '陇南市',
        children: [
          { value: 621202, pid: 621200, label: '武都区' },
          { value: 621221, pid: 621200, label: '成县' },
          { value: 621222, pid: 621200, label: '文县' },
          { value: 621223, pid: 621200, label: '宕昌县' },
          { value: 621224, pid: 621200, label: '康县' },
          { value: 621225, pid: 621200, label: '西和县' },
          { value: 621226, pid: 621200, label: '礼县' },
          { value: 621227, pid: 621200, label: '徽县' },
          { value: 621228, pid: 621200, label: '两当县' },
        ],
      },
      {
        value: 622900,
        pid: 620000,
        label: '临夏回族自治州',
        children: [
          { value: 622901, pid: 622900, label: '临夏市' },
          { value: 622921, pid: 622900, label: '临夏县' },
          { value: 622922, pid: 622900, label: '康乐县' },
          { value: 622923, pid: 622900, label: '永靖县' },
          { value: 622924, pid: 622900, label: '广河县' },
          { value: 622925, pid: 622900, label: '和政县' },
          { value: 622926, pid: 622900, label: '东乡族自治县' },
          { value: 622927, pid: 622900, label: '积石山保安族东乡族撒拉族自治县' },
        ],
      },
      {
        value: 623000,
        pid: 620000,
        label: '甘南藏族自治州',
        children: [
          { value: 623001, pid: 623000, label: '合作市' },
          { value: 623021, pid: 623000, label: '临潭县' },
          { value: 623022, pid: 623000, label: '卓尼县' },
          { value: 623023, pid: 623000, label: '舟曲县' },
          { value: 623024, pid: 623000, label: '迭部县' },
          { value: 623025, pid: 623000, label: '玛曲县' },
          { value: 623026, pid: 623000, label: '碌曲县' },
          { value: 623027, pid: 623000, label: '夏河县' },
        ],
      },
    ],
  },
  {
    value: 630000,
    pid: 0,
    label: '青海省',
    children: [
      {
        value: 630100,
        pid: 630000,
        label: '西宁市',
        children: [
          { value: 630102, pid: 630100, label: '城东区' },
          { value: 630103, pid: 630100, label: '城中区' },
          { value: 630104, pid: 630100, label: '城西区' },
          { value: 630105, pid: 630100, label: '城北区' },
          { value: 630121, pid: 630100, label: '大通回族土族自治县' },
          { value: 630122, pid: 630100, label: '湟中县' },
          { value: 630123, pid: 630100, label: '湟源县' },
        ],
      },
      {
        value: 630200,
        pid: 630000,
        label: '海东市',
        children: [
          { value: 630202, pid: 630200, label: '乐都区' },
          { value: 630203, pid: 630200, label: '平安区' },
          { value: 630222, pid: 630200, label: '民和回族土族自治县' },
          { value: 630223, pid: 630200, label: '互助土族自治县' },
          { value: 630224, pid: 630200, label: '化隆回族自治县' },
          { value: 630225, pid: 630200, label: '循化撒拉族自治县' },
        ],
      },
      {
        value: 632200,
        pid: 630000,
        label: '海北藏族自治州',
        children: [
          { value: 632221, pid: 632200, label: '门源回族自治县' },
          { value: 632222, pid: 632200, label: '祁连县' },
          { value: 632223, pid: 632200, label: '海晏县' },
          { value: 632224, pid: 632200, label: '刚察县' },
        ],
      },
      {
        value: 632300,
        pid: 630000,
        label: '黄南藏族自治州',
        children: [
          { value: 632321, pid: 632300, label: '同仁县' },
          { value: 632322, pid: 632300, label: '尖扎县' },
          { value: 632323, pid: 632300, label: '泽库县' },
          { value: 632324, pid: 632300, label: '河南蒙古族自治县' },
        ],
      },
      {
        value: 632500,
        pid: 630000,
        label: '海南藏族自治州',
        children: [
          { value: 632521, pid: 632500, label: '共和县' },
          { value: 632522, pid: 632500, label: '同德县' },
          { value: 632523, pid: 632500, label: '贵德县' },
          { value: 632524, pid: 632500, label: '兴海县' },
          { value: 632525, pid: 632500, label: '贵南县' },
        ],
      },
      {
        value: 632600,
        pid: 630000,
        label: '果洛藏族自治州',
        children: [
          { value: 632621, pid: 632600, label: '玛沁县' },
          { value: 632622, pid: 632600, label: '班玛县' },
          { value: 632623, pid: 632600, label: '甘德县' },
          { value: 632624, pid: 632600, label: '达日县' },
          { value: 632625, pid: 632600, label: '久治县' },
          { value: 632626, pid: 632600, label: '玛多县' },
        ],
      },
      {
        value: 632700,
        pid: 630000,
        label: '玉树藏族自治州',
        children: [
          { value: 632701, pid: 632700, label: '玉树市' },
          { value: 632722, pid: 632700, label: '杂多县' },
          { value: 632723, pid: 632700, label: '称多县' },
          { value: 632724, pid: 632700, label: '治多县' },
          { value: 632725, pid: 632700, label: '囊谦县' },
          { value: 632726, pid: 632700, label: '曲麻莱县' },
        ],
      },
      {
        value: 632800,
        pid: 630000,
        label: '海西蒙古族藏族自治州',
        children: [
          { value: 632801, pid: 632800, label: '格尔木市' },
          { value: 632802, pid: 632800, label: '德令哈市' },
          { value: 632803, pid: 632800, label: '茫崖市' },
          { value: 632821, pid: 632800, label: '乌兰县' },
          { value: 632822, pid: 632800, label: '都兰县' },
          { value: 632823, pid: 632800, label: '天峻县' },
        ],
      },
    ],
  },
  {
    value: 640000,
    pid: 0,
    label: '宁夏回族自治区',
    children: [
      {
        value: 640100,
        pid: 640000,
        label: '银川市',
        children: [
          { value: 640104, pid: 640100, label: '兴庆区' },
          { value: 640105, pid: 640100, label: '西夏区' },
          { value: 640106, pid: 640100, label: '金凤区' },
          { value: 640121, pid: 640100, label: '永宁县' },
          { value: 640122, pid: 640100, label: '贺兰县' },
          { value: 640181, pid: 640100, label: '灵武市' },
          { value: 640182, pid: 640100, label: '经济开发区' },
        ],
      },
      {
        value: 640200,
        pid: 640000,
        label: '石嘴山市',
        children: [
          { value: 640202, pid: 640200, label: '大武口区' },
          { value: 640205, pid: 640200, label: '惠农区' },
          { value: 640221, pid: 640200, label: '平罗县' },
          { value: 640222, pid: 640200, label: '经济开发区' },
        ],
      },
      {
        value: 640300,
        pid: 640000,
        label: '吴忠市',
        children: [
          { value: 640302, pid: 640300, label: '利通区' },
          { value: 640303, pid: 640300, label: '红寺堡区' },
          { value: 640323, pid: 640300, label: '盐池县' },
          { value: 640324, pid: 640300, label: '同心县' },
          { value: 640381, pid: 640300, label: '青铜峡市' },
        ],
      },
      {
        value: 640400,
        pid: 640000,
        label: '固原市',
        children: [
          { value: 640402, pid: 640400, label: '原州区' },
          { value: 640422, pid: 640400, label: '西吉县' },
          { value: 640423, pid: 640400, label: '隆德县' },
          { value: 640424, pid: 640400, label: '泾源县' },
          { value: 640425, pid: 640400, label: '彭阳县' },
        ],
      },
      {
        value: 640500,
        pid: 640000,
        label: '中卫市',
        children: [
          { value: 640502, pid: 640500, label: '沙坡头区' },
          { value: 640521, pid: 640500, label: '中宁县' },
          { value: 640522, pid: 640500, label: '海原县' },
        ],
      },
    ],
  },
  {
    value: 650000,
    pid: 0,
    label: '新疆维吾尔自治区',
    children: [
      {
        value: 650100,
        pid: 650000,
        label: '乌鲁木齐市',
        children: [
          { value: 650102, pid: 650100, label: '天山区' },
          { value: 650103, pid: 650100, label: '沙依巴克区' },
          { value: 650104, pid: 650100, label: '新市区' },
          { value: 650105, pid: 650100, label: '水磨沟区' },
          { value: 650106, pid: 650100, label: '头屯河区' },
          { value: 650107, pid: 650100, label: '达坂城区' },
          { value: 650109, pid: 650100, label: '米东区' },
          { value: 650121, pid: 650100, label: '乌鲁木齐县' },
          { value: 650171, pid: 650100, label: '经济技术开发区' },
          { value: 650172, pid: 650100, label: '高新区' },
        ],
      },
      {
        value: 650200,
        pid: 650000,
        label: '克拉玛依市',
        children: [
          { value: 650202, pid: 650200, label: '独山子区' },
          { value: 650203, pid: 650200, label: '克拉玛依区' },
          { value: 650204, pid: 650200, label: '白碱滩区' },
          { value: 650205, pid: 650200, label: '乌尔禾区' },
        ],
      },
      {
        value: 650400,
        pid: 650000,
        label: '吐鲁番市',
        children: [
          { value: 650402, pid: 650400, label: '高昌区' },
          { value: 650421, pid: 650400, label: '鄯善县' },
          { value: 650422, pid: 650400, label: '托克逊县' },
        ],
      },
      {
        value: 650500,
        pid: 650000,
        label: '哈密市',
        children: [
          { value: 650502, pid: 650500, label: '伊州区' },
          { value: 650521, pid: 650500, label: '巴里坤哈萨克自治县' },
          { value: 650522, pid: 650500, label: '伊吾县' },
        ],
      },
      {
        value: 652300,
        pid: 650000,
        label: '昌吉回族自治州',
        children: [
          { value: 652301, pid: 652300, label: '昌吉市' },
          { value: 652302, pid: 652300, label: '阜康市' },
          { value: 652323, pid: 652300, label: '呼图壁县' },
          { value: 652324, pid: 652300, label: '玛纳斯县' },
          { value: 652325, pid: 652300, label: '奇台县' },
          { value: 652327, pid: 652300, label: '吉木萨尔县' },
          { value: 652328, pid: 652300, label: '木垒哈萨克自治县' },
        ],
      },
      {
        value: 652700,
        pid: 650000,
        label: '博尔塔拉蒙古自治州',
        children: [
          { value: 652701, pid: 652700, label: '博乐市' },
          { value: 652702, pid: 652700, label: '阿拉山口市' },
          { value: 652722, pid: 652700, label: '精河县' },
          { value: 652723, pid: 652700, label: '温泉县' },
        ],
      },
      {
        value: 652800,
        pid: 650000,
        label: '巴音郭楞蒙古自治州',
        children: [
          { value: 652801, pid: 652800, label: '库尔勒市' },
          { value: 652822, pid: 652800, label: '轮台县' },
          { value: 652823, pid: 652800, label: '尉犁县' },
          { value: 652824, pid: 652800, label: '若羌县' },
          { value: 652825, pid: 652800, label: '且末县' },
          { value: 652826, pid: 652800, label: '焉耆回族自治县' },
          { value: 652827, pid: 652800, label: '和静县' },
          { value: 652828, pid: 652800, label: '和硕县' },
          { value: 652829, pid: 652800, label: '博湖县' },
          { value: 652871, pid: 652800, label: '库尔勒开发区' },
        ],
      },
      {
        value: 652900,
        pid: 650000,
        label: '阿克苏地区',
        children: [
          { value: 652901, pid: 652900, label: '阿克苏市' },
          { value: 652922, pid: 652900, label: '温宿县' },
          { value: 652923, pid: 652900, label: '库车县' },
          { value: 652924, pid: 652900, label: '沙雅县' },
          { value: 652925, pid: 652900, label: '新和县' },
          { value: 652926, pid: 652900, label: '拜城县' },
          { value: 652927, pid: 652900, label: '乌什县' },
          { value: 652928, pid: 652900, label: '阿瓦提县' },
          { value: 652929, pid: 652900, label: '柯坪县' },
        ],
      },
      {
        value: 653000,
        pid: 650000,
        label: '克孜勒苏柯尔克孜自治州',
        children: [
          { value: 653001, pid: 653000, label: '阿图什市' },
          { value: 653022, pid: 653000, label: '阿克陶县' },
          { value: 653023, pid: 653000, label: '阿合奇县' },
          { value: 653024, pid: 653000, label: '乌恰县' },
        ],
      },
      {
        value: 653100,
        pid: 650000,
        label: '喀什地区',
        children: [
          { value: 653101, pid: 653100, label: '喀什市' },
          { value: 653121, pid: 653100, label: '疏附县' },
          { value: 653122, pid: 653100, label: '疏勒县' },
          { value: 653123, pid: 653100, label: '英吉沙县' },
          { value: 653124, pid: 653100, label: '泽普县' },
          { value: 653125, pid: 653100, label: '莎车县' },
          { value: 653126, pid: 653100, label: '叶城县' },
          { value: 653127, pid: 653100, label: '麦盖提县' },
          { value: 653128, pid: 653100, label: '岳普湖县' },
          { value: 653129, pid: 653100, label: '伽师县' },
          { value: 653130, pid: 653100, label: '巴楚县' },
          { value: 653131, pid: 653100, label: '塔什库尔干塔吉克自治县' },
        ],
      },
      {
        value: 653200,
        pid: 650000,
        label: '和田地区',
        children: [
          { value: 653201, pid: 653200, label: '和田市' },
          { value: 653221, pid: 653200, label: '和田县' },
          { value: 653222, pid: 653200, label: '墨玉县' },
          { value: 653223, pid: 653200, label: '皮山县' },
          { value: 653224, pid: 653200, label: '洛浦县' },
          { value: 653225, pid: 653200, label: '策勒县' },
          { value: 653226, pid: 653200, label: '于田县' },
          { value: 653227, pid: 653200, label: '民丰县' },
        ],
      },
      {
        value: 654000,
        pid: 650000,
        label: '伊犁哈萨克自治州',
        children: [
          { value: 654002, pid: 654000, label: '伊宁市' },
          { value: 654003, pid: 654000, label: '奎屯市' },
          { value: 654004, pid: 654000, label: '霍尔果斯市' },
          { value: 654021, pid: 654000, label: '伊宁县' },
          { value: 654022, pid: 654000, label: '察布查尔锡伯自治县' },
          { value: 654023, pid: 654000, label: '霍城县' },
          { value: 654024, pid: 654000, label: '巩留县' },
          { value: 654025, pid: 654000, label: '新源县' },
          { value: 654026, pid: 654000, label: '昭苏县' },
          { value: 654027, pid: 654000, label: '特克斯县' },
          { value: 654028, pid: 654000, label: '尼勒克县' },
        ],
      },
      {
        value: 654200,
        pid: 650000,
        label: '塔城地区',
        children: [
          { value: 654201, pid: 654200, label: '塔城市' },
          { value: 654202, pid: 654200, label: '乌苏市' },
          { value: 654221, pid: 654200, label: '额敏县' },
          { value: 654223, pid: 654200, label: '沙湾县' },
          { value: 654224, pid: 654200, label: '托里县' },
          { value: 654225, pid: 654200, label: '裕民县' },
          { value: 654226, pid: 654200, label: '和布克赛尔蒙古自治县' },
        ],
      },
      {
        value: 654300,
        pid: 650000,
        label: '阿勒泰地区',
        children: [
          { value: 654301, pid: 654300, label: '阿勒泰市' },
          { value: 654321, pid: 654300, label: '布尔津县' },
          { value: 654322, pid: 654300, label: '富蕴县' },
          { value: 654323, pid: 654300, label: '福海县' },
          { value: 654324, pid: 654300, label: '哈巴河县' },
          { value: 654325, pid: 654300, label: '青河县' },
          { value: 654326, pid: 654300, label: '吉木乃县' },
        ],
      },
      {
        value: 659001,
        pid: 650000,
        label: '石河子市',
        children: [
          { value: 659101, pid: 659001, label: '新城街道' },
          { value: 659102, pid: 659001, label: '向阳街道' },
          { value: 659103, pid: 659001, label: '红山街道' },
          { value: 659104, pid: 659001, label: '老街街道' },
          { value: 659105, pid: 659001, label: '东城街道' },
          { value: 659106, pid: 659001, label: '北泉镇' },
          { value: 659107, pid: 659001, label: '石河子乡' },
          { value: 659108, pid: 659001, label: '一五二团' },
        ],
      },
      {
        value: 659002,
        pid: 650000,
        label: '阿拉尔市',
        children: [
          { value: 659201, pid: 659002, label: '幸福路街道' },
          { value: 659202, pid: 659002, label: '金银川路街道' },
          { value: 659203, pid: 659002, label: '青松路街道' },
          { value: 659204, pid: 659002, label: '南口街道' },
          { value: 659205, pid: 659002, label: '托喀依乡' },
          { value: 659206, pid: 659002, label: '金银川镇' },
        ],
      },
      {
        value: 659003,
        pid: 650000,
        label: '图木舒克市',
        children: [
          { value: 659301, pid: 659003, label: '图木舒克市区' },
          { value: 659302, pid: 659003, label: '兵团四十四团' },
          { value: 659303, pid: 659003, label: '兵团四十九团' },
          { value: 659304, pid: 659003, label: '兵团五十团' },
          { value: 659305, pid: 659003, label: '兵团五十一团' },
          { value: 659306, pid: 659003, label: '兵团五十二团' },
          { value: 659307, pid: 659003, label: '兵团五十三团' },
          { value: 659308, pid: 659003, label: '喀拉拜勒镇' },
          { value: 659309, pid: 659003, label: '永安坝' },
        ],
      },
      {
        value: 659004,
        pid: 650000,
        label: '五家渠市',
        children: [
          { value: 659401, pid: 659004, label: '城区' },
          { value: 659402, pid: 659004, label: '一零一团' },
          { value: 659403, pid: 659004, label: '一零二团' },
          { value: 659404, pid: 659004, label: '一零三团' },
        ],
      },
      {
        value: 659005,
        pid: 650000,
        label: '北屯市',
        children: [
          { value: 659501, pid: 659005, label: '新城区' },
          { value: 659502, pid: 659005, label: '老城区' },
          { value: 659503, pid: 659005, label: '工业园区' },
          { value: 659504, pid: 659005, label: '海川镇' },
          { value: 659505, pid: 659005, label: '丰庆镇' },
          { value: 659506, pid: 659005, label: '锡伯渡镇' },
        ],
      },
      {
        value: 659006,
        pid: 650000,
        label: '铁门关市',
        children: [
          { value: 659601, pid: 659006, label: '二十九团场' },
          { value: 659602, pid: 659006, label: '库西经济工业园' },
          { value: 659603, pid: 659006, label: '博古其镇' },
          { value: 659604, pid: 659006, label: '双丰镇' },
        ],
      },
      {
        value: 659007,
        pid: 650000,
        label: '双河市',
        children: [
          { value: 659701, pid: 659007, label: '八十一团' },
          { value: 659702, pid: 659007, label: '八十四团' },
          { value: 659703, pid: 659007, label: '八十五团' },
          { value: 659704, pid: 659007, label: '八十六团' },
          { value: 659705, pid: 659007, label: '八十九团' },
          { value: 659706, pid: 659007, label: '九十团' },
        ],
      },
      {
        value: 659008,
        pid: 650000,
        label: '可克达拉市',
        children: [
          { value: 659801, pid: 659008, label: '63团' },
          { value: 659802, pid: 659008, label: '64团' },
          { value: 659803, pid: 659008, label: '66团' },
          { value: 659804, pid: 659008, label: '67团' },
          { value: 659805, pid: 659008, label: '68团' },
        ],
      },
      {
        value: 659009,
        pid: 650000,
        label: '昆玉市',
        children: [
          { value: 659901, pid: 659009, label: '皮山农场' },
          { value: 659902, pid: 659009, label: '二二四团' },
          { value: 659903, pid: 659009, label: '四十七团' },
          { value: 659904, pid: 659009, label: '一牧场' },
        ],
      },
    ],
  },
  {
    value: 710000,
    pid: 0,
    label: '台湾',
    children: [
      {
        value: 710100,
        pid: 710000,
        label: '台北市',
        children: [
          { value: 710101, pid: 710100, label: '松山区' },
          { value: 710102, pid: 710100, label: '信义区' },
          { value: 710103, pid: 710100, label: '大安区' },
          { value: 710104, pid: 710100, label: '中山区' },
          { value: 710105, pid: 710100, label: '中正区' },
          { value: 710106, pid: 710100, label: '大同区' },
          { value: 710107, pid: 710100, label: '万华区' },
          { value: 710108, pid: 710100, label: '文山区' },
          { value: 710109, pid: 710100, label: '南港区' },
          { value: 710110, pid: 710100, label: '内湖区' },
          { value: 710111, pid: 710100, label: '士林区' },
          { value: 710112, pid: 710100, label: '北投区' },
        ],
      },
      {
        value: 710200,
        pid: 710000,
        label: '高雄市',
        children: [
          { value: 710201, pid: 710200, label: '盐埕区' },
          { value: 710202, pid: 710200, label: '鼓山区' },
          { value: 710203, pid: 710200, label: '左营区' },
          { value: 710204, pid: 710200, label: '楠梓区' },
          { value: 710205, pid: 710200, label: '三民区' },
          { value: 710206, pid: 710200, label: '新兴区' },
          { value: 710207, pid: 710200, label: '前金区' },
          { value: 710208, pid: 710200, label: '苓雅区' },
          { value: 710209, pid: 710200, label: '前镇区' },
          { value: 710210, pid: 710200, label: '旗津区' },
          { value: 710211, pid: 710200, label: '小港区' },
          { value: 710212, pid: 710200, label: '凤山区' },
          { value: 710213, pid: 710200, label: '林园区' },
          { value: 710214, pid: 710200, label: '大寮区' },
          { value: 710215, pid: 710200, label: '大树区' },
          { value: 710216, pid: 710200, label: '大社区' },
          { value: 710217, pid: 710200, label: '仁武区' },
          { value: 710218, pid: 710200, label: '鸟松区' },
          { value: 710219, pid: 710200, label: '冈山区' },
          { value: 710220, pid: 710200, label: '桥头区' },
          { value: 710221, pid: 710200, label: '燕巢区' },
          { value: 710222, pid: 710200, label: '田寮区' },
          { value: 710223, pid: 710200, label: '阿莲区' },
          { value: 710224, pid: 710200, label: '路竹区' },
          { value: 710225, pid: 710200, label: '湖内区' },
          { value: 710226, pid: 710200, label: '茄萣区' },
          { value: 710227, pid: 710200, label: '永安区' },
          { value: 710228, pid: 710200, label: '弥陀区' },
          { value: 710229, pid: 710200, label: '梓官区' },
          { value: 710230, pid: 710200, label: '旗山区' },
          { value: 710231, pid: 710200, label: '美浓区' },
          { value: 710232, pid: 710200, label: '六龟区' },
          { value: 710233, pid: 710200, label: '甲仙区' },
          { value: 710234, pid: 710200, label: '杉林区' },
          { value: 710235, pid: 710200, label: '内门区' },
          { value: 710236, pid: 710200, label: '茂林区' },
          { value: 710237, pid: 710200, label: '桃源区' },
          { value: 710238, pid: 710200, label: '那玛夏区' },
        ],
      },
      {
        value: 710300,
        pid: 710000,
        label: '基隆市',
        children: [
          { value: 710301, pid: 710300, label: '中正区' },
          { value: 710302, pid: 710300, label: '七堵区' },
          { value: 710303, pid: 710300, label: '暖暖区' },
          { value: 710304, pid: 710300, label: '仁爱区' },
          { value: 710305, pid: 710300, label: '中山区' },
          { value: 710306, pid: 710300, label: '安乐区' },
          { value: 710307, pid: 710300, label: '信义区' },
        ],
      },
      {
        value: 710400,
        pid: 710000,
        label: '台中市',
        children: [
          { value: 710401, pid: 710400, label: '中区' },
          { value: 710402, pid: 710400, label: '东区' },
          { value: 710403, pid: 710400, label: '南区' },
          { value: 710404, pid: 710400, label: '西区' },
          { value: 710405, pid: 710400, label: '北区' },
          { value: 710406, pid: 710400, label: '西屯区' },
          { value: 710407, pid: 710400, label: '南屯区' },
          { value: 710408, pid: 710400, label: '北屯区' },
          { value: 710409, pid: 710400, label: '丰原区' },
          { value: 710410, pid: 710400, label: '东势区' },
          { value: 710411, pid: 710400, label: '大甲区' },
          { value: 710412, pid: 710400, label: '清水区' },
          { value: 710413, pid: 710400, label: '沙鹿区' },
          { value: 710414, pid: 710400, label: '梧栖区' },
          { value: 710415, pid: 710400, label: '后里区' },
          { value: 710416, pid: 710400, label: '神冈区' },
          { value: 710417, pid: 710400, label: '潭子区' },
          { value: 710418, pid: 710400, label: '大雅区' },
          { value: 710419, pid: 710400, label: '新社区' },
          { value: 710420, pid: 710400, label: '石冈区' },
          { value: 710421, pid: 710400, label: '外埔区' },
          { value: 710422, pid: 710400, label: '大安区' },
          { value: 710423, pid: 710400, label: '乌日区' },
          { value: 710424, pid: 710400, label: '大肚区' },
          { value: 710425, pid: 710400, label: '龙井区' },
          { value: 710426, pid: 710400, label: '雾峰区' },
          { value: 710427, pid: 710400, label: '太平区' },
          { value: 710428, pid: 710400, label: '大里区' },
          { value: 710429, pid: 710400, label: '和平区' },
        ],
      },
      {
        value: 710500,
        pid: 710000,
        label: '台南市',
        children: [
          { value: 710501, pid: 710500, label: '东区' },
          { value: 710502, pid: 710500, label: '南区' },
          { value: 710504, pid: 710500, label: '北区' },
          { value: 710506, pid: 710500, label: '安南区' },
          { value: 710507, pid: 710500, label: '安平区' },
          { value: 710508, pid: 710500, label: '中西区' },
          { value: 710509, pid: 710500, label: '新营区' },
          { value: 710510, pid: 710500, label: '盐水区' },
          { value: 710511, pid: 710500, label: '白河区' },
          { value: 710512, pid: 710500, label: '柳营区' },
          { value: 710513, pid: 710500, label: '后壁区' },
          { value: 710514, pid: 710500, label: '东山区' },
          { value: 710515, pid: 710500, label: '麻豆区' },
          { value: 710516, pid: 710500, label: '下营区' },
          { value: 710517, pid: 710500, label: '六甲区' },
          { value: 710518, pid: 710500, label: '官田区' },
          { value: 710519, pid: 710500, label: '大内区' },
          { value: 710520, pid: 710500, label: '佳里区' },
          { value: 710521, pid: 710500, label: '学甲区' },
          { value: 710522, pid: 710500, label: '西港区' },
          { value: 710523, pid: 710500, label: '七股区' },
          { value: 710524, pid: 710500, label: '将军区' },
          { value: 710525, pid: 710500, label: '北门区' },
          { value: 710526, pid: 710500, label: '新化区' },
          { value: 710527, pid: 710500, label: '善化区' },
          { value: 710528, pid: 710500, label: '新市区' },
          { value: 710529, pid: 710500, label: '安定区' },
          { value: 710530, pid: 710500, label: '山上区' },
          { value: 710531, pid: 710500, label: '玉井区' },
          { value: 710532, pid: 710500, label: '楠西区' },
          { value: 710533, pid: 710500, label: '南化区' },
          { value: 710534, pid: 710500, label: '左镇区' },
          { value: 710535, pid: 710500, label: '仁德区' },
          { value: 710536, pid: 710500, label: '归仁区' },
          { value: 710537, pid: 710500, label: '关庙区' },
          { value: 710538, pid: 710500, label: '龙崎区' },
          { value: 710539, pid: 710500, label: '永康区' },
        ],
      },
      {
        value: 710600,
        pid: 710000,
        label: '新竹市',
        children: [
          { value: 710601, pid: 710600, label: '东区' },
          { value: 710602, pid: 710600, label: '北区' },
          { value: 710603, pid: 710600, label: '香山区' },
        ],
      },
      {
        value: 710700,
        pid: 710000,
        label: '嘉义市',
        children: [
          { value: 710701, pid: 710700, label: '东区' },
          { value: 710702, pid: 710700, label: '西区' },
        ],
      },
      {
        value: 710800,
        pid: 710000,
        label: '新北市',
        children: [
          { value: 710801, pid: 710800, label: '板桥区' },
          { value: 710802, pid: 710800, label: '三重区' },
          { value: 710803, pid: 710800, label: '中和区' },
          { value: 710804, pid: 710800, label: '永和区' },
          { value: 710805, pid: 710800, label: '新庄区' },
          { value: 710806, pid: 710800, label: '新店区' },
          { value: 710807, pid: 710800, label: '树林区' },
          { value: 710808, pid: 710800, label: '莺歌区' },
          { value: 710809, pid: 710800, label: '三峡区' },
          { value: 710810, pid: 710800, label: '淡水区' },
          { value: 710811, pid: 710800, label: '汐止区' },
          { value: 710812, pid: 710800, label: '瑞芳区' },
          { value: 710813, pid: 710800, label: '土城区' },
          { value: 710814, pid: 710800, label: '芦洲区' },
          { value: 710815, pid: 710800, label: '五股区' },
          { value: 710816, pid: 710800, label: '泰山区' },
          { value: 710817, pid: 710800, label: '林口区' },
          { value: 710818, pid: 710800, label: '深坑区' },
          { value: 710819, pid: 710800, label: '石碇区' },
          { value: 710820, pid: 710800, label: '坪林区' },
          { value: 710821, pid: 710800, label: '三芝区' },
          { value: 710822, pid: 710800, label: '石门区' },
          { value: 710823, pid: 710800, label: '八里区' },
          { value: 710824, pid: 710800, label: '平溪区' },
          { value: 710825, pid: 710800, label: '双溪区' },
          { value: 710826, pid: 710800, label: '贡寮区' },
          { value: 710827, pid: 710800, label: '金山区' },
          { value: 710828, pid: 710800, label: '万里区' },
          { value: 710829, pid: 710800, label: '乌来区' },
        ],
      },
      {
        value: 712200,
        pid: 710000,
        label: '宜兰县',
        children: [
          { value: 712201, pid: 712200, label: '宜兰市' },
          { value: 712221, pid: 712200, label: '罗东镇' },
          { value: 712222, pid: 712200, label: '苏澳镇' },
          { value: 712223, pid: 712200, label: '头城镇' },
          { value: 712224, pid: 712200, label: '礁溪乡' },
          { value: 712225, pid: 712200, label: '壮围乡' },
          { value: 712226, pid: 712200, label: '员山乡' },
          { value: 712227, pid: 712200, label: '冬山乡' },
          { value: 712228, pid: 712200, label: '五结乡' },
          { value: 712229, pid: 712200, label: '三星乡' },
          { value: 712230, pid: 712200, label: '大同乡' },
          { value: 712231, pid: 712200, label: '南澳乡' },
        ],
      },
      {
        value: 712300,
        pid: 710000,
        label: '桃园市',
        children: [
          { value: 712301, pid: 712300, label: '桃园市' },
          { value: 712302, pid: 712300, label: '中坜市' },
          { value: 712303, pid: 712300, label: '平镇市' },
          { value: 712304, pid: 712300, label: '八德市' },
          { value: 712305, pid: 712300, label: '杨梅市' },
          { value: 712306, pid: 712300, label: '芦竹市' },
          { value: 712321, pid: 712300, label: '大溪镇' },
          { value: 712324, pid: 712300, label: '大园乡' },
          { value: 712325, pid: 712300, label: '龟山乡' },
          { value: 712327, pid: 712300, label: '龙潭乡' },
          { value: 712329, pid: 712300, label: '新屋乡' },
          { value: 712330, pid: 712300, label: '观音乡' },
          { value: 712331, pid: 712300, label: '复兴乡' },
        ],
      },
      {
        value: 712400,
        pid: 710000,
        label: '新竹县',
        children: [
          { value: 712401, pid: 712400, label: '竹北市' },
          { value: 712421, pid: 712400, label: '竹东镇' },
          { value: 712422, pid: 712400, label: '新埔镇' },
          { value: 712423, pid: 712400, label: '关西镇' },
          { value: 712424, pid: 712400, label: '湖口乡' },
          { value: 712425, pid: 712400, label: '新丰乡' },
          { value: 712426, pid: 712400, label: '芎林乡' },
          { value: 712427, pid: 712400, label: '横山乡' },
          { value: 712428, pid: 712400, label: '北埔乡' },
          { value: 712429, pid: 712400, label: '宝山乡' },
          { value: 712430, pid: 712400, label: '峨眉乡' },
          { value: 712431, pid: 712400, label: '尖石乡' },
          { value: 712432, pid: 712400, label: '五峰乡' },
        ],
      },
      {
        value: 712500,
        pid: 710000,
        label: '苗栗县',
        children: [
          { value: 712501, pid: 712500, label: '苗栗市' },
          { value: 712521, pid: 712500, label: '苑里镇' },
          { value: 712522, pid: 712500, label: '通霄镇' },
          { value: 712523, pid: 712500, label: '竹南镇' },
          { value: 712524, pid: 712500, label: '头份市' },
          { value: 712525, pid: 712500, label: '后龙镇' },
          { value: 712526, pid: 712500, label: '卓兰镇' },
          { value: 712527, pid: 712500, label: '大湖乡' },
          { value: 712528, pid: 712500, label: '公馆乡' },
          { value: 712529, pid: 712500, label: '铜锣乡' },
          { value: 712530, pid: 712500, label: '南庄乡' },
          { value: 712531, pid: 712500, label: '头屋乡' },
          { value: 712532, pid: 712500, label: '三义乡' },
          { value: 712533, pid: 712500, label: '西湖乡' },
          { value: 712534, pid: 712500, label: '造桥乡' },
          { value: 712535, pid: 712500, label: '三湾乡' },
          { value: 712536, pid: 712500, label: '狮潭乡' },
          { value: 712537, pid: 712500, label: '泰安乡' },
        ],
      },
      {
        value: 712700,
        pid: 710000,
        label: '彰化县',
        children: [
          { value: 712701, pid: 712700, label: '彰化市' },
          { value: 712721, pid: 712700, label: '鹿港镇' },
          { value: 712722, pid: 712700, label: '和美镇' },
          { value: 712723, pid: 712700, label: '线西乡' },
          { value: 712724, pid: 712700, label: '伸港乡' },
          { value: 712725, pid: 712700, label: '福兴乡' },
          { value: 712726, pid: 712700, label: '秀水乡' },
          { value: 712727, pid: 712700, label: '花坛乡' },
          { value: 712728, pid: 712700, label: '芬园乡' },
          { value: 712729, pid: 712700, label: '员林市' },
          { value: 712730, pid: 712700, label: '溪湖镇' },
          { value: 712731, pid: 712700, label: '田中镇' },
          { value: 712732, pid: 712700, label: '大村乡' },
          { value: 712733, pid: 712700, label: '埔盐乡' },
          { value: 712734, pid: 712700, label: '埔心乡' },
          { value: 712735, pid: 712700, label: '永靖乡' },
          { value: 712736, pid: 712700, label: '社头乡' },
          { value: 712737, pid: 712700, label: '二水乡' },
          { value: 712738, pid: 712700, label: '北斗镇' },
          { value: 712739, pid: 712700, label: '二林镇' },
          { value: 712740, pid: 712700, label: '田尾乡' },
          { value: 712741, pid: 712700, label: '埤头乡' },
          { value: 712742, pid: 712700, label: '芳苑乡' },
          { value: 712743, pid: 712700, label: '大城乡' },
          { value: 712744, pid: 712700, label: '竹塘乡' },
          { value: 712745, pid: 712700, label: '溪州乡' },
        ],
      },
      {
        value: 712800,
        pid: 710000,
        label: '南投县',
        children: [
          { value: 712801, pid: 712800, label: '南投市' },
          { value: 712821, pid: 712800, label: '埔里镇' },
          { value: 712822, pid: 712800, label: '草屯镇' },
          { value: 712823, pid: 712800, label: '竹山镇' },
          { value: 712824, pid: 712800, label: '集集镇' },
          { value: 712825, pid: 712800, label: '名间乡' },
          { value: 712826, pid: 712800, label: '鹿谷乡' },
          { value: 712827, pid: 712800, label: '中寮乡' },
          { value: 712828, pid: 712800, label: '鱼池乡' },
          { value: 712829, pid: 712800, label: '国姓乡' },
          { value: 712830, pid: 712800, label: '水里乡' },
          { value: 712831, pid: 712800, label: '信义乡' },
          { value: 712832, pid: 712800, label: '仁爱乡' },
        ],
      },
      {
        value: 712900,
        pid: 710000,
        label: '云林县',
        children: [
          { value: 712901, pid: 712900, label: '斗六市' },
          { value: 712921, pid: 712900, label: '斗南镇' },
          { value: 712922, pid: 712900, label: '虎尾镇' },
          { value: 712923, pid: 712900, label: '西螺镇' },
          { value: 712924, pid: 712900, label: '土库镇' },
          { value: 712925, pid: 712900, label: '北港镇' },
          { value: 712926, pid: 712900, label: '古坑乡' },
          { value: 712927, pid: 712900, label: '大埤乡' },
          { value: 712928, pid: 712900, label: '莿桐乡' },
          { value: 712929, pid: 712900, label: '林内乡' },
          { value: 712930, pid: 712900, label: '二仑乡' },
          { value: 712931, pid: 712900, label: '仑背乡' },
          { value: 712932, pid: 712900, label: '麦寮乡' },
          { value: 712933, pid: 712900, label: '东势乡' },
          { value: 712934, pid: 712900, label: '褒忠乡' },
          { value: 712935, pid: 712900, label: '台西乡' },
          { value: 712936, pid: 712900, label: '元长乡' },
          { value: 712937, pid: 712900, label: '四湖乡' },
          { value: 712938, pid: 712900, label: '口湖乡' },
          { value: 712939, pid: 712900, label: '水林乡' },
        ],
      },
      {
        value: 713000,
        pid: 710000,
        label: '嘉义县',
        children: [
          { value: 713001, pid: 713000, label: '太保市' },
          { value: 713002, pid: 713000, label: '朴子市' },
          { value: 713023, pid: 713000, label: '布袋镇' },
          { value: 713024, pid: 713000, label: '大林镇' },
          { value: 713025, pid: 713000, label: '民雄乡' },
          { value: 713026, pid: 713000, label: '溪口乡' },
          { value: 713027, pid: 713000, label: '新港乡' },
          { value: 713028, pid: 713000, label: '六脚乡' },
          { value: 713029, pid: 713000, label: '东石乡' },
          { value: 713030, pid: 713000, label: '义竹乡' },
          { value: 713031, pid: 713000, label: '鹿草乡' },
          { value: 713032, pid: 713000, label: '水上乡' },
          { value: 713033, pid: 713000, label: '中埔乡' },
          { value: 713034, pid: 713000, label: '竹崎乡' },
          { value: 713035, pid: 713000, label: '梅山乡' },
          { value: 713036, pid: 713000, label: '番路乡' },
          { value: 713037, pid: 713000, label: '大埔乡' },
          { value: 713038, pid: 713000, label: '阿里山乡' },
        ],
      },
      {
        value: 713300,
        pid: 710000,
        label: '屏东县',
        children: [
          { value: 713301, pid: 713300, label: '屏东市' },
          { value: 713321, pid: 713300, label: '潮州镇' },
          { value: 713322, pid: 713300, label: '东港镇' },
          { value: 713323, pid: 713300, label: '恒春镇' },
          { value: 713324, pid: 713300, label: '万丹乡' },
          { value: 713325, pid: 713300, label: '长治乡' },
          { value: 713326, pid: 713300, label: '麟洛乡' },
          { value: 713327, pid: 713300, label: '九如乡' },
          { value: 713328, pid: 713300, label: '里港乡' },
          { value: 713329, pid: 713300, label: '盐埔乡' },
          { value: 713330, pid: 713300, label: '高树乡' },
          { value: 713331, pid: 713300, label: '万峦乡' },
          { value: 713332, pid: 713300, label: '内埔乡' },
          { value: 713333, pid: 713300, label: '竹田乡' },
          { value: 713334, pid: 713300, label: '新埤乡' },
          { value: 713335, pid: 713300, label: '枋寮乡' },
          { value: 713336, pid: 713300, label: '新园乡' },
          { value: 713337, pid: 713300, label: '崁顶乡' },
          { value: 713338, pid: 713300, label: '林边乡' },
          { value: 713339, pid: 713300, label: '南州乡' },
          { value: 713340, pid: 713300, label: '佳冬乡' },
          { value: 713341, pid: 713300, label: '琉球乡' },
          { value: 713342, pid: 713300, label: '车城乡' },
          { value: 713343, pid: 713300, label: '满州乡' },
          { value: 713344, pid: 713300, label: '枋山乡' },
          { value: 713345, pid: 713300, label: '三地门乡' },
          { value: 713346, pid: 713300, label: '雾台乡' },
          { value: 713347, pid: 713300, label: '玛家乡' },
          { value: 713348, pid: 713300, label: '泰武乡' },
          { value: 713349, pid: 713300, label: '来义乡' },
          { value: 713350, pid: 713300, label: '春日乡' },
          { value: 713351, pid: 713300, label: '狮子乡' },
          { value: 713352, pid: 713300, label: '牡丹乡' },
        ],
      },
      {
        value: 713400,
        pid: 710000,
        label: '台东县',
        children: [
          { value: 713401, pid: 713400, label: '台东市' },
          { value: 713421, pid: 713400, label: '成功镇' },
          { value: 713422, pid: 713400, label: '关山镇' },
          { value: 713423, pid: 713400, label: '卑南乡' },
          { value: 713424, pid: 713400, label: '鹿野乡' },
          { value: 713425, pid: 713400, label: '池上乡' },
          { value: 713426, pid: 713400, label: '东河乡' },
          { value: 713427, pid: 713400, label: '长滨乡' },
          { value: 713428, pid: 713400, label: '太麻里乡' },
          { value: 713429, pid: 713400, label: '大武乡' },
          { value: 713430, pid: 713400, label: '绿岛乡' },
          { value: 713431, pid: 713400, label: '海端乡' },
          { value: 713432, pid: 713400, label: '延平乡' },
          { value: 713433, pid: 713400, label: '金峰乡' },
          { value: 713434, pid: 713400, label: '达仁乡' },
          { value: 713435, pid: 713400, label: '兰屿乡' },
        ],
      },
      {
        value: 713500,
        pid: 710000,
        label: '花莲县',
        children: [
          { value: 713501, pid: 713500, label: '花莲市' },
          { value: 713521, pid: 713500, label: '凤林镇' },
          { value: 713522, pid: 713500, label: '玉里镇' },
          { value: 713523, pid: 713500, label: '新城乡' },
          { value: 713524, pid: 713500, label: '吉安乡' },
          { value: 713525, pid: 713500, label: '寿丰乡' },
          { value: 713526, pid: 713500, label: '光复乡' },
          { value: 713527, pid: 713500, label: '丰滨乡' },
          { value: 713528, pid: 713500, label: '瑞穗乡' },
          { value: 713529, pid: 713500, label: '富里乡' },
          { value: 713530, pid: 713500, label: '秀林乡' },
          { value: 713531, pid: 713500, label: '万荣乡' },
          { value: 713532, pid: 713500, label: '卓溪乡' },
        ],
      },
      {
        value: 713600,
        pid: 710000,
        label: '澎湖县',
        children: [
          { value: 713601, pid: 713600, label: '马公市' },
          { value: 713621, pid: 713600, label: '湖西乡' },
          { value: 713622, pid: 713600, label: '白沙乡' },
          { value: 713623, pid: 713600, label: '西屿乡' },
          { value: 713624, pid: 713600, label: '望安乡' },
          { value: 713625, pid: 713600, label: '七美乡' },
        ],
      },
      {
        value: 713700,
        pid: 710000,
        label: '金门县',
        children: [
          { value: 713701, pid: 713700, label: '金城镇' },
          { value: 713702, pid: 713700, label: '金湖镇' },
          { value: 713703, pid: 713700, label: '金沙镇' },
          { value: 713704, pid: 713700, label: '金宁乡' },
          { value: 713705, pid: 713700, label: '烈屿乡' },
          { value: 713706, pid: 713700, label: '乌丘乡' },
        ],
      },
      {
        value: 713800,
        pid: 710000,
        label: '连江县',
        children: [
          { value: 713801, pid: 713800, label: '南竿乡' },
          { value: 713802, pid: 713800, label: '北竿乡' },
          { value: 713803, pid: 713800, label: '莒光乡' },
          { value: 713804, pid: 713800, label: '东引乡' },
        ],
      },
    ],
  },
  {
    value: 810000,
    pid: 0,
    label: '香港特别行政区',
    children: [
      {
        value: 810100,
        pid: 810000,
        label: '香港岛',
        children: [
          { value: 810101, pid: 810100, label: '中西区' },
          { value: 810102, pid: 810100, label: '湾仔区' },
          { value: 810103, pid: 810100, label: '东区' },
          { value: 810104, pid: 810100, label: '南区' },
        ],
      },
      {
        value: 810200,
        pid: 810000,
        label: '九龙',
        children: [
          { value: 810201, pid: 810200, label: '油尖旺区' },
          { value: 810202, pid: 810200, label: '深水埗区' },
          { value: 810203, pid: 810200, label: '九龙城区' },
          { value: 810204, pid: 810200, label: '黄大仙区' },
          { value: 810205, pid: 810200, label: '观塘区' },
        ],
      },
      {
        value: 810300,
        pid: 810000,
        label: '新界',
        children: [
          { value: 810301, pid: 810300, label: '荃湾区' },
          { value: 810302, pid: 810300, label: '屯门区' },
          { value: 810303, pid: 810300, label: '元朗区' },
          { value: 810304, pid: 810300, label: '北区' },
          { value: 810305, pid: 810300, label: '大埔区' },
          { value: 810306, pid: 810300, label: '西贡区' },
          { value: 810307, pid: 810300, label: '沙田区' },
          { value: 810308, pid: 810300, label: '葵青区' },
          { value: 810309, pid: 810300, label: '离岛区' },
        ],
      },
    ],
  },
  {
    value: 820000,
    pid: 0,
    label: '澳门特别行政区',
    children: [
      {
        value: 820100,
        pid: 820000,
        label: '澳门半岛',
        children: [
          { value: 820101, pid: 820100, label: '花地玛堂区' },
          { value: 820102, pid: 820100, label: '圣安多尼堂区' },
          { value: 820103, pid: 820100, label: '大堂区' },
          { value: 820104, pid: 820100, label: '望德堂区' },
          { value: 820105, pid: 820100, label: '风顺堂区' },
        ],
      },
      {
        value: 820200,
        pid: 820000,
        label: '氹仔岛',
        children: [{ value: 820201, pid: 820200, label: '嘉模堂区' }],
      },
      {
        value: 820300,
        pid: 820000,
        label: '路环岛',
        children: [{ value: 820301, pid: 820300, label: '圣方济各堂区' }],
      },
    ],
  },
];
