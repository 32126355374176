
    import {onMounted, reactive, toRefs, computed, ref} from "vue";
    import EditorComponent from '@/components/editor/Editor.vue'
    import {message} from "ant-design-vue/es";
    import PageUtil from "@/utils/page_util";
    import UploadUtil from "@/utils/upload_util";
    import {baseUrl} from "@/utils/config";
    import router from "@/router";
    import regionList from '@/enums/region';
    import type { UploadChangeParam, UploadProps } from 'ant-design-vue';
    import {PlusOutlined,CloseCircleOutlined,InboxOutlined} from '@ant-design/icons-vue'
    import Apisign from "@/request/apis/api_sign";
    interface IState {
        formState
    }

    export default ({
        name: 'ArticleList',
        components: {EditorComponent,PlusOutlined,CloseCircleOutlined,InboxOutlined},
        setup() {
            const state: IState = reactive({
                formState:{
                    pic_list:[]
                }       
            })

            const rules = {
                reg_mer_type: [{required: true, message: "请选择注册类型"}],
                shop_name:[{required: true, message: "请输入商户营业名称"}],
                shop_lic:[{required: true, message: "请输入社会信用统一代码"}],
                shop_region_ids:[{required: true, message: "请输入商户营业地址"}],
                shop_addr_ext:[{required: true, message: "请输入商户详细地址"}],
                legal_name:[{required: true, message: "请输入法人姓名"}],
                legal_mobile:[{required: true, message: "请输入法人手机号"}],
                legal_idcard_no:[{required: true, message: "请输入法人身份证号"}],
                legal_card_deadline:[{required: true, message: "请选择法人证件截止日期"}],
                legalmanHomeAddr:[{required: true, message: "请输入法人家庭地震"}],
                bank_acct_type:[{required: true, message: "请选择账户类型"}],
                bank_no:[{required: true, message: "请输入开户支行名称"}],
                bank_acct_name:[{required: true, message: "请输入开户账号名称"}],
                bank_acct_no:[{required: true, message: "请输入开户行账号"}],
                join_eam:[{required: true, message: "请选择是否开通资管系统"}]
            }

            const occupationList = [
                {label: '各类专业、技术人员',value: '0'},
                {label: '国家机关、党群组织、企事业单位的负责人',value: '1'},
                {label: '办事人员和有关人员',value: '2'},
                {label: '商业工作人员', value: '3'},
                {label: '服务性工作人员',value: '4'},
                {label: '农林牧渔劳动者', value: '5'},
                {label: '生产工作、运输工作和部分体力劳动者',value: '6'},
                {label: '不便分类的其他劳动者',value: '7'},
            ];

            const shareholderCertTypeList = [
                {label: '身份证',value: '1'},
                {label: '护照',value: '2'},
                {label: '军官证',value: '3'},
                {label: '警官证',value: '4'},
                {label: '士兵证',value: '5' },
                {label: '台湾居民来往大陆通行证',value: '6'},
                {label: '回乡证',value: '7'},
                {label: '港澳居民来往内地通行证',value: '8'},
                {label: '港澳台居民居住证',value: '10'},
                {label: '营业执照',value: '11'},
                {label: '组织机构代码证',value: '12'},
                {label: '税务登记证',value: '13'},
                {label: '商业登记证',value: '14'},
                {label: '民办非企业登记证书',value: '15'},
                {label: '批文证明',value: '16'},
            ];
            const chinaumsPicUploadUrl = 'https://eam.ynjgyy.cn/api/account.chinaums/upload_picture';
            const pictures = reactive({
                shop_lic: '',
                id_card_front: '',
                id_card_back: '',
                id_card_handle: '',
                bank_lic: '',
                bank_card_front: '',
                bank_card_back: '',
                stroe: '',
                stroe_inner: '',
                certificate: '',
            });

            // const beforeUpload = (file: UploadProps['fileList'][number]) => {
            //     const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            //         if (!isJpgOrPng) {
            //             message.error('只能上传PNG或JPG格式图片');
            //         }
            //     const isLt2M = file.size / 1024 / 1024 < 2;
            //         if (!isLt2M) {
            //             message.error('上传图片文件大小需小于2MB!');
            //         }
            //         return isJpgOrPng && isLt2M;
            // };
            const loading = ref<boolean>(false);
            const handleChange = (info: UploadChangeParam) => {
                console.log(info)
                if (info.file.status === 'uploading') {
                    loading.value = true;
                    return;
                }
                if (info.file.status === 'done') {
                    let response = info.file.response.data;
                    pictures[response.picture_field] = response.file_url;
                    state.formState.pic_list.push({
                        document_type: response.document_type,
                        document_name: response.document_name,
                        file_path: response.file_path,
                        file_size: response.file_size,
                    });
                    console.log(state.formState.pic_list)
                    const { document_type, ocr_result } = response;
                    if (ocr_result) {
                        if (document_type == '0002') {
                            if (ocr_result.hasOwnProperty('单位名称')) {
                                state.formState.shop_name = ocr_result.单位名称.words;
                        }
                        if (ocr_result.hasOwnProperty('社会信用代码')) {
                            state.formState.shop_lic = ocr_result.社会信用代码.words;
                        }
                        if (ocr_result.hasOwnProperty('法人')) {
                            state.formState.legal_name = ocr_result.法人.words;
                        }
                        if (ocr_result.hasOwnProperty('地址')) {
                            state.formState.shop_addr_ext = ocr_result.地址.words;
                        }
                    }
                if (document_type == '0001' || document_type == '0011') {
                    if (ocr_result.hasOwnProperty('姓名')) {
                        state.formState.legal_name = ocr_result.姓名.words;
                    }
                    if (ocr_result.hasOwnProperty('公民身份号码')) {
                        state.formState.legal_idcard_no = ocr_result.公民身份号码.words;
                    }
                    if (ocr_result.hasOwnProperty('住址')) {
                        state.formState.legalmanHomeAddr = ocr_result.住址.words;
                    }
                    if (ocr_result.hasOwnProperty('失效日期')) {
                        let date = ocr_result.失效日期.words.toString();
                        state.formState.legal_card_deadline =
                         date.substr(0, 4) + '-' + date.substr(4, 2) + '-' + date.substr(6, 2);
                    }
                }
                if (document_type == '0025') {
                    if (ocr_result.hasOwnProperty('银行卡卡号')) {
                        state.formState.bank_acct_no = ocr_result.银行卡卡号.words;
                    }
                }
            }
            loading.value = false;
        }
                if (info.file.status === 'error') {
                    loading.value = false;
                    message.error('上传图片错误');
                }
        };

        const formRef = ref();
        const onSubmit = async () => {
                console.log(state.formState)
                formRef.value.validate() .then(async() => {
                    const params = {...state.formState}
                    const res = await Apisign.chinaumsSave(params)
                        if(res.err_code == 0){
                            message.error(res.msg)
                             router.push("./SignList")
                        }else{
                            message.error(res.msg)
                        }
                })
                .catch((error: any) => {
                    console.log("error", error)
                })
            }
            const  handleShopRegionChange = (region) => {
                state.formState.shop_region_ids = region;
                state.formState.shop_province_id = region[0].toString().substr(0, 2);
                state.formState.shop_city_id = region[1].toString().substr(0, 4);
                state.formState.shop_country_id = region[2].toString();
                state.formState.value = region;
            }

            onMounted(() => {
                
            })

            return {
                ...toRefs(state),
                rules,
                occupationList,
                formRef,
                shareholderCertTypeList,
                regionList,
                pictures,
                chinaumsPicUploadUrl,
                handleChange,
                onSubmit,
                handleShopRegionChange
            }
        }
    })
